<template>
	<div>
		<b-row> 
			<b-col cols="6" class="ml-2">
				<!-- <label class="font-weight-bolder"> Co</label> -->
				<b-card no-body class="border mt-1">
					<b-table
						:items="selectedItems"
						:fields="selectedFields"
						thead-class="text-center"
						small
					>
						<template #cell(action)="row">
							<b-button
								v-ripple.400="'rgba(40, 199, 111, 0.15)'"
								variant="flat-danger"
								class="btn-icon rounded-circle"
								@click="remove(row.item)"
								size="sm"
							>
							
								<feather-icon icon="TrashIcon" />
							</b-button>
						</template>
					</b-table>
				</b-card>
			</b-col>
		</b-row>

		<b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
	</div>
</template>

<script>
import {
	BCard,
	BCardHeader,
	BContainer,
	BRow,
	BCol,
	BFormSelect,
	BForm,
	BButton,
	BFormGroup,
	BCollapse,
	BFormDatepicker,
	BFormInput,
	BOverlay,
	BFormCheckbox, 
	BTable
} from "bootstrap-vue";
import vSelect from "vue-select";

import axios from "axios";
import helper from "@/api/helper";
import metadata from "@/api/metadata";
import error_handler from "@/api/error_handler";

export default {
	components: {
		BCard,
		BCardHeader,
		BContainer,
		BRow,
		BCol,
		BFormSelect,
		BForm,
		BButton,
		BFormGroup,
		BCollapse,
		BFormDatepicker,
		BFormInput,
		BOverlay,
		vSelect,
		BFormCheckbox,
		BTable
	},
	props: ["profile"],
	data() {
		return {
			isEditing: false,
			showLoading: false,
			path: "",
			selectedFields: [
				{ key: 'userid', label: 'User ID', sortable: true, class: 'text-center' },
				{ key: 'username', label: 'Username', sortable: true, class: 'text-center' },
				{ key: 'department', label: 'Department', sortable: true, class: 'text-center' },
				{ key: 'assigned_at', label: 'Assigned At', sortable: true, class: 'text-center' },
				{ key: 'action', label: 'Action', class: 'text-center'}],
			selectedItems: [
				// { userid: "df", username: "dfd", department: "", assigned_at: "", }
			],
		};
	},
	mounted() {
		this.path = this.$route.query.path;
		let poshujan = this.profile["BmkgSatu.metadata.poshujan.IPosHujan"]
		

	},
	methods: {
		goback() {
			this.$router.push({ name: "metadata" });
		},
		updateStation() {
			this.showLoading = true;

			const update_data = {
				poshujan_valid_from: this.poshujan_valid_from,
				poshujan_valid_to: this.poshujan_valid_to,
				pos_hujan_id: this.pos_hujan_id,
				poshujan_sms_id: this.poshujan_sms_id ? this.poshujan_sms_id : null,
				poshujan_is_active: this.poshujan_is_active,
				poshujan_entry_station_id: this.poshujan_entry_station_id,
				poshujan_contact_type: this.poshujan_contact_type,
				poshujan_name: this.poshujan_name,
				poshujan_telephone: this.poshujan_telephone,
				poshujan_alamat: this.poshujan_alamat,
			};

			let post_data = {
				"@type": "BmkgStation",
				"BmkgSatu.metadata.poshujan.IPosHujan": update_data,
			};

			const update_url = helper.getBaseUrl() + this.path;

			axios
				.patch(update_url, post_data, helper.getConfig())
				.then((response) => {
					if (
						response.status == 201 ||
						response.status == 200 ||
						response.status == 204
					) {
						this.$swal({
						title: "Update Data berhasil!",
						text: "Update Data Pos Hujan",
						icon: "success",
						customClass: {
							confirmButton: "btn btn-primary",
						},
						buttonsStyling: false,
						});
						this.showLoading = false;
					}
				})
				.catch((error) => {
					this.showLoading = false;
					error_handler(this, error, "Metadata Stasiun", "Update Data Error");
				});
				this.isEditing = false;
		},
	},
};
</script>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
.mt {
  margin-top: -9px;
}
</style>
