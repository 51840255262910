<template>
	<div>
		<div class="row">
			<b-col sm="6">
				<b-card no-body class="border mt-1 pr-1">
					<b-row class="pl-1 pr-1 mt-1">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="3"
								label-size="sm"
								label="Date Established :"
								label-for="valid-from"
								size="sm"
							>
								<b-form-datepicker
									id="valid-from"
									v-model="station_valid_from"
									size="sm"
									:readonly="station_valid_from"
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="3"
								label-size="sm"
								label="Date Closed :"
								label-for="valid-to"
								size="sm"
							>
								<b-form-datepicker
									id="valid-to"
									v-model="station_valid_to"
									size="sm"
									reset-button
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="3"
								label-size="sm"
								label="Station ID :"
								label-for="wmo-id"
								size="sm"
							>
								<b-form-input
									id="wmo-id"
									size="sm"
									v-model="wmo_id"
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="3"
								label-size="sm"
								label="WIGOS ID :"
								label-for="wigos-id"
								size="sm"
							>
								<b-form-input
									id="wigos-id"
									size="sm"
									v-model="wigos_id"
								/>
							</b-form-group>
						</b-col>
					</b-row>
					
					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="3"
								label-size="sm"
								label="ICAO :"
								label-for="icao-id"
								size="sm"
							>
								<b-form-input
									id="icao-id"
									size="sm"
									v-model="station_icao"
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="3"
								label-size="sm"
								label="Nama :"
								label-for="mkg-name"
								size="sm"
							>
								<b-form-input
									id="mkg-name"
									size="sm"
									v-model="mkg_name"
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
									label-cols="4"
									label-cols-lg="3"
									label-size="sm"
									label=" Type :"
									label-for="select-type"
								>
								<b-form-select
									v-model="station_type_mkg"
									id="select-type"
									size="sm"
									:options="options.mkg_type"
								></b-form-select>
							</b-form-group>
							
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="3"
								label-size="sm"
								label="Operating Hours :"
								label-for="operating-hours"
							>
								<b-form-input
									id="operating-hours"
									size="sm"
									v-model="station_operating_hours"
								/>
							</b-form-group>
						</b-col>
					</b-row>
					
					<b-row class="pl-1 pr-1 mt">
						<b-col cols="3"> </b-col>
						<b-col cols="8"> 
							<b-row>
								<b-col cols="6">
									<b-form-group
											label-cols="5"
											label-cols-lg="5"
											label-size="sm"
											label=" Start :"
											label-for="select-start"
										>
										<b-form-select
											v-model="station_hour_start"
											id="select-start"
											size="sm"
											:options="options.time_type"
										></b-form-select>
									</b-form-group>
								</b-col>
		
								<b-col cols="6" v-if="is_mid_hour">
									<b-form-group
											label-cols="5"
											label-cols-lg="5"
											label-size="sm"
											label="Mid End :"
											label-for="select-end"
										>
										<b-form-select
											v-model="station_mid_hour_end"
											id="select-end"
											size="sm"
											:options="options.time_type"
										></b-form-select>
									</b-form-group>
								</b-col>
		
								<b-col cols="6" v-if="is_mid_hour">
									<b-form-group
											label-cols="5"
											label-cols-lg="5"
											label-size="sm"
											label="Mid Start :"
											label-for="select-start"
										>
										<b-form-select
											v-model="station_mid_hour_start"
											id="select-start"
											size="sm"
											:options="options.time_type"
										></b-form-select>
									</b-form-group>
								</b-col>
		
								<b-col cols="6">
									<b-form-group
											label-cols="5"
											label-cols-lg="5"
											label-size="sm"
											label=" End :"
											label-for="select-end"
										>
										<b-form-select
											v-model="station_hour_end"
											id="select-end"
											size="sm"
											:options="options.time_type"
										></b-form-select>
									</b-form-group>
								</b-col>
							</b-row>
						</b-col>
						
					</b-row>

				</b-card>

				<div>
					<b-button
						variant="primary"
						class="mb-1 mb-sm-0 mr-0 mr-sm-1"
						size="sm"
						v-on:click="goback"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					>
						Back
					</b-button>

					<b-button
						variant="success"
						class="mb-1 mb-sm-0 mr-0 mr-sm-1"
						size="sm"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
						@click="updateStation()"
						:disabled="!isRoleEdit"
					>
						Update
					</b-button>

					<!-- <b-button
						variant="danger"
						class="mb-1 mb-sm-0 ml-0 ml-sm-1 float-right"
						type="reset"
						size="sm"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					>
						Delete
					</b-button> -->
				</div>
			</b-col>
		</div>

		<b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
	</div>
</template>

<script>
import {
	BCard,
	BCardHeader,
	BContainer,
	BRow,
	BCol,
	BFormSelect,
	BForm,
	BButton,
	BFormGroup,
	BCollapse,
	BFormDatepicker,
	BFormInput,
	BOverlay,
	BFormCheckbox,
	BTable
} from "bootstrap-vue";
import vSelect from "vue-select";

import axios from "axios";
import helper from "@/api/helper";
import metadata from "@/api/metadata";
import error_handler from "@/api/error_handler";

export default {
	components: {
		BCard,
		BCardHeader,
		BContainer,
		BRow,
		BCol,
		BFormSelect,
		BForm,
		BButton,
		BFormGroup,
		BCollapse,
		BFormDatepicker,
		BFormInput,
		BOverlay,
		vSelect,
		BFormCheckbox,
		BTable
	},
	props: ["profile", "roles"],
	data() {
		return {
			isEditing: false,
			showLoading: false,
			is_mid_hour: false,
			path: "",
			wmo_id: "",
			wigos_id: "",
			mkg_name: "",
			station_icao: "",
			station_type_mkg: "",
			station_operating_hours: "",
			station_pilot: "",
			station_rason: "",
			station_petir: "",
			station_magnet: "",
			station_hour_start: "",
			station_hour_end: "",
			station_mid_hour_start: null,
			station_mid_hour_end: null,
			station_valid_from: "",
			station_valid_to: "",
			options: {
				time_type: [
					{ value: 0, text: "00:00" },
					{ value: 1, text: "01:00" },
					{ value: 2, text: "02:00" },
					{ value: 3, text: "03:00" },
					{ value: 4, text: "04:00" },
					{ value: 5, text: "05:00" },
					{ value: 6, text: "06:00" },
					{ value: 7, text: "07:00" },
					{ value: 8, text: "08:00" },
					{ value: 9, text: "09:00" },
					{ value: 10, text: "10:00" },
					{ value: 11, text: "11:00" },
					{ value: 12, text: "12:00" },
					{ value: 13, text: "13:00" },
					{ value: 14, text: "14:00" },
					{ value: 15, text: "15:00" },
					{ value: 16, text: "16:00" },
					{ value: 17, text: "17:00" },
					{ value: 18, text: "18:00" },
					{ value: 19, text: "19:00" },
					{ value: 20, text: "20:00" },
					{ value: 21, text: "21:00" },
					{ value: 22, text: "22:00" },	
					{ value: 23, text: "23:00" },
				],
				mkg_type: [
					{ value: 'M', text: "Meteorologi" },
					{ value: 'K', text: "Klimatologi" },
					{ value: 'G', text: "Geofisika" },
				]
			},
			pengamatanTable: {
                fields: [
						{key:'checked', label: ''}, 
						{key:'pengamatan', label: 'Pengamatan', tdClass: 'text-left'},
						{key:'start_date', label: 'Start Date'}, 
						{key:'end_date', label: 'End Date'}
					],
                items: [
                    {
                        id: 1,
                        pengamatan: "PILOT",
                        checked: false,
						start_date: null,
						end_date: null
                    },
                    {
                        id: 2,
                        pengamatan: "RASON",
                        checked: false,
						start_date: null,
						end_date: null
                    },
                    {
                        id: 3,
                        pengamatan: "PETIR",
                        checked: false,
						start_date: null,
						end_date: null
                    },
					{
                        id: 4,
                        pengamatan: "MAGNET",
                        checked: false,
						start_date: null,
						end_date: null
                    },
                ],
            },
			isRoleAdd: false,
			isRoleEdit: false,
			isRoleView: false,
			isRoleDelete: false

		};
	},

	watch: {
		profile(val) {
			console.log('generalMkg-profile:', val)
			let mkg = this.profile["BmkgSatu.metadata.mkg.IMKG"]
		
			this.mkg_name = this.profile.station_name
			this.wmo_id = this.profile.station_wmo_id
			this.wigos_id = this.profile.wigos_id
			this.station_icao = this.profile.station_icao
			this.station_type_mkg = mkg.station_type_mkg
			this.station_operating_hours = mkg.station_operating_hours
			this.station_hour_start = mkg.station_hour_start
			this.station_hour_end = mkg.station_hour_end
			this.station_mid_hour_end = mkg.station_mid_hour_end
			this.station_mid_hour_start = mkg.station_mid_hour_start
			this.station_valid_from = mkg.station_valid_from
			this.station_valid_to = mkg.station_valid_to
		},

		roles(val) {
			// console.log('s.roles', val)
			// console.log('this.roles', this.roles)
			if (val) {
				this.isRoleAdd = val.isRoleAdd
				this.isRoleEdit = val.isRoleEdit
				this.isRoleView = val.isRoleView
				this.isRoleDelete = val.isRoleDelete
			}
		},

		station_operating_hours: function (val) {
			this.is_mid_hour = false
			if (val && val >= 0) {
				if (!this.station_hour_start) {
					this.station_hour_start = 0
					this.station_hour_end = (val == 24) ? 23 : val 
					this.is_mid_hour = false
					this.station_mid_hour_end = null
					this.station_mid_hour_start = null
				}
				else {
					this.station_hour_end = (parseInt(this.station_hour_start) + parseInt(val))
					if (this.station_hour_end == 24) {
						this.is_mid_hour = false
						this.station_hour_end = 23
						this.station_mid_hour_end = null
						this.station_mid_hour_start = null
					}
					else if (this.station_hour_end > 23) {
						this.is_mid_hour = true
						this.station_mid_hour_end = 23
						this.station_mid_hour_start = 0
						this.station_hour_end = parseInt(this.station_hour_end) - 24
					}
					else {
						this.is_mid_hour = false
						this.station_mid_hour_end = null
						this.station_mid_hour_start = null
					}
				}
			}
		},

		station_hour_start: function(val) {
			this.is_mid_hour = false
			if (val || val === 0) {
				if (val === 0) {
					if (this.station_operating_hours >= 23) {
						this.station_hour_end = 23
					}
					else {
						this.station_hour_end = this.station_operating_hours
					}
					this.is_mid_hour = false
					this.station_mid_hour_end = null
					this.station_mid_hour_start = null
				}
				else {
					this.station_hour_end = (parseInt(val) + parseInt(this.station_operating_hours))
					if (this.station_hour_end == 24) {
						this.is_mid_hour = false
						this.station_hour_end = 23
						this.station_mid_hour_end = null
						this.station_mid_hour_start = null
					}
					else if (this.station_hour_end > 23) {
						this.is_mid_hour = true
						this.station_mid_hour_end = 23
						this.station_mid_hour_start = 0
						this.station_hour_end = parseInt(this.station_hour_end) - 24
					}
					else {
						this.is_mid_hour = false
						this.station_mid_hour_end = null
						this.station_mid_hour_start = null
					}
				}
			}
		}
	},

	mounted() {
		this.path = this.$route.query.path;
		let mkg = this.profile["BmkgSatu.metadata.mkg.IMKG"]
		
		this.mkg_name = this.profile.station_name
		this.wmo_id = this.profile.station_wmo_id
		this.wigos_id = this.profile.wigos_id
		this.station_icao = this.profile.station_icao
		this.station_type_mkg = mkg.station_type_mkg
		this.station_operating_hours = mkg.station_operating_hours
		// this.station_pilot = mkg.station_pilot
		// this.station_rason = mkg.station_rason
		// this.station_petir = mkg.station_petir
		// this.station_magnet = mkg.station_magnet
		this.station_hour_start = mkg.station_hour_start
		this.station_hour_end = mkg.station_hour_end
		this.station_mid_hour_end = mkg.station_mid_hour_end
		this.station_mid_hour_start = mkg.station_mid_hour_start
		this.station_valid_from = mkg.station_valid_from
		this.station_valid_to = mkg.station_valid_to

		// for (var i = 0 ; i < this.pengamatanTable.items.length; i++) {
		// 	if (this.pengamatanTable.items[i].pengamatan == "PILOT") {
		// 		this.pengamatanTable.items[i].checked = this.station_pilot
		// 	}
		// 	else if (this.pengamatanTable.items[i].pengamatan == "RASON") {
		// 		this.pengamatanTable.items[i].checked = this.station_rason
		// 	}
		// 	else if (this.pengamatanTable.items[i].pengamatan == "PETIR") {
		// 		this.pengamatanTable.items[i].checked = this.station_petir
		// 	}
		// 	else if (this.pengamatanTable.items[i].pengamatan == "MAGNET") {
		// 		this.pengamatanTable.items[i].checked = this.station_magnet
		// 	}
		// }

		if (this.roles) {
			// console.log('mounted - this.roles', this.roles)
			this.isRoleAdd = this.roles.isRoleAdd
			this.isRoleEdit = this.roles.isRoleEdit
			this.isRoleView = this.roles.isRoleView
			this.isRoleDelete = this.roles.isRoleDelete
		}

	},
	methods: {

		onCheckedClick(data) {
			console.log('data', data)
			// switch(data.pengamatan)
			switch (data.pengamatan) {
				case "PILOT":
					this.station_pilot = data.checked
					break;
				case "RASON":
					this.station_rason = data.checked
					break;
				case "PETIR":
					this.station_petir = data.checked
					break;
				case "MAGNET":
					this.station_magnet = data.checked
					break;
			
				default:
					break;
			}
			
		},

		goback() {
			this.$router.push({ name: "metadata" });
		},

		async updateStation() {
			this.showLoading = true;

			const update_data = {
				station_type_mkg: this.station_type_mkg,
				station_operating_hours:this.station_operating_hours,
				station_hour_start: this.station_hour_start,
				station_hour_end: this.station_hour_end,
				station_mid_hour_start: this.station_mid_hour_start,
				station_mid_hour_end: this.station_mid_hour_end,
				station_valid_from: this.station_valid_from,
				station_valid_to: this.station_valid_to && this.station_valid_to != "" ? this.station_valid_to : null,
				wmo_id: this.wmo_id
			};

			let post_data = {
				"station_icao": this.station_icao,
				"station_name": this.mkg_name,
				"station_wmo_id": this.wmo_id,
				"wigos_id": this.wigos_id,
				"@type": "BmkgStation",
				"BmkgSatu.metadata.mkg.IMKG": update_data,
				"sync_metastation": 1
			};
			// console.log('post_data', post_data)
			const update_url = helper.getBaseUrl() + this.path;
			axios
				.patch(update_url, post_data, helper.getConfig())
				.then((response) => {
					if (
						response.status == 201 ||
						response.status == 200 ||
						response.status == 204
					) {
						if (this.profile) {
							this.createHistoricalNote(`perubahan data usage MKG
								Date Established: ${this.profile.station_valid_from} => ${this.station_valid_from}
								Date Closed: ${this.profile.station_valid_to} => ${this.station_valid_to}
								WIGOS ID: ${this.profile.wigos_id} => ${this.wigos_id}
								ICAO : ${this.profile.station_icao} => ${this.station_icao}
								Station Name: ${this.profile.station_name} => ${this.mkg_name}
								Type: ${this.profile.station_type_mkg} => ${this.station_type_mkg}
								Operating Hours : ${this.profile.station_operating_hours} => ${this.station_operating_hours}
								Start Hour: ${this.profile.station_hour_start} => ${this.station_hour_start}
								End Hour: ${this.profile.station_valid_to} => ${this.station_hour_end}
							`)
						}
						this.$swal({
						title: "Update Data berhasil!",
						text: "Update Data MKG",
						icon: "success",
						customClass: {
							confirmButton: "btn btn-primary",
						},
						buttonsStyling: false,
						});
						this.showLoading = false;
					}
				})
				.catch((error) => {
					this.showLoading = false;
					error_handler(this, error, "Metadata Stasiun", "Update Data Error");
				});
				this.isEditing = false;
		},

		async createHistoricalNote(description) {
			// get user login
			let user = "";
			if (localStorage.userLogin) {
				const userLogin = JSON.parse(localStorage.userLogin);
				user = userLogin.fullName;
			}

			if (user === "" || description === "") {
				return false;
			}
			this.showLoading = true;

			const NOW = new Date().toISOString();
			const date = NOW.split("T")[0];
			const POST_DATE = NOW.substr(0, 19).split(":").join("-");

			const POST_ID = this.profile.station_id
							+ "_metahistoricalnote_"
							+ date + "_"
							+ POST_DATE;

			const POST_DATA = {
				"@type": "StationHistoricalNote",
				id: POST_ID,
				historical_date: date,
				historical_user: user,
				historical_description: description,
			}

			const POST_URL = helper.getBaseUrl() + this.path + "/metahistoricalnote";

			axios.post(POST_URL, POST_DATA, helper.getConfig())
			.then((response) => {
				const { data } = response;
				this.showLoading = false;
			})
			.catch((error) => {
				console.log(error);
				this.showLoading = false;
			});

			return 0
		},
	},
};
</script>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
.mt {
  margin-top: -9px;
}
</style>
