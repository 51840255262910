<template>
  <div>
    <b-row>
      <b-col sm="12">
        <b-tabs pills>
          <b-tab title="General" active>
            <general
              :profile="profile"
              :detail="detail"
              :roles="roles"
              :usage="usage"
              @station="shareStation($event)"
              @usageUpdate="usageUpdate($event)"
          /></b-tab>
          <b-tab  title="Historical Notes" lazy>
            <historical :profile="profile" :detail="detail" :roles="roles"/>
          </b-tab>
          <b-tab  title="Photos" lazy>
            <photos :profile="profile" :detail="detail" :roles="roles"/>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
} from "bootstrap-vue";
import General from "./UsageGeneral.vue";
import Historical from "./UsageHistorical.vue";
import Photos from "./UsagePhotos.vue";

export default {
  components: {
    BTab,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BTabs,
    General,
    Historical,
    Photos
  },
  props: ["profile", "detail", "roles", "usage"],
  data() {
    return {
      t_usage: 'lain'
    };
  },
  methods: {},
  mounted() {
    if (this.usage) {
      console.log('this.usage', this.usage)
      this.t_usage = this.usage
    }
    // console.log('main allthis.detail', this.detail)
  },
};
</script>
<style>
.nav-pills .nav-link {
  padding: 0.786rem 1rem;
}
</style>
