<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="onDraftNewObserver()">
          <feather-icon icon="PlusIcon" class="mr-50" />
          New Observer
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-card no-body class="mt-1 ml-1 mr-1">
          <!-- <b-table :items="selectedItems" :fields="selectedFields">
            <template #cell(observer_is_active)="data">
              <b-badge variant="success" v-if="data.item.observer_is_active"> Aktif </b-badge>
              <b-badge variant="danger" v-else> Tidak Aktif </b-badge>
            </template>
            <template #cell(actions)="data">
              <b-button-group>
                <b-button variant="gradient-danger" size="sm" @click="onActionDelete(data.item)">
                  <trash-icon size="1x" />
                </b-button>
                <b-button id="bedit" v-if="data.item.observer_id === 0 || data.item.observer_id === null" class="" variant="gradient-warning" size="sm" @click="onActionEdit(data.item)">
                  <edit-2-icon size="1x" />
                </b-button>
                <b-button v-else class="" variant="gradient-primary" size="sm" @click="onActionEdit(data.item)">
                  <edit-2-icon size="1x" />
                </b-button>
              </b-button-group>
              <b-tooltip v-if="data.item.observer_id === 0 || data.item.observer_id === null" target="bedit" triggers="hover">
                {{ "Perlu diupdate" }}
              </b-tooltip>
            </template>
          </b-table> -->
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class="mr-1 mb-md-0"
            >
              <b-input-group
                size="sm"
              >
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      none
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filter"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card>

        <b-card no-body class="mt-1 ml-1 mr-1">
          <b-table
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="selectedItems" 
            :fields="selectedFields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(observer_is_active)="data">
              <b-badge variant="success" v-if="data.item.observer_is_active"> Aktif </b-badge>
              <b-badge variant="danger" v-else> Tidak Aktif </b-badge>
            </template>
            <template #cell(actions)="data">
              <b-button-group>
                <b-button variant="gradient-danger" size="sm" @click="onActionDelete(data.item)">
                  <trash-icon size="1x" />
                </b-button>
                <b-button id="bedit" v-if="data.item.observer_id === 0 || data.item.observer_id === null" class="" variant="gradient-warning" size="sm" @click="onActionEdit(data.item)">
                  <edit-2-icon size="1x" />
                </b-button>
                <b-button v-else class="" variant="gradient-primary" size="sm" @click="onActionEdit(data.item)">
                  <edit-2-icon size="1x" />
                </b-button>
              </b-button-group>
              <b-tooltip v-if="data.item.observer_id === 0 || data.item.observer_id === null" target="bedit" triggers="hover">
                {{ "Perlu diupdate" }}
              </b-tooltip>
            </template>
          </b-table>
        </b-card>

        <b-card class="d-flex justify-content-between flex-wrap pt-0">
          <b-row>
            <b-col cols="2">
              <!-- page length -->
              <b-form-group
                label="Per Page"
                label-cols="6"
                label-align="left"
                label-size="sm"
                label-for="sortBySelect"
                class="text-nowrap mb-md-0 mr-1"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                />
              </b-form-group>
            </b-col>
            <b-col cols="10" class="">
              <!-- pagination -->
              <div class="d-flex justify-content-end">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            <!-- </b-card> -->
            </b-col>
          </b-row>
        </b-card>


        <div>
          <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" v-on:click="goback" :block="$store.getters['app/currentBreakPoint'] === 'xs'"> Back </b-button>
        </div>
      </b-col>
    </b-row>

    <b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>

    
      <b-modal id="add-new-observer" ref="add-new-observer" :title="typeTitle" button-size="sm" size="md" body-class="position-static" centered no-close-on-backdrop>
        <!-- <b-row class="mb-1" >
          <b-col cols="4">
            Observer ID
          </b-col>
          <b-col cols="8">
            <b-form-input
              id="id_observer"
              v-model="draftNewObserver.observer_id"
              size="sm"
            ></b-form-input>
          </b-col>
        </b-row> -->
        <b-overlay :show="showLoadModal" rounded="sm"  no-wrap> </b-overlay>
          <b-row class="mb-1">
            <b-col cols="4"> Name </b-col>
            <b-col cols="8">
              <b-form-input id="name_observer" v-model="draftNewObserver.observer_name" size="sm"></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-1">
            <b-col cols="4"> Nip </b-col>
            <b-col cols="8">
              <b-form-input id="nip_observer" v-model="draftNewObserver.observer_nip" size="sm"></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-1">
            <b-col cols="4"> Telephone </b-col>
            <b-col cols="8">
              <b-form-input id="telephone_observer" v-model="draftNewObserver.observer_telephone" size="sm"></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-1">
            <b-col cols="4"> Valid From </b-col>
            <b-col cols="8">
              <b-form-datepicker
                id="datepicker-valid-from"
                placeholder="From"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="en"
                size="sm"
                v-model="draftNewObserver.observer_valid_from"
                reset-button
              />
            </b-col>
          </b-row>

          <b-row class="mb-1">
            <b-col cols="4"> Valid To </b-col>
            <b-col cols="8">
              <b-form-datepicker
                id="datepicker-valid-to"
                placeholder="To"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="en"
                size="sm"
                v-model="draftNewObserver.observer_valid_to"
                reset-button
              />
            </b-col>
          </b-row>

          <template #modal-footer>
            <b-button v-if="!isUpdateObserver" size="sm" variant="gradient-danger" @click="onReset()"> Reset </b-button>
            <b-button size="sm" @click="onCancel"> Cancel </b-button>
            <b-button size="sm" variant="gradient-primary" @click="onOk"> Submit </b-button>
          </template>
        
      </b-modal>
    
  </div>
</template>

<script>
import { BCard, BCardHeader, BContainer, BRow, BCol, BFormSelect, BForm, BButton, BButtonGroup, BFormGroup, BInputGroup, BInputGroupAppend, BCollapse, BFormDatepicker, 
  BFormInput, BOverlay, BFormCheckbox, BModal, BTable, BBadge, VBToggle, VBTooltip, BTooltip, BPagination } from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import helper from "@/api/helper";
import metadata from "@/api/metadata";
import error_handler from "@/api/error_handler";
import { TrashIcon, Edit2Icon } from "vue-feather-icons";

export default {
  components: {
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BButtonGroup,
    BFormGroup,
    BCollapse,
    BFormDatepicker,
    BFormInput,
    BOverlay,
    TrashIcon,
    Edit2Icon,
    vSelect,
    BFormCheckbox,
    BModal,
    BTable,
    BBadge,
    BTooltip,
    BPagination,
    BInputGroup,
    BInputGroupAppend
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: ["profile"],
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 15, 20, 25, 30],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      isEditing: false,
      showLoading: false,
      showLoadModal: false,
      path: "",
      selectedFields: [
        { key: 'no', label: 'No'},
        { key: "observer_name", label: "Name", sortable: true },
        { key: "observer_nip", label: "NIP", sortable: true },
        {
          key: "observer_telephone",
          label: "Telephone",
          sortable: true,
        },
        {
          key: "observer_valid_from",
          label: "Valid From",
          sortable: true,
        },
        { key: "observer_valid_to", label: "Valid To", sortable: true },
        { key: "observer_is_active", label: "Active", sortable: true },
        { key: "actions", label: "Actions", sortable: false },
      ],
      selectedItems: [],
      isUpdateObserver: false,
      objectUpdateObserver: null,
      typeTitle: "CREATE NEW OBSERVER",
      draftNewObserver: {
        // observer_id: 0,
        observer_name: "",
        observer_nip: "",
        observer_telephone: "",
        observer_valid_from: "",
        observer_valid_to: null,
        observer_is_active: 1,
      },
    };
  },
  mounted() {
    this.path = this.$route.query.path;
    // console.log("profile", this.profile);
    this.getListObserver();
    
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.selectedFields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },

  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    onActionDelete(item) {
      this.$swal({
        title: "Apakah Anda Yakin?",
        text: "Anda ingin menghapus observer '" + item.observer_name + "' ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteObserver(item);
        } else if (result.dismiss === "cancel") {
          return;
        }
      });
    },

    deleteObserver(item) {
      let url = helper.getBaseUrl() + item.path;
      axios
        .delete(url, helper.getConfig())
        .then((response) => {
          if (response.status == 201 || response.status == 200 || response.status == 204) {
            const index = this.selectedItems.indexOf(item);
            if (index > -1) {
              this.selectedItems.splice(index, 1);
            }
            this.onReset();
            this.$refs["add-new-observer"].hide();
            this.$swal({
              title: "Delete Data berhasil!",
              text: "Delete Observer",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onActionEdit(item) {
      this.isUpdateObserver = true;
      this.objectUpdateObserver = item;
      this.typeTitle = "UPDATE OBSERVER";
      this.draftNewObserver = {
        observer_name: item.observer_name,
        observer_nip: item.observer_nip,
        observer_telephone: item.observer_telephone,
        observer_valid_from: item.observer_valid_from,
        observer_valid_to: item.observer_valid_to,
        observer_is_active: item.observer_is_active,
      };
      this.$refs["add-new-observer"].show();
    },

    onCancel() {
      this.$refs["add-new-observer"].hide();
      this.onReset();
    },

    onReset() {
      this.draftNewObserver = {
        observer_name: "",
        observer_nip: "",
        observer_telephone: "",
        observer_valid_from: "",
        observer_valid_to: null,
        observer_is_active: 1,
      };
    },

    onDraftNewObserver() {
      this.isUpdateObserver = false;
      this.objectUpdateObserver = null;
      this.typeTitle = "CREATE NEW OBSERVER";
      this.onReset();
      this.$refs["add-new-observer"].show();
    },

    onOk() {
      let post_data = {
        // observer_id: this.draftNewObserver.observer_id,
        observer_name: this.draftNewObserver.observer_name,
        observer_nip: this.draftNewObserver.observer_nip,
        observer_telephone: this.draftNewObserver.observer_telephone,
        observer_valid_from: this.draftNewObserver.observer_valid_from,
        observer_valid_to: this.draftNewObserver.observer_valid_to,
        observer_is_active: 1,
        type_name: "Observer",
        station_id: this.profile.station_id,
        "@type": "Observer",
      };

      if (this.isUpdateObserver) {
        if (this.objectUpdateObserver) {
          post_data.observer_is_active = this.draftNewObserver.observer_is_active;
          let url = helper.getBaseUrl() + this.objectUpdateObserver.path;
          this.showLoadModal = true;
          axios
            .patch(url, post_data, helper.getConfig())
            .then((response) => {
              this.showLoadModal = false;
              if (response.status == 201 || response.status == 200 || response.status == 204) {
                (this.objectUpdateObserver.observer_name = post_data.observer_name),
                  (this.objectUpdateObserver.observer_nip = post_data.observer_nip),
                  (this.objectUpdateObserver.observer_telephone = post_data.observer_telephone),
                  (this.objectUpdateObserver.observer_valid_from = post_data.observer_valid_from),
                  (this.objectUpdateObserver.observer_valid_to = post_data.observer_valid_to),
                  (this.objectUpdateObserver.observer_is_active = post_data.observer_is_active),
                  this.onReset();
                this.$refs["add-new-observer"].hide();
                this.$swal({
                  title: "Input Data Berhasil!",
                  text: "Data Observer telah di simpan",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
            })
            .catch((error) => {
              this.showLoadModal = false;
              console.log(error);
            });
        } else {
          console.log("object objectUpdateObserver null");
        }
      } else {
        let url = helper.getBaseUrl() + "/observer";
        this.showLoadModal = true;
        axios
          .post(url, post_data, helper.getConfig())
          .then((response) => {
            this.showLoadModal = false;
            if (response.status == 201 || response.status == 200 || response.status == 204) {
              let temp = [...this.selectedItems];
              post_data["observer_id"] = response.data["@name"];
              post_data["path"] = "/observer/" + response.data["@name"];
              temp.push(post_data);
              this.selectedItems = temp;
              this.onReset();
              this.$refs["add-new-observer"].hide();

              this.$swal({
                title: "Simpan data sukses!",
                text: "Data Observer telah di simpan",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          })
          .catch((error) => {
            this.showLoadModal = false;
            console.log(error);
          });
      }
    },

    goback() {
      this.$router.push({ name: "metadata" });
    },
    async getListObserver() {
      if (!this.profile.station_id) return false;

      var {data} = await metadata.getObserverStationWithAggQuery(this.profile.station_id);
      this.selectedItems = []
      this.selectedItems = data;
      this.selectedItems.map((iData, index) => { 
        iData.no = index + 1 
        let date = iData.observer_valid_from ? (iData.observer_valid_from).split("T")[0] : iData.observer_valid_from;
        // console.log('date', date)
        iData.observer_valid_from = date;//new Date(iData.observer_valid_from).toISOString().substr(0, 19).split(":").join("-");
      })      
      this.totalRows = this.selectedItems.length
      // const config = {
      //   mode: "cors",
      //   withCredentials: false,
      //   params: {
      //     type_name: "Observer",
      //     station_id: this.profile.station_id,
      //     _metadata: "observer_id,station_id,path,observer_name,observer_nip,observer_telephone,observer_valid_from,observer_valid_to,observer_is_active&_size=200",
      //   },
      //   headers: helper.getHeader(),
      // };
      // const get_url = helper.getBaseUrl() + "@aggregation_query";

      // await axios
      //   .get(get_url, config)
      //   .then((response) => {
      //     this.selectedItems = []
      //     this.selectedItems = response.data;

      //     this.selectedItems.map((data, index) => {
      //       data.no = index+1
      //     })
          
      //     this.totalRows = this.selectedItems.length
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
    updateStation() {
      this.showLoading = true;

      const update_data = {
        poshujan_valid_from: this.poshujan_valid_from,
        poshujan_valid_to: this.poshujan_valid_to,
        pos_hujan_id: this.pos_hujan_id,
        poshujan_sms_id: this.poshujan_sms_id ? this.poshujan_sms_id : null,
        poshujan_is_active: this.poshujan_is_active,
        poshujan_entry_station_id: this.poshujan_entry_station_id,
        poshujan_contact_type: this.poshujan_contact_type,
        poshujan_name: this.poshujan_name,
        poshujan_telephone: this.poshujan_telephone,
        poshujan_alamat: this.poshujan_alamat,
      };

      let post_data = {
        "@type": "BmkgStation",
        "BmkgSatu.metadata.poshujan.IPosHujan": update_data,
      };

      const update_url = helper.getBaseUrl() + this.path;

      axios
        .patch(update_url, post_data, helper.getConfig())
        .then((response) => {
          if (response.status == 201 || response.status == 200 || response.status == 204) {
            this.selectedItems = []
            this.getListObserver()
            this.$swal({
              title: "Update Data berhasil!",
              text: "Update Data Pos Hujan",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.showLoading = false;
          }
        })
        .catch((error) => {
          this.showLoading = false;
          error_handler(this, error, "Metadata Stasiun", "Update Data Error");
        });
      this.isEditing = false;
    },
  },
};
</script>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
.mt {
  margin-top: -9px;
}
</style>
