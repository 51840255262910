<template>
  <div>
    <div class="row">
      <!-- ALL Historical Note table -->
      <b-col sm="8">
        <b-card no-body class="border">
          <!-- <b-table
            :fields="fields"
            :items="sortedItems"
            responsive
            hover
            selectable
            select-mode="single"
            @row-selected="selectedRow"
            ref="table"
            style="white-space: pre-line;"
          /> -->
          <a-spin :spinning="LOADING_TABLE" >
            <a-table :columns="COLUMN_HISTORY" :data-source="sortedItems" rowKey="id" :customRow="customRow"></a-table>
          </a-spin>
        </b-card>
      </b-col>

      <b-col sm="4">
        <!-- Input Form -->
        <b-card class="border">
          <b-row>
            <b-col>
              <b-form-group
                label-cols="3"
                label-size="sm"
                label="Date"
                label-for="datepicker-date"
              >
                <b-form-datepicker
                  id="datepicker-date"
                  placeholder="Date"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="en"
                  size="sm"
                  v-model="date"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-cols="3"
                label-size="sm"
                label="User"
                label-for="input-user"
              >
                <b-form-input id="input-user" size="sm" placeholder="AUTO" v-model="user" disabled />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-cols="3"
                label-size="sm"
                label="Description"
                label-for="input-textarea"
              >
                <b-form-textarea
                  id="input-textarea"
                  placeholder="Description"
                  rows="10"
                  v-model="description"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <!-- Action Buttons -->
        <!-- <b-button
          variant="gradient-primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          size="sm"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          Back
        </b-button> -->
        <b-button
						variant="primary"
						class="mb-1 mb-sm-0 mr-0 mr-sm-1"
						size="sm"
						v-on:click="goback"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					>
						Back
        </b-button>
        <b-button
          variant="gradient-secondary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          size="sm"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="resetForm()"
          v-if="isEditing"
        >
          Cancel
        </b-button>
        <b-button
          variant="gradient-warning"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="reset"
          size="sm"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="editData()"
          v-if="isEditing"
          :disabled="!isRoleEdit"
        >
          Edit
        </b-button>
        <b-button
          variant="gradient-danger"
          class="mb-1 mb-sm-0 mr-0 float-right"
          size="sm"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="deleteData()"
          v-if="isEditing"
          :disabled="!isRoleEdit"
        >
          Delete
        </b-button>
        <b-button
          variant="gradient-success"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="reset"
          size="sm"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="addData()"
          v-if="!isEditing"
          :disabled="!isRoleEdit"
        >
          Add Historical Note
        </b-button>
      </b-col>
    </div>
    <b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
  </div>
</template>

<script>
import axios from 'axios'
import helper from '@/api/helper';

import {
  BCard,
  BCardHeader,
  BContainer,
  BRow,
  BCol,
  BFormSelect,
  BForm,
  BButton,
  BFormGroup,
  BCollapse,
  BFormDatepicker,
  BFormInput,
  BFormTextarea,
  BTable,
  BOverlay,
} from "bootstrap-vue";


export default {
  components: {
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BFormGroup,
    BCollapse,
    BFormDatepicker,
    BFormInput,
    BFormTextarea,
    BTable,
    BOverlay,
  },
  props: ["profile", "roles"],
  data: function () {
    return {
      LOADING_TABLE : false,
      COLUMN_HISTORY : [
        // {
				// 	title: 'NO',
				// 	dataIndex: 'historical_no',
				// 	key: 'historical_no',
				// 	scopedSlots: { customRender: "historical_no-column" },
				// 	width: 25
				// },
				{
					title: 'DATE',
					dataIndex: 'historical_date',
					key: 'historical_date',
					scopedSlots: { customRender: "historical_date-column" },
					width: 25
				},
				{
					title: 'USER',
					dataIndex: 'historical_user',
					key: 'historical_user',
					scopedSlots: { customRender: "historical_user-column" },
					width: 50,
					align: 'center'
				},
        {
					title: 'DESCRIPTION',
					dataIndex: 'historical_description',
					key: 'historical_description',
					scopedSlots: { customRender: "historical_description-column" },
					width: 200,
					align: 'center'
				},
			],
			DATA_HISTORY : [],
      userLogin: null,
      showLoading: false,
      isEditing: false,
      selected_id: "",
      station_path: "",
      date: "",
      user: "",
      description: "",
      fields: [
        { key: "historical_date", label: "Date" },
        { key: "historical_user", label: "User" },
        { key: "historical_description", label: "Description" },
      ],
      items: [
        // {
        //   historical_date: "2021-10-14",
        //   historical_user: "Stamet Maimun Saleh",
        //   historical_description: "perubahan range wind speed (kt) via terminal",
        // },
      ],
      isRoleAdd: false,
			isRoleEdit: false,
			isRoleView: false,
			isRoleDelete: false
    };
  },
  computed: {
    sortedItems() {
      // return this.items.sort((a, b) => -(a.id.localeCompare(b.id)));
      
      let inc = 1
      let items =  this.DATA_HISTORY
                              .sort((a, b) => -(a.id.localeCompare(b.id)))
                              .map(item => {
                                item['historical_no'] = inc++
                                return item;
                              })
      console.log('items', items)
      return items
    }
  },
  mounted() {
    this.station_path = this.$route.query.path;
    if (localStorage.userLogin) {
      this.userLogin = JSON.parse(localStorage.userLogin);
      this.user = this.userLogin.fullName;
    }

    if (this.roles) {
      // console.log('mounted - this.roles', this.roles)
      this.isRoleAdd = this.roles.isRoleAdd
      this.isRoleEdit = this.roles.isRoleEdit
      this.isRoleView = this.roles.isRoleView
      this.isRoleDelete = this.roles.isRoleDelete
    }

    this.getAllDatas();
  },

  watch: {
		roles(val) {
			// console.log('s.roles', val)
			// console.log('this.roles', this.roles)
			if (val) {
				this.isRoleAdd = val.isRoleAdd
				this.isRoleEdit = val.isRoleEdit
				this.isRoleView = val.isRoleView
				this.isRoleDelete = val.isRoleDelete
			}
		}
	},

  methods: {
    goback() {
			this.$router.push({ name: "metadata" });
		},
    resetForm() {
      this.clearSelected()
      this.isEditing = false;
      this.selected_id = "";
      this.date = "";
      if (this.userLogin) {
        this.user = this.userLogin.fullName;
      }
      this.description = "";
    },

    selectedRow(items) {
      if (items.length > 0) {
        const selected_item = items[0];
        this.isEditing = true;
        this.selected_id = selected_item.id;
        this.date = selected_item.historical_date;
        // this.user = selected_item.historical_user;
        this.description = selected_item.historical_description;
      }
    },

    customRow(record, index) {
      return {
        on: {
          click: () => {
            // console.log("record:", record, index);
            this.isEditing = true;
            this.selected_id = record.id;
            this.date = record.historical_date;
            // this.user = selected_item.historical_user;
            this.description = record.historical_description;
          },
        },
      };
    },

    clearSelected() {
      this.$refs.table.clearSelected()
    },
    getAllDatas() {
      this.showLoading = true;
      this.LOADING_TABLE = true;

      const GET_URL = helper.getBaseUrl() + this.station_path + "/metahistoricalnote/@items_list";
      const GET_CONFIG = {
        mode: "cors",
        withCredentials: false,
        params: {
          include: "id,historical_date,historical_user,historical_description",
        },
        headers: helper.getHeader(),
      };

      axios.get(GET_URL, GET_CONFIG)
        .then((response) => {
          const { data } = response;
          // this.items = data.items.map(item => ({
          //   id: item["@name"],
          //   historical_date: item.historical_date.split("T")[0],
          //   historical_user: item.historical_user,
          //   historical_description: item.historical_description,
          // }));
          let inc = 1
          this.DATA_HISTORY = data.items.map(item => ({
            id: item["@name"],
            historical_date: item.historical_date.split("T")[0],
            historical_user: item.historical_user,
            historical_description: item.historical_description,
          }));
          this.showLoading = false;
          this.LOADING_TABLE = false;
        })
        .catch((error) => {
          console.log(error);
          this.showLoading = false;
          this.LOADING_TABLE = false;
        });
    },
    editData() {
      if (this.selected_id === "" || this.date === "" || this.user === "" || this.description === "") {
        return false;
      }
      this.showLoading = true;

      const PATCH_DATA = {
        "@type": "StationHistoricalNote",
        historical_date: this.date,
        historical_user: this.user,
        historical_description: this.description,
      }

      const PATCH_URL = helper.getBaseUrl() + this.station_path + "/metahistoricalnote/" + this.selected_id;

      axios.patch(PATCH_URL, PATCH_DATA, helper.getConfig())
        .then((response) => {
          const { data } = response;
          const found = this.items.find(item => item.id == this.selected_id);
          found.historical_date = PATCH_DATA.historical_date;
          found.historical_user = PATCH_DATA.historical_user;
          found.historical_description = PATCH_DATA.historical_description;
          this.showLoading = false;
          this.resetForm();
        })
        .catch((error) => {
          console.log(error);
          this.showLoading = false;
        });
    },
    deleteData() {
      if (this.selected_id === "") {
        return false;
      }
      this.showLoading = true;

      const DELETE_URL = helper.getBaseUrl() + this.station_path + "/metahistoricalnote/" + this.selected_id;

      axios.delete(DELETE_URL, helper.getConfig())
        .then((response) => {
          this.items = this.items.filter(item => item.id != this.selected_id);
          this.showLoading = false;
          this.resetForm();
        })
        .catch((error) => {
          console.log(error);
          this.showLoading = false;
        });
    },
    addData() {
      if (this.date === "" || this.user === "" || this.description === "") {
        return false;
      }
      this.showLoading = true;

      const POST_DATE = new Date().toISOString().substr(0, 19).split(":").join("-");

      const POST_ID = this.profile.station_id
                    + "_metahistoricalnote_"
                    + this.date + "_"
                    + POST_DATE;

      const POST_DATA = {
        "@type": "StationHistoricalNote",
        id: POST_ID,
        historical_date: this.date,
        historical_user: this.user,
        historical_description: this.description,
      }

      const POST_URL = helper.getBaseUrl() + this.station_path + "/metahistoricalnote";

      axios.post(POST_URL, POST_DATA, helper.getConfig())
        .then((response) => {
          const { data } = response;
          this.items.push(POST_DATA);
          this.showLoading = false;
          this.resetForm();
        })
        .catch((error) => {
          console.log(error);
          this.showLoading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
