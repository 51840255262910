<template>
  <div>
    <b-row>
      <b-col sm="12">
        <b-tabs pills>
          <b-tab title="General" active>
            <general
              :profile="profile"
              :roles="roles"
              @station="shareStation($event)"
              @usageUpdate="usageUpdate($event)"
          /></b-tab>
          <b-tab :profile="profile" title="Observer" lazy>
            <observer :profile="profile" :roles="roles" />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
} from "bootstrap-vue";
import General from "./componentmkg/GeneralMkg.vue";
import Observer from "./componentmkg/ObserverMkg.vue";

export default {
  components: {
    BTab,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BTabs,
    General,
    Observer,
  },
  props: ["profile", "roles"],
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style>
.nav-pills .nav-link {
  padding: 0.786rem 1rem;
}
</style>
