<template>
	<div>
		<div class="row">
			<!-- ALL Historical Note table -->
			<b-col sm="7">
				<b-card no-body class="border">
					<b-table
						:fields="fields"
						:items="sortedItems"
						responsive
						hover
						selectable
						select-mode="single"
						@row-selected="selectedRow"
						ref="table"
						style="white-space: pre-line;"
					/>
				</b-card>
			</b-col>

			<b-col sm="5">
				<!-- Input Form -->
				<b-card class="border">
					<b-row>
						<b-col>
							<b-form-group
								label-cols="4"
								label-size="sm"
								label="Date"
								label-for="datepicker-date"
							>
								<b-form-datepicker
									id="datepicker-date"
									placeholder="Date"
									:date-format-options="{
										year: 'numeric',
										month: 'numeric',
										day: 'numeric',
									}"
									locale="en"
									size="sm"
									v-model="date"
								/>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-form-group
								label-cols="4"
								label-size="sm"
								label="User"
								label-for="input-user"
							>
								<b-form-input id="input-user" size="sm" placeholder="AUTO" v-model="user" disabled />
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-form-group
								label-cols="4"
								label-size="sm"
								label="Description"
								label-for="input-textarea"
							>
								<b-form-textarea
									id="input-textarea"
									placeholder="Description"
									rows="10"
									v-model="description"
								/>
							</b-form-group>
						</b-col>
					</b-row>
				</b-card>

				
				<b-button
					variant="gradient-primary"
					class="mb-1 mb-sm-0 mr-0 mr-sm-1"
					size="sm"
					:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					@click="resetForm()"
					v-if="isEditing"
				>
					Cancel
				</b-button>
				<b-button
					variant="gradient-warning"
					class="mb-1 mb-sm-0 mr-0 mr-sm-1"
					type="reset"
					size="sm"
					:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					@click="editData()"
					v-if="isEditing"
					:disabled="!isRoleEdit"
				>
					Edit
				</b-button>
				<b-button
					variant="gradient-danger"
					class="mb-1 mb-sm-0 mr-0 float-right"
					size="sm"
					:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					@click="deleteData()"
					v-if="isEditing"
					:disabled="!isRoleEdit"
				>
					Delete
				</b-button>
				<b-button
					variant="gradient-success"
					class="mb-1 mb-sm-0 mr-0 mr-sm-1"
					type="reset"
					size="sm"
					:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					@click="addData()"
					v-if="!isEditing"
					:disabled="!isRoleEdit"
				>
					Add Historical Note
				</b-button>
			</b-col>
		</div>
		<b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
	</div>
</template>

<script>
import axios from 'axios'
import helper from '@/api/helper';

import {
	BCard,
	BCardHeader,
	BContainer,
	BRow,
	BCol,
	BFormSelect,
	BForm,
	BButton,
	BFormGroup,
	BCollapse,
	BFormDatepicker,
	BFormInput,
	BFormTextarea,
	BTable,
	BOverlay,
} from "bootstrap-vue";

export default {
	components: {
		BCard,
		BCardHeader,
		BContainer,
		BRow,
		BCol,
		BFormSelect,
		BForm,
		BButton,
		BFormGroup,
		BCollapse,
		BFormDatepicker,
		BFormInput,
		BFormTextarea,
		BTable,
		BOverlay,
	},
	props: ["profile", "detail", "roles"],
	data: function () {
		return {
			userLogin: null,
			showLoading: false,
			isEditing: false,
			selected_id: "",
			station_path: "",
			date: "",
			user: "",
			description: "",
			pathUsage: "",
			fields: [
				{ key: "usage_historical_date", label: "Date" },
				{ key: "usage_historical_user", label: "User" },
				{ key: "usage_historical_description", label: "Description" },
			],
			items: [
				// {
				//   historical_date: "2021-10-14",
				//   historical_user: "Stamet Maimun Saleh",
				//   historical_description: "perubahan range wind speed (kt) via terminal",
				// },
			],
			isRoleAdd: false,
			isRoleEdit: false,
			isRoleView: false,
			isRoleDelete: false
		};
	},

	watch: {
		roles(val) {
			// console.log('s.roles', val)
			// console.log('this.roles', this.roles)
			if (val) {
				this.isRoleAdd = val.isRoleAdd
				this.isRoleEdit = val.isRoleEdit
				this.isRoleView = val.isRoleView
				this.isRoleDelete = val.isRoleDelete
			}
		}
	},

	computed: {
		sortedItems() {
			return this.items.sort((a, b) => -(a.id.localeCompare(b.id)));
		}
	},
	mounted() {
		this.station_path = this.$route.query.path;
		if (localStorage.userLogin) {
			this.userLogin = JSON.parse(localStorage.userLogin);
			this.user = this.userLogin.fullName;
		}
		this.pathUsage = this.detail.path

		if (this.roles) {
			// console.log('mounted - this.roles', this.roles)
			this.isRoleAdd = this.roles.isRoleAdd
			this.isRoleEdit = this.roles.isRoleEdit
			this.isRoleView = this.roles.isRoleView
			this.isRoleDelete = this.roles.isRoleDelete
		}

		this.getAllDatas();
	},
	methods: {
		resetForm() {
			this.clearSelected()
			this.isEditing = false;
			this.selected_id = "";
			this.date = "";
			if (this.userLogin) {
				this.user = this.userLogin.fullName;
			}
			this.description = "";
		},
		selectedRow(items) {
			if (items.length > 0) {
				const selected_item = items[0];
				this.isEditing = true;
				this.selected_id = selected_item.id;
				this.date = selected_item.usage_historical_date;
				// this.user = selected_item.historical_user;
				this.description = selected_item.usage_historical_description;
			}
		},
		clearSelected() {
			this.$refs.table.clearSelected()
		},
		getAllDatas() {
			this.showLoading = true;

			const GET_URL = this.pathUsage + "/historicalnote/@items";
			
			// const GET_URL = helper.getBaseUrl() + this.station_path + "/metahistoricalnote/@items";
			const GET_CONFIG = {
					mode: "cors",
					withCredentials: false,
					params: {
						include: "id,usage_historical_date,usage_historical_user,usage_historical_description",
					},
					headers: helper.getHeader(),
			};

			axios.get(GET_URL, GET_CONFIG)
				.then((response) => {
					const { data } = response;
					this.items = data.items.map(item => ({
						id: item["@name"],
						usage_historical_date: item.usage_historical_date.split("T")[0],
						usage_historical_user: item.usage_historical_user,
						usage_historical_description: item.usage_historical_description,
					}));
					this.showLoading = false;
				})
				.catch((error) => {
					console.log(error);
					this.showLoading = false;
				});
		},
		editData() {
			if (this.selected_id === "" || this.date === "" || this.user === "" || this.description === "") {
				return false;
			}
			this.showLoading = true;

			const PATCH_DATA = {
				"@type": "StationHistoricalNote",
				usage_historical_date: this.date,
				usage_historical_user: this.user,
				usage_historical_description: this.description,
			}

			const PATCH_URL = this.pathUsage + "/historicalnote/" + this.selected_id;

			axios.patch(PATCH_URL, PATCH_DATA, helper.getConfig())
				.then((response) => {
					const { data } = response;
					const found = this.items.find(item => item.id == this.selected_id);
					found.usage_historical_date = PATCH_DATA.usage_historical_date;
					found.usage_historical_user = PATCH_DATA.usage_historical_user;
					found.usage_historical_description = PATCH_DATA.usage_historical_description;
					this.showLoading = false;
					this.resetForm();
				})
				.catch((error) => {
					console.log(error);
					this.showLoading = false;
				});
		},
		deleteData() {
			if (this.selected_id === "") {
				return false;
			}
			this.showLoading = true;

			// const DELETE_URL = helper.getBaseUrl() + this.station_path + "/metahistoricalnote/" + this.selected_id;
			const DELETE_URL = this.pathUsage + "/historicalnote/" + this.selected_id;

			axios.delete(DELETE_URL, helper.getConfig())
				.then((response) => {
					this.items = this.items.filter(item => item.id != this.selected_id);
					this.showLoading = false;
					this.resetForm();
				})
				.catch((error) => {
					console.log(error);
					this.showLoading = false;
				});
		},
		addData() {
			if (this.date === "" || this.user === "" || this.description === "") {
				return false;
			}
			this.showLoading = true;

			const POST_DATE = new Date().toISOString().substr(0, 19).split(":").join("-");

			const POST_ID = this.profile.station_id
							+ "_metahistoricalnote_"
							+ this.date + "_"
							+ POST_DATE;

			const POST_DATA = {
				"@type": "StationUsageHistoricalNote",
				id: POST_ID,
				usage_historical_date: this.date,
				usage_historical_user: this.user,
				usage_historical_description: this.description,
			}
			
			const POST_URL = this.pathUsage + "/historicalnote";
			// const POST_URL = helper.getBaseUrl() + this.station_path + "/usage/historicalnote";
			// console.log('add data', POST_URL, "POST_ID", POST_ID)
			axios.post(POST_URL, POST_DATA, helper.getConfig())
				.then((response) => {
					const { data } = response;
					this.items.push(POST_DATA);
					this.showLoading = false;
					this.resetForm();
				})
				.catch((error) => {
					console.log(error);
					this.showLoading = false;
				});
		},
	},
};
</script>

<style scoped>
</style>
