<template>
  <div>
    <div class="row">
      <!-- ALL Geography table -->
      <b-col sm="8">
        <b-card no-body class="border">
          <b-table
            :fields="fields"
            :items="sortedItems"
            responsive
            hover
            selectable
            select-mode="single"
            @row-selected="selectedRow"
            ref="table"
          />
        </b-card>
      </b-col>

      <b-col sm="4">
        <!-- Input Form -->
        <b-card class="border">
          <b-row>
            <b-col>
              <b-form-group
                label-cols="4"
                label-size="sm"
                label="Latitude"
                label-for="latitude"
              >
                <b-form-input id="latitude" size="sm" placeholder="Latitude" type="number" v-model.number="latitude" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-cols="4"
                label-size="sm"
                label="Longitude"
                label-for="longitude"
              >
                <b-form-input id="longitude" size="sm" placeholder="Longitude" type="number" v-model.number="longitude" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-cols="4"
                label-size="sm"
                label="Elevation (m)"
                label-for="elevation"
              >
                <b-form-input id="elevation" size="sm" placeholder="Elevation" type="number" v-model.number="elevation" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-cols="4"
                label-size="sm"
                label="Valid From"
                label-for="datepicker-valid-from"
              >
                <b-form-datepicker
                  id="datepicker-valid-from"
                  placeholder="From"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="en"
                  size="sm"
                  v-model="valid_from"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-cols="4"
                label-size="sm"
                label="Valid To"
                label-for="datepicker-valid-to"
              >
                <b-form-datepicker
                  id="datepicker-valid-to"
                  placeholder="To"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="en"
                  size="sm"
                  v-model="valid_to"
                  reset-button
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <!-- Action Buttons -->
        <!-- <b-button
          variant="gradient-primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          size="sm"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          Back
        </b-button> -->
        <b-button
						variant="primary"
						class="mb-1 mb-sm-0 mr-0 mr-sm-1"
						size="sm"
						v-on:click="goback"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					>
						Back
        </b-button>
        <b-button
          variant="gradient-secondary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          size="sm"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="resetForm()"
          v-if="isEditing"
        >
          Cancel
        </b-button>
        <b-button
          variant="gradient-warning"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="reset"
          size="sm"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="editData()"
          v-if="isEditing"
          :disabled="!isRoleEdit"
        >
          Edit
        </b-button>
        <b-button
          variant="gradient-danger"
          class="mb-1 mb-sm-0 mr-0 float-right"
          size="sm"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="deleteConfirm()"
          v-if="isEditing"
          :disabled="!isRoleEdit"
        >
          Delete
        </b-button>
        <b-button
          variant="gradient-success"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="reset"
          size="sm"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="addGeography()"
          v-if="!isEditing"
          :disabled="!isRoleEdit"
        >
          Add Geography
        </b-button>
      </b-col>
    </div>
    <b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
  </div>
</template>

<script>
import axios from 'axios'
import helper from '@/api/helper';

import {
  BCard,
  BCardHeader,
  BContainer,
  BRow,
  BCol,
  BFormSelect,
  BForm,
  BButton,
  BFormGroup,
  BFormDatepicker,
  BFormInput,
  BTable,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BFormGroup,
    BFormDatepicker,
    BFormInput,
    BTable,
    BOverlay,
  },
  props: ["profile", "roles"],
  data: function () {
    return {
      showLoading: false,
      isEditing: false,
      selected_id: "",
      station_path: "",
      latitude: "",
      longitude: "",
      elevation: "",
      valid_from: "",
      valid_to: "",
      fields: [
        { key: "geography_latitude", label: "latitude" },
        { key: "geography_longitude", label: "longitude" },
        { key: "geography_elevation", label: "elevation (m)" },
        { key: "geography_valid_from", label: "valid from" },
        { key: "geography_valid_to", label: "valid to" },
      ],
      items: [
        // {
        //   id: "1_metageography_2022-01-08_2022-01-17T10-58-38",
        //   geography_latitude: 44,
        //   geography_longitude: 33,
        //   geography_elevation: 22,
        //   geography_valid_from: "2022-01-08",
        //   geography_valid_to: "2022-01-09",
        // },
      ],
      isRoleAdd: false,
			isRoleEdit: false,
			isRoleView: false,
			isRoleDelete: false
    };
  },
  computed: {
    sortedItems() {
      return this.items.sort((a, b) => -(a.geography_valid_from.localeCompare(b.geography_valid_from)));
    }
  },
  mounted() {
    this.station_path = this.$route.query.path;
    if (this.roles) {
      // console.log('mounted - this.roles', this.roles)
      this.isRoleAdd = this.roles.isRoleAdd
      this.isRoleEdit = this.roles.isRoleEdit
      this.isRoleView = this.roles.isRoleView
      this.isRoleDelete = this.roles.isRoleDelete
    }
    this.getAllGeography();
  },

  watch: {
		roles(val) {
			// console.log('s.roles', val)
			// console.log('this.roles', this.roles)
			if (val) {
				this.isRoleAdd = val.isRoleAdd
				this.isRoleEdit = val.isRoleEdit
				this.isRoleView = val.isRoleView
				this.isRoleDelete = val.isRoleDelete
			}
		}
	},

  methods: {
    goback() {
			this.$router.push({ name: "metadata" });
		},
    resetForm() {
      this.clearSelected()
      this.isEditing = false;
      this.selected_id = "";
      this.latitude = "";
      this.longitude = "";
      this.elevation = "";
      this.valid_from = "";
      this.valid_to = "";
    },
    selectedRow(items) {
      if (items.length > 0) {
        const selected_item = items[0];
        this.isEditing = true;
        this.selected_id = selected_item.id;
        this.latitude = selected_item.geography_latitude;
        this.longitude = selected_item.geography_longitude;
        this.elevation = selected_item.geography_elevation;
        this.valid_from = selected_item.geography_valid_from;
        this.valid_to = selected_item.geography_valid_to;
      }
    },
    clearSelected() {
      this.$refs.table.clearSelected()
    },

    editData() {
      if (this.selected_id === "" || this.latitude === "" || this.longitude === "" || this.elevation === "" || this.valid_from === "") {
        return false;
      }
      this.showLoading = true;

      const PATCH_DATA = {
        "@type": "StationGeography",
        geography_latitude: this.latitude,
        geography_longitude: this.longitude,
        geography_elevation: this.elevation,
        geography_valid_from: this.valid_from,
        geography_valid_to: this.valid_to || null,
      }

      const PATCH_URL = helper.getBaseUrl() + this.station_path + "/metageography/" + this.selected_id;

      axios.patch(PATCH_URL, PATCH_DATA, helper.getConfig())
        .then((response) => {
          const { data } = response;
          const found = this.items.find(item => item.id == this.selected_id);

          this.updateMetadataParent(this.latitude, this.longitude, this.elevation, this.valid_to)

          this.createHistoricalNote(`perubahan data geography
          latitude: ${found.geography_latitude} => ${this.latitude}
          longitude: ${found.geography_longitude} => ${this.longitude}
          elevation: ${found.geography_elevation} => ${this.elevation}
          valid_from: ${found.geography_valid_from} => ${this.valid_from}
          valid_to: ${found.geography_valid_to} => ${this.valid_to || null}
          `);
          found.geography_latitude = PATCH_DATA.geography_latitude;
          found.geography_longitude = PATCH_DATA.geography_longitude;
          found.geography_elevation = PATCH_DATA.geography_elevation;
          found.geography_valid_from = PATCH_DATA.geography_valid_from;
          found.geography_valid_to = PATCH_DATA.geography_valid_to || null;
          this.showLoading = false;
          this.resetForm();
        })
        .catch((error) => {
          console.log(error);
          this.showLoading = false;
        });
    },
    deleteConfirm() {
      this.$swal({
        title: "Apakah Anda Yakin?",
        text: "Apakah Anda Yakin untuk melakukan penghapusan data?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-dark ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteData();
        }
      });
    },
    deleteData() {
      if (this.selected_id === "") {
        return false;
      }
      this.showLoading = true;

      const DELETE_URL = helper.getBaseUrl() + this.station_path + "/metageography/" + this.selected_id;

      axios.delete(DELETE_URL, helper.getConfig())
        .then((response) => {
          this.items = this.items.filter(item => item.id != this.selected_id);
          this.showLoading = false;
          this.createHistoricalNote(`penghapusan data geography
            latitude: ${this.latitude}
            longitude: ${this.longitude}
            elevation: ${this.elevation}
            valid_from: ${this.valid_from}
            valid_to: ${this.valid_to || null}
            `);
          this.resetForm();
        })
        .catch((error) => {
          console.log(error);
          this.showLoading = false;
        });
    },

    getAllGeography() {
      this.showLoading = true;

      const GET_URL = helper.getBaseUrl() + this.station_path + "/metageography/@items_list";
      const GET_CONFIG = {
        mode: "cors",
        withCredentials: false,
        params: {
          include: "id,geography_latitude,geography_longitude,geography_elevation,geography_valid_from,geography_valid_to",
        },
        headers: helper.getHeader(),
      };

      axios.get(GET_URL, GET_CONFIG)
        .then((response) => {
          const { data } = response;
          this.items = data.items.map(item => ({
            id: item["@name"],
            geography_latitude: item.geography_latitude,
            geography_longitude: item.geography_longitude,
            geography_elevation: item.geography_elevation,
            geography_valid_from: item.geography_valid_from?.split("T")[0],
            geography_valid_to: item.geography_valid_to?.split("T")[0] || null,
          }));
          this.showLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.showLoading = false;
        });
    },

    addGeography() {
      if (this.latitude === "" || this.longitude === "" || this.elevation === "" || this.valid_from === "") {
        return false;
      }
      this.showLoading = true;

      const POST_DATE = new Date().toISOString().substr(0, 19).split(":").join("-");

      const POST_ID = this.profile.station_id
                    + "_metageography_"
                    + this.valid_from + "_"
                    + POST_DATE;

      const POST_DATA = {
        "@type": "StationGeography",
        id: POST_ID,
        geography_latitude: this.latitude,
        geography_longitude: this.longitude,
        geography_elevation: this.elevation,
        geography_valid_from: this.valid_from,
        geography_valid_to: this.valid_to || null,
      }

      const POST_URL = helper.getBaseUrl() + this.station_path + "/metageography";

      axios.post(POST_URL, POST_DATA, helper.getConfig())
        .then((response) => {
          const { data } = response;
          this.items.push(POST_DATA);
          this.showLoading = false;
          this.updateMetadataParent(this.latitude, this.longitude, this.elevation, this.valid_to)
          this.createHistoricalNote(`penambahan data geography
            latitude: ${this.latitude}
            longitude: ${this.longitude}
            elevation: ${this.elevation}
            valid_from: ${this.valid_from}
            valid_to: ${this.valid_to || null}
            `);
          this.resetForm();
        })
        .catch((error) => {
          console.log(error);
          this.showLoading = false;
        });
    },

    async updateMetadataParent(latitude, longitude, elevation, valid_to) {
      console.log('updateMetadataParent: ', latitude, longitude, elevation, valid_to)
      if (!valid_to || valid_to == '') {
        let POST_DATA = {
          "current_latitude": latitude,
          "current_longitude": longitude,
          "current_elevation": elevation,
          "station_elevation": elevation
        };

      const POST_URL = helper.getBaseUrl() + this.station_path ;
      this.showLoading = true;
      await axios.patch(POST_URL, POST_DATA, helper.getConfig())
        .then((response) => {
          const { data } = response;
          this.showLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.showLoading = false;
        });
      }
    },

    createHistoricalNote(description) {
      // get user login
      let user = "";
      if (localStorage.userLogin) {
        const userLogin = JSON.parse(localStorage.userLogin);
        user = userLogin.fullName;
      }

      if (user === "" || description === "") {
        return false;
      }
      this.showLoading = true;

      const NOW = new Date().toISOString();
      const date = NOW.split("T")[0];
      const POST_DATE = NOW.substr(0, 19).split(":").join("-");

      const POST_ID = this.profile.station_id
                    + "_metahistoricalnote_"
                    + date + "_"
                    + POST_DATE;

      const POST_DATA = {
        "@type": "StationHistoricalNote",
        id: POST_ID,
        historical_date: date,
        historical_user: user,
        historical_description: description,
      }

      const POST_URL = helper.getBaseUrl() + this.station_path + "/metahistoricalnote";

      axios.post(POST_URL, POST_DATA, helper.getConfig())
        .then((response) => {
          const { data } = response;
          this.showLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.showLoading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
