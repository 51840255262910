<template>
    <div>
        <div class="row">
            <b-col sm="6">
                <!-- Parameter -->
                <b-card no-body class="border mt-1">
                    <b-row class="pl-1 pr-1 mt-1">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="Station ID :" label-for="input-station-id">
                                <b-form-input id="input-station-id" size="sm" v-model="station_id" disabled />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="Wigos ID :" label-for="input-wigos-id">
                                <b-form-input id="input-wigos-id" size="sm" v-model="wigos_id" disabled />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="Name :" label-for="input-station-name">
                                <b-form-input id="input-station-name" size="sm" v-model="station_name" disabled />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label=" Latitude :" label-for="input-latitude">
                                <b-form-input id="input-latitude" size="sm" v-model="latitude" disabled />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="Longitude :" label-for="input-longitude">
                                <b-form-input id="input-longitude" size="sm" v-model="longitude" disabled />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="Elevation (m) :" label-for="input-elevation">
                                <b-form-input id="input-elevation" size="sm" v-model="elevation" disabled />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label=" Time Zone :" label-for="select-timezone">
                                <b-form-select v-model="timezone" id="select-timezone" size="sm" :options="options.timezone"></b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-size="sm" label="Balai :" label-for="select-region" size="sm">
                                <v-select id="select-region" size="sm" label="text" :reduce="(item) => item.value" v-model="region" :options="options.region" class="select-size-sm" disabled />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-size="sm" label="Provinsi :" label-for="select-propinsi" size="sm">
                                <v-select id="select-propinsi" size="sm" label="text" :reduce="(item) => item.value" v-model="propinsi" :options="options.propinsi" class="select-size-sm" disabled />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-size="sm" label="Kabupaten :" label-for="select-kabupaten" size="sm">
                                <v-select id="select-kabupaten" size="sm" label="text" :reduce="(item) => item.value" v-model="kabupaten" :options="options.kabupaten" class="select-size-sm" disabled />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-size="sm" label="Kabupaten :" label-for="select-kabupaten" size="sm">
                                <v-select id="select-kabupaten" size="sm" label="text" :reduce="(item) => item.value" v-model="kabupaten" :options="options.kabupaten" class="select-size-sm" disabled />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="Waktu Setempat (WS) :" label-for="input-ws">
                                <b-form-input id="input-ws" size="sm" v-model="ws" placeholder="Pilih timezone terlebih dahulu" disabled />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card>

                <div>
                    <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" v-on:click="goback" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                        Back
                    </b-button>

                    <b-button variant="success" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="updateStation()" :disabled="!isRoleEdit">
                        Update
                    </b-button>

                    <!-- <b-button
						variant="success"
						class="mb-1 mb-sm-0 mr-0 mr-sm-1"
						size="sm"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
						v-if="isEditing"
						@click="updateStation()"
					>
						Update
					</b-button> -->
                    <!-- <b-button
						variant="warning"
						class="mb-1 mb-sm-0 mr-0 mr-sm-1"
						size="sm"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
						v-else
						@click="isEditing = true"
					>
						Edit
					</b-button> -->

                    <b-button variant="danger" class="mb-1 mb-sm-0 ml-0 ml-sm-1 float-right" type="reset" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="onDeleteStation()" :disabled="!isRoleDelete || !isRoleEdit">
                        Delete
                    </b-button>
                </div>
            </b-col>

            <b-col sm="5" class="ml-2">
                <b-card class="border mt-1">
                    <b-form-group label-size="sm" label="Select Usage" label-for="observer">
                        <v-select v-on:input="changeSelection(`${t_usage}`)" v-model="t_usage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="t_usage_opt" class="select-size-sm" style="margin-top: -2px;" />
                    </b-form-group>
                    <b-button variant="primary" class="mb-1 mb-sm-0 ml-0 ml-sm-1 float-right" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'" :disabled="t_usage == '' || !isRoleEdit" @click="addUsage()">
                        Add Usage
                    </b-button>
                </b-card>

                <!-- <b-card class="border mt-1">
					<label>USAGE</label>
					<b-row class="ml-2 mt-1 mb-1">
						<b-col>
							<b-form-checkbox
								v-model="mkg_selected"
								class="custom-control-info"
								@change="usageChecked('mkg_selected', $event)"
							>
							MKG
							</b-form-checkbox>
						</b-col>
					</b-row>
					<b-row class="ml-2 mb-1">
						<b-col>
							<b-form-checkbox
								v-model="pos_hujan_selected"
								class="custom-control-info"
								@change="usageChecked('pos_hujan_selected', $event)"
							>
							Pos Hujan
							</b-form-checkbox>
						</b-col>
					</b-row>
					<b-row class="ml-2 mb-1">
						<b-col>
							<b-form-checkbox
								v-model="ku_selected"
								class="custom-control-info"
								@change="usageChecked('ku_selected', $event)"
							>
							Kualitas Udara (KU)
							</b-form-checkbox>
						</b-col>
					</b-row>
					<b-row class="ml-2 mb-1">
						<b-col>
							<b-form-checkbox
								v-model="aws_selected"
								class="custom-control-info"
								@change="usageChecked('aws_selected', $event)"
							>
							AWS Center
							</b-form-checkbox>
						</b-col>
					</b-row>

					<b-row class="ml-2 mb-1">
						<b-col>
							<b-form-checkbox
								v-model="radar_selected"
								class="custom-control-info"
								@change="usageChecked('radar_selected', $event)"
							>
							Radar
							</b-form-checkbox>
						</b-col>
					</b-row>

					<b-row class="ml-2 mb-1">
						<b-col>
							<b-form-checkbox
								v-model="accelerograph_selected"
								class="custom-control-info"
								@change="usageChecked('accelerograph_selected', $event)"
							>
							Percepatan Tanah
							</b-form-checkbox>
						</b-col>
					</b-row>

					<b-row class="ml-2 mb-1">
						<b-col>
							<b-form-checkbox
								v-model="gempa_selected"
								class="custom-control-info"
								@change="usageChecked('gempa_selected', $event)"
							>
							Gempa
							</b-form-checkbox>
						</b-col>
					</b-row>
					<b-row class="ml-2 mb-1">
						<b-col>
							<b-form-checkbox
								v-model="bmkgentry_selected"
								class="custom-control-info"
								@change="usageChecked('bmkgentry_selected', $event)"
							>
							BMKG Entry
							</b-form-checkbox>
						</b-col>
					</b-row>
				</b-card> -->
            </b-col>
        </div>

        <b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
    </div>
</template>

<script>
//import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BCard, BCardHeader, BContainer, BRow, BCol, BFormSelect, BForm, BButton, BFormGroup, BCollapse, BFormDatepicker, BFormInput, BOverlay, BFormCheckbox } from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "axios";
import helper from "@/api/helper";
import GenHelper from "@/helper/helper";
import metadata from "@/api/metadata";
import error_handler from "@/api/error_handler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
//import aaws from "./monitoringcomponent/aaws.vue";
// import Aktinograph from "./piascomponent/aktinograph.vue";

export default {
    components: {
        BCard,
        BCardHeader,
        BContainer,
        BRow,
        BCol,
        BFormSelect,
        BForm,
        BButton,
        BFormGroup,
        BCollapse,
        BFormDatepicker,
        BFormInput,
        BOverlay,
        vSelect,
        BFormCheckbox,
    },

    props: ["listUsage", "roles"],

    data() {
        return {
            isEditing: false,
            showLoading: false,
            path: "",
            region: "",
            propinsi: "",
            kabupaten: "",
            station_id: "",
            wigos_id: "",
            station_name: "",
            latitude: "",
            longitude: "",
            elevation: "",
            soil: "",
            exposure: "",
            land_use: "",
            timezone: "",
            options: {
                region: [],
                propinsi: [],
                kabupaten: [],
                soil: [
                    { value: 47, text: "Tanah sawah" },
                    { value: 49, text: "Calcisol" },
                    { value: 50, text: "Regosol" },
                    { value: 53, text: "Tanah organik" },
                    { value: 41, text: "Latosol" },
                    { value: 44, text: "Mediteran merah kuning" },
                    { value: 45, text: "Regur" },
                    { value: 46, text: "Podsol" },
                    { value: 48, text: "Hidrosol" },
                    { value: 51, text: "Litosol" },
                    { value: 52, text: "Aluvial" },
                    { value: 61, text: "Basal Peroksin" },
                    { value: 62, text: "Sandstone" },
                    { value: 42, text: "Andosol" },
                    { value: 43, text: "Podsolik merah kuning" },
                    { value: 81, text: "Aluvial" },
                ],
                exposure: [
                    { value: 42, text: "Arah barat terhalang obstacle" },
                    { value: 2, text: "Arah timur terhalang obstacle" },
                    { value: 47, text: "Arah timur-selatan terhalang obstacle" },
                    { value: 22, text: "Stasiun tdk ada obstacle " },
                    { value: 49, text: "Arah selatan-barat terhalang obstacle" },
                    { value: 43, text: "2.3 arah utara-timur terhalang obstacle" },
                    { value: 21, text: "Arah utara terhalang obstacle" },
                    { value: 41, text: "Arah selatan terhalang obstacle" },
                    { value: 44, text: "Arah utara-slatan terhalang obstacle" },
                    { value: 45, text: "Arah utara-barat terhalang obstacle" },
                    { value: 48, text: "Arah timur-barat terhalang obstacle" },
                ],
                land_use: [
                    { value: 22, text: "Sawah" },
                    { value: 26, text: "Alang-alang dan semak belukar" },
                    { value: 28, text: "Lahan lain-lain" },
                    { value: 21, text: "Perkampungan" },
                    { value: 23, text: "Tegalan dan kebun" },
                    { value: 24, text: "Ladang berpindah" },
                    { value: 25, text: "Hutan" },
                    { value: 27, text: "Rawa" },
                ],
                timezone: ["+07:00", "+08:00", "+09:00"],
            },
            gempa_selected: false,
            accelerograph_selected: false,
            radar_selected: false,
            aws_selected: false,
            ku_selected: false,
            pos_hujan_selected: false,
            mkg_selected: false,
            bmkgentry_selected: false,
            t_usage: "",
            t_usage_opt: [],
            isRoleAdd: false,
            isRoleEdit: false,
            isRoleView: false,
            isRoleDelete: false,
        };
    },

    mounted() {
        this.path = this.$route.query.path;

        // console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaa')
        this.getStationData();
        this.getRegion();
        this.getPropinsi();
        this.getKabupaten();
        this.listManageUsage();
    },

    watch: {
        roles(val) {
            // console.log('s.roles', val)
            // console.log('this.roles', this.roles)
            if (val) {
                this.isRoleAdd = val.isRoleAdd;
                this.isRoleEdit = val.isRoleEdit;
                this.isRoleView = val.isRoleView;
                this.isRoleDelete = val.isRoleDelete;
            }
        },
    },

    computed: {
        ws() {
            const tzz = this.timezone?.replace("+", "").split(":");
            if (tzz?.length > 1 && GenHelper.isValidValue(this.longitude)) {
                return GenHelper.rumusWaktuSetempat(+tzz[0], this.longitude);
            } else {
                return "";
            }
        },
    },

    methods: {
        changeSelection(object) {
            // console.log('changeSelection', object)

            if (!object) {
                this.t_usage = "";
            }
        },

        addUsage() {
            let isUsageExist = false;
            let isActive = false;
            let usageSelect = null;
            if (this.listUsage && this.listUsage.length > 0) {
                for (let usage of this.listUsage) {
                    if (usage.label.toLowerCase() == this.t_usage.label.toLowerCase()) {
                        let detail = usage.detail;
                        isUsageExist = true;

                        if (detail.usage_status) {
                            isActive = true;
                        } else {
                            usageSelect = usage;
                        }
                        break;
                    }
                }
            }

            if (isUsageExist) {
                if (isActive) {
                    this.$swal({
                        title: "Usage Exist",
                        text: "Usage '" + this.t_usage.label + "' pada stasiun ini sudah ada.",
                        icon: "primary",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                } else {
                    this.$swal({
                        title: "Add Usage?",
                        text: "Anda akan menambahkan usage '" + this.t_usage.label + "' pada stasiun ini ?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Ya",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                            confirmButton: "btn btn-primary",
                            cancelButton: "btn btn-outline-danger ml-1",
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        this.onEditStatusUsage(this.t_usage.label, usageSelect);
                    });
                }
            } else {
                this.$swal({
                    title: "Add Usage?",
                    text: "Anda akan menambahkan usage '" + this.t_usage.label + "' pada stasiun ini?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Ya",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1",
                    },
                    buttonsStyling: false,
                }).then((result) => {
                    this.onRequestAddUsage(this.t_usage.label);
                });
            }
        },

        onRequestAddUsage(usage_name) {
            new Promise(async (resolve) => {
                // console.log('this.path', this.path)
                let path = this.path + "/usage";
                let post_data = {
                    "@type": "MetaStationUsage",
                    "BmkgSatu.metadata.station.behavusage.IBehavStationUsage": {
                        usage_name: usage_name,
                        usage_station_id: "-1",
                        usage_status: true,
                    },
                };
                this.showLoading = true;
                const { data } = await metadata.requestMetadataUsage(path, post_data);
                this.showLoading = false;
                console.log("data: ", data);

                let detail = {
                    usage_contact_name: null,
                    usage_contact_telephone: null,
                    usage_elevation: null,
                    usage_end_date: null,
                    usage_koordinator_station: null,
                    usage_latitude: null,
                    usage_longitude: null,
                    usage_name: "",
                    usage_start_date: null,
                    usage_station_id: "",
                    usage_station_name: null,
                    usage_status: true,
                    usage_wigos_id: "",
                };

                let row = {
                    value: usage_name,
                    label: usage_name,
                    status: true,
                    path: data["@id"],
                    detail: detail,
                };
                this.$emit("newUpdateUsage", row);
                resolve(data);
            });
        },

        onEditStatusUsage(usage_name, usageObj) {
            this.showLoading = true;
            new Promise(async (resolve) => {
                console.log("usage_name", usage_name, "usageObj", usageObj);
                console.log("usageObj: ", usageObj.path);

                let post_data = {
                    "BmkgSatu.metadata.station.behavusage.IBehavStationUsage": {
                        usage_status: true,
                    },
                };

                const usageUrl = usageObj.path;
                axios
                    .patch(usageUrl, post_data, helper.getConfig())
                    .then((response) => {
                        if (response.status == 201 || response.status == 200 || response.status == 204) {
                            let detail = usageObj.detail;
                            detail.usage_status = true;

                            this.showLoading = false;

                            this.$emit("refreshRendering", "refresh");
                        }
                    })
                    .catch((error) => {
                        this.showLoading = false;
                        error_handler(this, error, "Metadata Stasiun", "Update Data Error");
                    });

                resolve(usageObj);
            });
        },

        filterOption(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
        usageChecked(usage, event) {
            // console.log('usage- event: ', usage+' - '+event)
        },
        goback() {
            this.$router.push({ name: "metadata" });
        },
        getStationData() {
            this.showLoading = true;
            let url = helper.getBaseUrl() + this.path;
            axios.get(url, helper.getConfig()).then((response) => {
                if (response.status == 201 || response.status == 200 || response.status == 204) {
                    // console.log("station-general: ", response.data);
                    this.$emit("station", response.data);
                    const { data } = response;

                    this.region = data.region_id;
                    this.propinsi = data.propinsi_id;
                    this.kabupaten = data.kabupaten_id;
                    this.station_id = data.station_wmo_id;
                    this.station_name = data.station_name;
                    // this.latitude = data.current_latitude;
                    // this.longitude = data.current_longitude;
                    // this.elevation = data.current_elevation;
                    this.wigos_id = data.wigos_id;
                    // this.soil = data.soil_id;
                    // this.exposure = data.exposure_id;
                    // this.land_use = data.land_use_id;
                    this.timezone = data.timezone;
                    // this.mkg_selected = data.is_mkg,
                    // this.bmkgentry_selected = data.is_bmkgentry
                    // this.pos_hujan_selected = data.is_poshujan
                    // this.ku_selected = data.is_kualitas_udara
                    // this.aws_selected = data.is_peralatan_otomatis
                    // this.radar_selected = data.is_radar
                    // this.accelerograph_selected = data.is_percepatan_tanah
                    // this.gempa_selected = data.is_gempa

                    if (data.station_wmo_id == 0 && data.is_poshujan) {
                        let posHujan = data["BmkgSatu.metadata.poshujan.IPosHujan"];
                        this.station_id = posHujan.pos_hujan_id;
                    } else if (!data.is_mkg || !data.station_wmo_id) {
                        this.station_id = data.alias_station_id;
                    }

                    let node = this.getGeography(this.path);
                }
                this.showLoading = false;
            });
        },

        async getGeography(path) {
            this.showLoading = true;
            metadata.getListMetaGeography(path).then((response) => {
                // console.log('getListMetaGeography',response.data.items)
                let validGeo = null;
                for (let item of response.data.items) {
                    if (!item.geography_valid_to) {
                        validGeo = item;
                        break;
                    } else {
                        if (validGeo && new Date(item.geography_valid_to) > new Date(validGeo.geography_valid_to)) {
                            validGeo = item;
                        }
                    }
                }
                // console.log('validGeo', validGeo)
                if (validGeo) {
                    this.latitude = validGeo.geography_latitude;
                    this.longitude = validGeo.geography_longitude;
                    this.elevation = validGeo.geography_elevation;
                }
                this.showLoading = false;
            });
        },

        getRegion() {
            this.showLoading = true;
            metadata.getRegionList().then((response) => {
                this.options.region = response.data.map((item) => ({
                    value: item.region_id,
                    text: item.region_description,
                }));
                this.showLoading = false;
            });
        },
        getPropinsi() {
            this.showLoading = true;
            metadata.getPropinsiList().then((response) => {
                this.options.propinsi = response.data.map((item) => ({
                    value: item.propinsi_id,
                    text: item.propinsi_name,
                }));
                this.showLoading = false;
            });
        },
        getKabupaten() {
            this.showLoading = true;
            metadata.getKabupatenList().then((response) => {
                this.options.kabupaten = response.data.map((item) => ({
                    value: item.kabupaten_id,
                    text: item.kabupaten_name,
                }));
                this.showLoading = false;
            });
        },
        updateStation() {
            this.showLoading = true;
            const propinsi = this.options.propinsi.find(({ value }) => value === this.propinsi);
            // console.log('propinsi check', propinsi)
            const update_data = {
                // station_name: this.station_name,
                // station_platform_name: this.station_name,
                // current_latitude: this.latitude,
                // current_longitude: this.longitude,
                // current_elevation: this.elevation,
                // station_elevation: this.elevation,
                // wigos_id: this.wigos_id,
                // soil_id: this.soil,
                // exposure_id: this.exposure,
                // land_use_id: this.land_use,
                propinsi_name: propinsi.text,
                timezone: this.timezone,
                // is_mkg:this.mkg_selected,
                // is_bmkgentry: this.bmkgentry_selected,
                // is_poshujan: this.pos_hujan_selected,
                // is_kualitas_udara: this.ku_selected,
                // is_peralatan_otomatis: this.aws_selected,
                // is_radar: this.radar_selected,
                // is_percepatan_tanah: this.accelerograph_selected,
                // is_gempa: this.gempa_selected
                sync_metastation: 1,
            };

            const update_url = helper.getBaseUrl() + this.path;

            axios
                .patch(update_url, update_data, helper.getConfig())
                .then((response) => {
                    if (response.status == 201 || response.status == 200 || response.status == 204) {
                        this.$swal({
                            title: "Update Data berhasil!",
                            text: "Update Data Metadata Stasiun",
                            icon: "success",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                        this.showLoading = false;

                        this.$emit("usageUpdate", update_data);
                    }
                })
                .catch((error) => {
                    this.showLoading = false;
                    error_handler(this, error, "Metadata Stasiun", "Update Data Error");
                });
            this.isEditing = false;
        },

        async listManageUsage() {
            // console.log('listUsage')
            try {
                const { data } = await metadata.getListManageUsage();
                let usagesList = data.items.sort((a, b) => {
                    let fa = a.manage_usage_name.toLowerCase();
                    let fb = b.manage_usage_name.toLowerCase();

                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                });
                for (var i = 0; i < usagesList.length; i++) {
                    if (usagesList[i].manage_usage_status) {
                        const row = {
                            value: usagesList[i].manage_usage_id,
                            label: usagesList[i].manage_usage_name,
                            status: usagesList[i].manage_usage_status,
                            path: usagesList[i]["@id"],
                        };
                        this.t_usage_opt.push(row);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        },

        onDeleteStation() {
            this.$swal({
                title: "Apakah Anda yakin untuk menghapus metadata stasiun " + this.station_name + " dari database?",
                text: "Critical Data! Perlu perhatian, Anda akan kehilangan data lain yang berhubungan dengan stasiun '" + this.station_name + "'' ini. Pastikan bahwa data tersebut tidak anda butuhkan lagi. ",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Ya",
                allowOutsideClick: false,
                allowEscapeKey: false,
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                console.log("result", result);
                if (result.isConfirmed) {
                    // this.onConfirmDelete();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Silahkan menghubungi administrator untuk menghapus data ini.",
                            icon: "BellIcon",
                            variant: "danger",
                        },
                    });
                } else {
                    console.log("cancel delete");
                }
            });
        },

        async onConfirmDelete() {
            this.showLoading = true;
            const delete_url = helper.getBaseUrl() + this.path;
            console.log("deleteStation", delete_url, this.station_name);
            axios
                .delete(delete_url, helper.getConfig())
                .then((response) => {
                    if (response.status == 201 || response.status == 200 || response.status == 202 || response.status == 203 || response.status == 204) {
                        this.$swal({
                            title: "Delete Data berhasil!",
                            text: "Delete Data Metadata Stasiun",
                            icon: "success",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                        this.showLoading = false;

                        this.$emit("deleteStation", this.station_name + " telah berhasil dihapus dari database");
                    }
                })
                .catch((error) => {
                    this.showLoading = false;
                    error_handler(this, error, "Metadata Stasiun", "Update Data Error");
                });
            // this.$emit("deleteStation", this.station_name + " telah berhasil dihapus dari database");
            // this.showLoading = false;
        },
    },
};
</script>

<style scoped>
.pad2 {
    padding-left: 2px;
    padding-right: 2px;
}
.mt {
    margin-top: -9px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
