<template>
  <div>
    <b-card no-body>
      <b-row>
        <b-col sm="12">
          <b-tabs>
            <b-tab title="Input Assignment" active>
              <general :profile="profile" :roles="roles" @settingForm="updateSetting($event)" @goBack="onBack($event)" />
            </b-tab>
            <b-tab lazy title="Input Operator">
              <entry-user :roles="roles" @settingForm="updateSetting($event)" @goBack="onBack($event)" />
            </b-tab>
            <b-tab v-if="is_Mkg" lazy title="Pos Hujan Station">
              <pos-hujan :profile="profile" :roles="roles" @settingForm="updateSetting($event)" @goBack="onBack($event)" />
            </b-tab>
            <!-- <b-tab title="Input Settings">
              <settings :profile="profile" :roles="roles" @settingForm="updateSetting($event)" @goBack="onBack($event)" />
            </b-tab> -->
            <!-- <b-tab v-for="(item, index) in filtered_list_entry" :key="item + index" :title="humanize(`${item}`)">
              <be-forms :profile="profile" :type_form="item" :roles="roles" />
            </b-tab>
            <b-tab title="QC Rumus Otomatis">
              <be-auto-qc :profile="profile" :roles="roles"></be-auto-qc>
            </b-tab> -->
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BTab, BTabs, BRow, BCol, BCard } from "bootstrap-vue";
import Settings from "./componentbmkgentry/BeSettings.vue";
import General from "./componentbmkgentry/BeGeneral.vue";
import EntryUser from "./componentbmkgentry/BeEntryUser.vue";
import PosHujan from "./componentbmkgentry/BePostHujan.vue";
import BeForms from "./componentbmkgentry/BeForms.vue";
import BeAutoQc from "./componentbmkgentry/BeAutoQc";
import Params from "@/attr/metadata/params";
export default {
  components: {
    BTab,
    BRow,
    BCol,
    BCard,
    BTabs,
    Settings,
    General,
    EntryUser,
    PosHujan,
    BeForms,
    BeAutoQc,
  },
  props: ["profile", "roles", "isMkg"],
  data() {
    return {
      is_Mkg: false,
      listbmkgentry: [],
    };
  },
  mounted() {
    if (this.profile) {
      this.is_Mkg = this.isMkg
    }
    // console.log("bmkgentry : ", this.profile);
    // console.log("BmkgSatu.metadata.qc.IME48 : ", this.profile["BmkgSatu.metadata.qc.IME48qc"]);
  },
  methods: {
    updateSetting(event) {
      this.listbmkgentry = event;
    },
    humanize(str) {
      var i,
        frags = str.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    onBack(event) {
      this.$emit("goBack", "back");
    },
    listQcAuto() {
      var Qc_sinop = this.profile["BmkgSatu.metadata.qc.IME48qc"];
    },
  },
  computed: {
    filtered_list_entry() {
      // return hanya item yang ada qc nya
      return this.listbmkgentry.filter((item) => Params.GET_QC_STATION_KEY(item));
    },
  },
};
</script>
<style>
.nav-pills .nav-link {
  padding: 0.786rem 1rem;
}
</style>
