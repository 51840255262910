<template>
  <div>
    <b-row>
      <b-col :xl="(currentSelectedMeteo.metar || currentSelectedMeteo.pibal || currentSelectedMeteo.sinoptik || currentSelectedMeteo.rason) ? 12 : 9" lg="12">
        <!-- Parameter TABLE -->
        <b-card no-body class="border mt-1">
          <b-row class="m-1">
            <b-col :cols="(currentSelectedMeteo.metar || currentSelectedMeteo.pibal || currentSelectedMeteo.sinoptik || currentSelectedMeteo.rason) ? 4 : 3">
              <label>METEOROLOGI</label>
              <!-- <b-form-checkbox-group v-model="selectedMeteo" :options="optionsMeteo" stacked></b-form-checkbox-group> -->
              
              <div>
                <b-form-checkbox v-model="currentSelectedMeteo.sinoptik">
                  Sinoptik
                </b-form-checkbox>
                <div v-if="currentSelectedMeteo.sinoptik" class="border ml-2 p-1 mb-1">
                  <b-row class="mb-1">
                    <b-col xl="4">
                      <b-form-checkbox
                        v-model="entry_first_enable"
                        value=true
                      >
                        First Input
                      </b-form-checkbox>
                    </b-col>
                    
                    <b-col xl="5">
                      <b-form-datepicker
                        id="datepicker-2"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        locale="en"
                        size="sm"
                        placeholder="tanggal"
                        v-model="entry_first_date"
                        :disabled="!entry_first_enable"
                      />
                    </b-col>

                    <b-col xl="3">
                      <label> Jam 01:00</label>
                    </b-col>
                  </b-row>
                  <b-form-group
                    label="Operating Hours :"
                    label-for="operating-hours"
                  >
                    <b-form-input
                      id="operating-hours"
                      size="sm"
                      v-model="selectedMKG.station_operating_hours"
                    />
                  </b-form-group>                              
                
                  <b-row class="mt">
                    <b-col cols="2"/>
                    <b-col cols="10">
                      <b-row>
                        <b-col cols="6">
                          <b-form-group
                              label-cols="4"
                              label-cols-lg="4"
                              label-size="sm"
                              label="Start:"
                              label-for="select-start"
                            >
                            <b-form-select
                              v-model="selectedMKG.station_hour_start"
                              id="select-start"
                              size="sm"
                              :options="options.time_type"
                            ></b-form-select>
                          </b-form-group>
                        </b-col>

                        <b-col cols="6" v-if="is_mid_hour">
                          <b-form-group
                              label-cols="4"
                              label-cols-lg="4"
                              label-size="sm"
                              label="Mid End:"
                              label-for="select-start"
                            >
                            <b-form-select
                              v-model="selectedMKG.station_mid_hour_end"
                              id="select-start"
                              size="sm"
                              :options="options.time_type"
                            ></b-form-select>
                          </b-form-group>
                        </b-col>

                        <b-col cols="6" v-if="is_mid_hour">
                          <b-form-group
                              label-cols="4"
                              label-cols-lg="4"
                              label-size="sm"
                              label="Mid Start:"
                              label-for="select-start"
                            >
                            <b-form-select
                              v-model="selectedMKG.station_mid_hour_start"
                              id="select-start"
                              size="sm"
                              :options="options.time_type"
                            ></b-form-select>
                          </b-form-group>
                        </b-col>

                        <b-col cols="6">
                          <b-form-group
                              label-cols="4"
                              label-cols-lg="4"
                              label-size="sm"
                              label="End:"
                              label-for="select-end"
                            >
                            <b-form-select
                              v-model="selectedMKG.station_hour_end"
                              id="select-end"
                              size="sm"
                              :options="options.time_type"
                            ></b-form-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div> 
              </div>

              <div class="">
                <b-form-checkbox v-model="currentSelectedMeteo.metar">
                  Metar Speci
                </b-form-checkbox>
                <b-form-group v-if="currentSelectedMeteo.metar" class="border ml-2 p-1 mb-1">
                  <!-- <b-form-checkbox style="margin-top: 0.2rem !important;" inline v-model="selectedMetar.is_metar_hourly">
                    Hourly
                  </b-form-checkbox>
                  <b-form-checkbox style="margin-top: 0.2rem !important;" inline v-model="selectedMetar.is_metar_half_hourly">
                    Half Hourly
                  </b-form-checkbox> -->
                  <b-form-radio-group
                    v-model="radioMetarSelected"
                    :options="radioMetarOptions"
                    class="demo-inline-spacing"
                    name="radio-inline"
                  />
                  
                </b-form-group>
              </div>

              <div class="">
                <b-form-checkbox v-model="currentSelectedMeteo.pibal">
                  Pilot Balon
                </b-form-checkbox>
                <b-form-group v-if="currentSelectedMeteo.pibal" class="border ml-2 p-1 mb-1">
                  <b-form-checkbox style="margin-top: 0.2rem !important;" inline v-model="selectedPibal.is_pibal_00">
                    00
                  </b-form-checkbox>
                  <b-form-checkbox style="margin-top: 0.2rem !important;" inline v-model="selectedPibal.is_pibal_06">
                    06
                  </b-form-checkbox>
                  <b-form-checkbox style="margin-top: 0.2rem !important;" inline v-model="selectedPibal.is_pibal_12">
                    12
                  </b-form-checkbox>
                  <b-form-checkbox style="margin-top: 0.2rem !important;" inline v-model="selectedPibal.is_pibal_18">
                    18
                  </b-form-checkbox>
                </b-form-group>
              </div>

              <div class="">
                <b-form-checkbox v-model="currentSelectedMeteo.rason">
                  Rason
                </b-form-checkbox>
                <b-form-group v-if="currentSelectedMeteo.rason" class="border ml-2 p-1 mb-1">
                  <b-form-checkbox style="margin-top: 0.2rem !important;" inline v-model="selectedRason.is_rason_00">
                    00
                  </b-form-checkbox>
                  <b-form-checkbox style="margin-top: 0.2rem !important;" inline v-model="selectedRason.is_rason_06">
                    06
                  </b-form-checkbox>
                  <b-form-checkbox style="margin-top: 0.2rem !important;" inline v-model="selectedRason.is_rason_12">
                    12
                  </b-form-checkbox>
                  <b-form-checkbox style="margin-top: 0.2rem !important;" inline v-model="selectedRason.is_rason_18">
                    18
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="3">
              <!-- FDIH -->
              <label>KLIMATOLOGI / FDIH</label>
              <b-form-checkbox-group v-model="selectedFdih" :options="optionsFdih" stacked></b-form-checkbox-group>
            </b-col>
            <b-col cols="3">
              <!-- pias -->
              <label>KLIMATOLOGI / FORM</label>
              <b-form-checkbox-group v-model="selectedPias" :options="optionsPias" stacked></b-form-checkbox-group>
            </b-col>

            <b-col :cols="(currentSelectedMeteo.metar || currentSelectedMeteo.pibal || currentSelectedMeteo.sinoptik || currentSelectedMeteo.rason) ? 2 : 3">
              <!-- pias -->
              <label>KLIMATOLOGI / KU</label>
              <b-form-checkbox-group v-model="selectedKU" :options="optionsKU" stacked></b-form-checkbox-group>
            </b-col>

          </b-row>
          <b-row class="m-2 font-weight-bolder">
            <span class="text-danger">Keterangan : </span> <br />
            (*) Form hanya menerapkan gross check
          </b-row>
        </b-card>
        <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'" v-on:click="goback">
          Back
        </b-button>
        <b-button variant="danger" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'" v-on:click="onCancel">
          Cancel
        </b-button>
        <b-button variant="gradient-success" class="mb-1 mb-sm-0 mr-0 mr-sm-1" type="reset" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'" v-on:click="onUpdate" :disabled="!isRoleEdit">
          Update
        </b-button>

        <!-- Action Buttons -->
      </b-col>
    </b-row>
    <b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
  </div>
</template>

<script>
//import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BCard,
  BCardHeader,
  BContainer,
  BRow,
  BCol,
  BFormSelect,
  BForm,
  BButton,
  BFormGroup,
  BCollapse,
  BFormDatepicker,
  BFormInput,
  BTable,
  BFormFile,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormRadioGroup,
  BOverlay
} from "bootstrap-vue";
// import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import axios from "axios";
import helper from "@/api/helper";
import metadata from "@/api/metadata";
import error_handler from "@/api/error_handler";

export default {
  components: {
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BFormGroup,
    BCollapse,
    BFormDatepicker,
    BFormInput,
    BFormFile,
    BTable,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormRadioGroup,
    BOverlay
    //aaws,
    // Aktinograph,
  },
  // mixins: [togglePasswordVisibility],
  // computed: {
  // 	passwordToggleIcon() {
  // 		return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
  // 	},
  // },
  props: ["profile", "roles"],
  data: function() {
    return {
      showLoading: false,
      path: "",
      entry_first_enable: false,
      entry_first_date: null,
      //Meteo
      selectedMeteo: [],
      optionsMeteo: [
        // { text: "ME 45", value: "ME45" },
        { text: "Sinoptik", value: "me48" },
        { text: "Metar ", value: "metar" },
        { text: "Pilot Balon * ", value: "pilot_balon" },
      ],

      is_mid_hour: false,
      selectedMKG: {
        station_operating_hours: "",
        station_hour_start: "",
			  station_hour_end: "",
        station_mid_hour_start: null,
			  station_mid_hour_end: null,
      },

      currentSelectedMeteo: {
        sinoptik: false,
        metar: false,
        pibal: false,
        rason: false 
      },
      
      radioMetarSelected:"",
      radioMetarOptions: [
        { text: 'Hourly', value: 'is_metar_hourly' },
        { text: 'Half Hourly', value: 'is_metar_half_hourly' },
      ],
      selectedPibal: {
        is_pibal_00: false,
        is_pibal_06: false,
        is_pibal_12: false,
        is_pibal_18: false
      },

      selectedRason: {
        is_rason_00: false,
        is_rason_06: false,
        is_rason_12: false,
        is_rason_18: false
      },

      //FDIH
      selectedFdih: [],
      optionsFdih: [
        { text: "Iklim Mikro", value: "iklim_mikro" },
        { text: "FKLIM71", value: "fklim" },
        { text: "AGM1A", value: "agm1a" },
        { text: "AGM1B", value: "agm1b" },
        { text: "Psychrometer Assman", value: "psychrometer_assman" },
        { text: "Suhu Tanah", value: "suhu_tanah" },
        { text: "OP Penguapan", value: "op_penguapan" },
        { text: "Piche Penguapan", value: "piche_penguapan" },
        { text: "Gunbellani", value: "gunbellani" },
        { text: "Lysimeter : Gundul *", value: "lysimeter_gundul" },
        { text: "Lysimeter : Komoditi *", value: "lysimeter_komoditi" },
        { text: "Lysimeter : Berumput *", value: "lysimeter_berumput" },
      ],
      //form
      selectedPias: [],
      optionsPias: [
        { text: "Lama Penyinaran *", value: "lama_penyinaran" },
        { text: "Aktinograph *", value: "aktinograph" },
        { text: "Thermohigrograph *", value: "thermohigograph" },
        { text: "Barograph *", value: "barograph" },
        { text: "Perawanan *", value: "perawanan" },
        { text: "Form Hujan", value: "form_hujan" },
        { text: "Hujan Helman", value: "hujan_helman" },
        { text: "Kelembapan Tanah *", value: "kelembapan_tanah" },
        { text: "Fenologi *", value: "fenologi" },
      ],

      //form
      selectedKU: [],
      optionsKU: [
        { text: "Kimia Air Hujan *", value: "kah" },
        { text: "Suspended Particulate Matter *", value: "spm" },
        { text: "SO2 & NO2 *", value: "so2no2" },
        { text: "Aerosol *", value: "aerosol" }
      ],

      isRoleAdd: false,
      isRoleEdit: false,
      isRoleView: false,
      isRoleDelete: false,
      
      options: {
				time_type: [
					{ value: 0, text: "00:00" },
					{ value: 1, text: "01:00" },
					{ value: 2, text: "02:00" },
					{ value: 3, text: "03:00" },
					{ value: 4, text: "04:00" },
					{ value: 5, text: "05:00" },
					{ value: 6, text: "06:00" },
					{ value: 7, text: "07:00" },
					{ value: 8, text: "08:00" },
					{ value: 9, text: "09:00" },
					{ value: 10, text: "10:00" },
					{ value: 11, text: "11:00" },
					{ value: 12, text: "12:00" },
					{ value: 13, text: "13:00" },
					{ value: 14, text: "14:00" },
					{ value: 15, text: "15:00" },
					{ value: 16, text: "16:00" },
					{ value: 17, text: "17:00" },
					{ value: 18, text: "18:00" },
					{ value: 19, text: "19:00" },
					{ value: 20, text: "20:00" },
					{ value: 21, text: "21:00" },
					{ value: 22, text: "22:00" },	
					{ value: 23, text: "23:00" },
				]
			},
    };
  },

  watch: {
    profile: function(val) {
      console.log('BeGeneral-profile: ', val)
      if (val) {
        this.entry_first_date = this.profile.entry_first_date
        this.entry_first_enable = this.profile.entry_first_enable

        let be = this.profile["BmkgSatu.metadata.bmkgentry.IBMKGEntry"];

        if (be.is_me48) {
          this.currentSelectedMeteo.sinoptik = true
        }
        if (be.is_metar_speci) {
          this.currentSelectedMeteo.metar = true
          if (be.is_metar_hourly) {
            this.radioMetarSelected = "is_metar_hourly"
          }

          if (be.is_metar_half_hourly) {
            this.radioMetarSelected = "is_metar_half_hourly"
          }
        }
        if (be.is_pibal) {
          this.currentSelectedMeteo.pibal = true
          if (be.is_pibal_00) {
            this.selectedPibal.is_pibal_00 = true
          }

          if (be.is_pibal_06) {
            this.selectedPibal.is_pibal_06 = true
          }

          if (be.is_pibal_12) {
            this.selectedPibal.is_pibal_12 = true
          }

          if (be.is_pibal_18) {
            this.selectedPibal.is_pibal_18 = true
          }
        }

        if (be.is_rason_00) {
          this.selectedRason.is_rason_00 = true
          this.currentSelectedMeteo.rason = true
        }
        if (be.is_rason_06) {
          this.selectedRason.is_rason_06 = true
          this.currentSelectedMeteo.rason = true
        }
        if (be.is_rason_12) {
          this.selectedRason.is_rason_12 = true
          this.currentSelectedMeteo.rason = true
        }
        if (be.is_rason_18) {
          this.selectedRason.is_rason_18 = true
          this.currentSelectedMeteo.rason = true
        }

        

        if (be.is_fklim) {
          if (!this.selectedFdih.includes("fklim")) {
            this.selectedFdih.push("fklim");
          }
        }
        if (be.is_iklim_mikro) {
          if (!this.selectedFdih.includes("iklim_mikro")) {
            this.selectedFdih.push("iklim_mikro");
          }
        }
        if (be.is_agm1a) {
          if (!this.selectedFdih.includes("agm1a")) {
            this.selectedFdih.push("agm1a");
          }
        }
        if (be.is_agm1b) {
          if (!this.selectedFdih.includes("agm1b")) {
            this.selectedFdih.push("agm1b");
          }
        }
        if (be.is_suhu_tanah) {
          if (!this.selectedFdih.includes("suhu_tanah")) {
            this.selectedFdih.push("suhu_tanah");
          }
        }
        if (be.is_psychrometer_assman) {
          if (!this.selectedFdih.includes("psychrometer_assman")) {
            this.selectedFdih.push("psychrometer_assman");
          }
        }
        if (be.is_op_penguapan) {
          if (!this.selectedFdih.includes("op_penguapan")) {
            this.selectedFdih.push("op_penguapan");
          }
        }
        if (be.is_piche_penguapan) {
          if (!this.selectedFdih.includes("piche_penguapan")) {
            this.selectedFdih.push("piche_penguapan");
          }
        }
        // if (be.is_lysimeter) {
        //   if (!this.selectedFdih.includes("lysimeter")) {
        //     this.selectedFdih.push("lysimeter");
        //   }
        // }
        if (be.is_lysimeter_gundul) {
          if (!this.selectedFdih.includes("lysimeter_gundul")) {
            this.selectedFdih.push("lysimeter_gundul");
          }
        }
        if (be.is_lysimeter_komoditi) {
          if (!this.selectedFdih.includes("lysimeter_komoditi")) {
            this.selectedFdih.push("lysimeter_komoditi");
          }
        }
        if (be.is_lysimeter_berumput) {
          if (!this.selectedFdih.includes("lysimeter_berumput")) {
            this.selectedFdih.push("lysimeter_berumput");
          }
        }
        if (be.is_gunbellani) {
          if (!this.selectedFdih.includes("gunbellani")) {
            this.selectedFdih.push("gunbellani");
          }
        }
        if (be.is_lama_penyinaran) {
          if (!this.selectedPias.includes("lama_penyinaran")) {
            this.selectedPias.push("lama_penyinaran");
          }
        }
        if (be.is_aktinograph) {
          if (!this.selectedPias.includes("aktinograph")) {
            this.selectedPias.push("aktinograph");
          }
        }
        if (be.is_thermohigograph) {
          if (!this.selectedPias.includes("thermohigograph")) {
            this.selectedPias.push("thermohigograph");
          }
        }
        if (be.is_barograph) {
          if (!this.selectedPias.includes("barograph")) {
            this.selectedPias.push("barograph");
          }
        }
        if (be.is_perawanan) {
          if (!this.selectedPias.includes("perawanan")) {
            this.selectedPias.push("perawanan");
          }
        }
        if (be.is_form_hujan) {
          if (!this.selectedPias.includes("form_hujan")) {
            this.selectedPias.push("form_hujan");
          }
        }
        if (be.is_hujan_helman) {
          if (!this.selectedPias.includes("hujan_helman")) {
            this.selectedPias.push("hujan_helman");
          }
        }
        if (be.is_kelembapan_tanah) {
          if (!this.selectedPias.includes("kelembapan_tanah")) {
            this.selectedPias.push("kelembapan_tanah");
          }
        }
        if (be.is_fenologi) {
          if (!this.selectedPias.includes("fenologi")) {
            this.selectedPias.push("fenologi");
          }
        }

        if (be.is_kimia_air_hujan) {
          if (!this.selectedKU.includes("kah")) {
            this.selectedKU.push("kah");
          }
        }
        if (be.is_spm) {
          if (!this.selectedKU.includes("spm")) {
            this.selectedKU.push("spm");
          }
        }
        if (be.is_so2_no2) {
          if (!this.selectedKU.includes("so2no2")) {
            this.selectedKU.push("so2no2");
          }
        }
        if (be.is_aerosol) {
          if (!this.selectedKU.includes("aerosol")) {
            this.selectedKU.push("aerosol");
          }
        }
        

        let mkg = this.profile["BmkgSatu.metadata.mkg.IMKG"];
        this.selectedMKG.station_operating_hours = mkg.station_operating_hours
        this.selectedMKG.station_hour_start = mkg.station_hour_start
        this.selectedMKG.station_hour_end = mkg.station_hour_end
        this.selectedMKG.station_mid_hour_start = mkg.station_mid_hour_start
        this.selectedMKG.station_mid_hour_end = mkg.station_mid_hour_end
      }
    },

    "selectedMKG.station_operating_hours": function(val) {
      // console.log('station_operating_hours', val)
			this.is_mid_hour = false
			if (val && val >= 0) {
				if (!this.selectedMKG.station_hour_start) {
					this.selectedMKG.station_hour_start = 0
					this.selectedMKG.station_hour_end = (val == 24) ? 23 : val 
					this.is_mid_hour = false
					this.selectedMKG.station_mid_hour_end = null
					this.selectedMKG.station_mid_hour_start = null
				}
				else {
					this.selectedMKG.station_hour_end = (parseInt(this.selectedMKG.station_hour_start) + parseInt(val))
					if (this.selectedMKG.station_hour_end == 24) {
						this.is_mid_hour = false
						this.selectedMKG.station_hour_end = 23
						this.selectedMKG.station_mid_hour_end = null
						this.selectedMKG.station_mid_hour_start = null
					}
					else if (this.selectedMKG.station_hour_end > 23) {
						this.is_mid_hour = true
						this.selectedMKG.station_mid_hour_end = 23
						this.selectedMKG.station_mid_hour_start = 0
						this.selectedMKG.station_hour_end = parseInt(this.selectedMKG.station_hour_end) - 24
					}
					else {
						this.is_mid_hour = false
						this.selectedMKG.station_mid_hour_end = null
						this.selectedMKG.station_mid_hour_start = null
					}
				}
			}
    },

    "selectedMKG.station_hour_start": function(val) {
      // console.log('station_hour_start', val)
      this.is_mid_hour = false
			if (val || val === 0) {
				if (val === 0) {
					if (this.selectedMKG.station_operating_hours >= 23) {
						this.selectedMKG.station_hour_end = 23
					}
					else {
						this.selectedMKG.station_hour_end = this.selectedMKG.station_operating_hours
					}
					this.is_mid_hour = false
					this.selectedMKG.station_mid_hour_end = null
					this.selectedMKG.station_mid_hour_start = null
				}
				else {
					this.selectedMKG.station_hour_end = (parseInt(val) + parseInt(this.selectedMKG.station_operating_hours))
					if (this.selectedMKG.station_hour_end == 24) {
						this.is_mid_hour = false
						this.selectedMKG.station_hour_end = 23
						this.selectedMKG.station_mid_hour_end = null
						this.selectedMKG.station_mid_hour_start = null
					}
					else if (this.selectedMKG.station_hour_end > 23) {
						this.is_mid_hour = true
						this.selectedMKG.station_mid_hour_end = 23
						this.selectedMKG.station_mid_hour_start = 0
						this.selectedMKG.station_hour_end = parseInt(this.selectedMKG.station_hour_end) - 24
					}
					else {
						this.is_mid_hour = false
						this.selectedMKG.station_mid_hour_end = null
						this.selectedMKG.station_mid_hour_start = null
					}
				}
			}
    },

    "selectedMKG.station_hour_end": function(value) {
      // console.log('station_hour_end', value)
    },

    "selectedMKG.station_mid_hour_start": function(value) {
      // console.log('station_mid_hour_start', value)
    },

    "selectedMKG.station_mid_hour_end": function(value) {
      // console.log('station_mid_hour_end', value)
    },

    "currentSelectedMeteo.sinoptik": function(value) {
      if(!value) {
        this.entry_first_enable = false
        this.entry_first_date = null
      }
    },

    "currentSelectedMeteo.metar": function(value) {
      if (!value) {
        this.radioMetarSelected = ""
      }
    },

    "currentSelectedMeteo.pibal": function(value) {
      if (!value) {
        this.selectedPibal.is_pibal_00 = false
        this.selectedPibal.is_pibal_06 = false
        this.selectedPibal.is_pibal_12 = false
        this.selectedPibal.is_pibal_18 = false
      }
    },

    entry_first_enable: function(value) {
      // console.log('entry_first_enable',value)
      if (!value) {
        this.entry_first_date = null
      }
    },

    roles(val) {
      // console.log('s.roles', val)
      // console.log('this.roles', this.roles)
      if (val) {
        this.isRoleAdd = val.isRoleAdd;
        this.isRoleEdit = val.isRoleEdit;
        this.isRoleView = val.isRoleView;
        this.isRoleDelete = val.isRoleDelete;
      }
    },
  
  },

  mounted() {
    this.path = this.$route.query.path;
    
    if (this.profile) {
      this.entry_first_date = this.profile.entry_first_date
      this.entry_first_enable = this.profile.entry_first_enable

      let be = this.profile["BmkgSatu.metadata.bmkgentry.IBMKGEntry"];

      if (be.is_me48) {
        this.currentSelectedMeteo.sinoptik = true
      }
      if (be.is_metar_speci) {
        this.currentSelectedMeteo.metar = true
        if (be.is_metar_hourly) {
          this.radioMetarSelected = "is_metar_hourly"
        }

        if (be.is_metar_half_hourly) {
          this.radioMetarSelected = "is_metar_half_hourly"
        }
      }
      if (be.is_pibal) {
        this.currentSelectedMeteo.pibal = true
        if (be.is_pibal_00) {
          this.selectedPibal.is_pibal_00 = true
        }

        if (be.is_pibal_06) {
          this.selectedPibal.is_pibal_06 = true
        }

        if (be.is_pibal_12) {
          this.selectedPibal.is_pibal_12 = true
        }

        if (be.is_pibal_18) {
          this.selectedPibal.is_pibal_18 = true
        }
      }

      if (be.is_rason_00) {
        this.selectedRason.is_rason_00 = true
        this.currentSelectedMeteo.rason = true
      }
      if (be.is_rason_06) {
        this.selectedRason.is_rason_06 = true
        this.currentSelectedMeteo.rason = true
      }
      if (be.is_rason_12) {
        this.selectedRason.is_rason_12 = true
        this.currentSelectedMeteo.rason = true
      }
      if (be.is_rason_18) {
        this.selectedRason.is_rason_18 = true
        this.currentSelectedMeteo.rason = true
      }

      

      if (be.is_fklim) {
        if (!this.selectedFdih.includes("fklim")) {
          this.selectedFdih.push("fklim");
        }
      }
      if (be.is_iklim_mikro) {
        if (!this.selectedFdih.includes("iklim_mikro")) {
          this.selectedFdih.push("iklim_mikro");
        }
      }
      if (be.is_agm1a) {
        if (!this.selectedFdih.includes("agm1a")) {
          this.selectedFdih.push("agm1a");
        }
      }
      if (be.is_agm1b) {
        if (!this.selectedFdih.includes("agm1b")) {
          this.selectedFdih.push("agm1b");
        }
      }
      if (be.is_suhu_tanah) {
        if (!this.selectedFdih.includes("suhu_tanah")) {
          this.selectedFdih.push("suhu_tanah");
        }
      }
      if (be.is_psychrometer_assman) {
        if (!this.selectedFdih.includes("psychrometer_assman")) {
          this.selectedFdih.push("psychrometer_assman");
        }
      }
      if (be.is_op_penguapan) {
        if (!this.selectedFdih.includes("op_penguapan")) {
          this.selectedFdih.push("op_penguapan");
        }
      }
      if (be.is_piche_penguapan) {
        if (!this.selectedFdih.includes("piche_penguapan")) {
          this.selectedFdih.push("piche_penguapan");
        }
      }
      // if (be.is_lysimeter) {
      //   if (!this.selectedFdih.includes("lysimeter")) {
      //     this.selectedFdih.push("lysimeter");
      //   }
      // }
      if (be.is_lysimeter_gundul) {
        if (!this.selectedFdih.includes("lysimeter_gundul")) {
          this.selectedFdih.push("lysimeter_gundul");
        }
      }
      if (be.is_lysimeter_komoditi) {
        if (!this.selectedFdih.includes("lysimeter_komoditi")) {
          this.selectedFdih.push("lysimeter_komoditi");
        }
      }
      if (be.is_lysimeter_berumput) {
        if (!this.selectedFdih.includes("lysimeter_berumput")) {
          this.selectedFdih.push("lysimeter_berumput");
        }
      }
      if (be.is_gunbellani) {
        if (!this.selectedFdih.includes("gunbellani")) {
          this.selectedFdih.push("gunbellani");
        }
      }
      if (be.is_lama_penyinaran) {
        if (!this.selectedPias.includes("lama_penyinaran")) {
          this.selectedPias.push("lama_penyinaran");
        }
      }
      if (be.is_aktinograph) {
        if (!this.selectedPias.includes("aktinograph")) {
          this.selectedPias.push("aktinograph");
        }
      }
      if (be.is_thermohigograph) {
        if (!this.selectedPias.includes("thermohigograph")) {
          this.selectedPias.push("thermohigograph");
        }
      }
      if (be.is_barograph) {
        if (!this.selectedPias.includes("barograph")) {
          this.selectedPias.push("barograph");
        }
      }
      if (be.is_perawanan) {
        if (!this.selectedPias.includes("perawanan")) {
          this.selectedPias.push("perawanan");
        }
      }
      if (be.is_form_hujan) {
        if (!this.selectedPias.includes("form_hujan")) {
          this.selectedPias.push("form_hujan");
        }
      }
      if (be.is_hujan_helman) {
        if (!this.selectedPias.includes("hujan_helman")) {
          this.selectedPias.push("hujan_helman");
        }
      }
      if (be.is_kelembapan_tanah) {
        if (!this.selectedPias.includes("kelembapan_tanah")) {
          this.selectedPias.push("kelembapan_tanah");
        }
      }
      if (be.is_fenologi) {
        if (!this.selectedPias.includes("fenologi")) {
          this.selectedPias.push("fenologi");
        }
      }

      if (be.is_kimia_air_hujan) {
        if (!this.selectedKU.includes("kah")) {
          this.selectedKU.push("kah");
        }
      }
      if (be.is_spm) {
        if (!this.selectedKU.includes("spm")) {
          this.selectedKU.push("spm");
        }
      }
      if (be.is_so2_no2) {
        if (!this.selectedKU.includes("so2no2")) {
          this.selectedKU.push("so2no2");
        }
      }
      if (be.is_aerosol) {
        if (!this.selectedKU.includes("aerosol")) {
          this.selectedKU.push("aerosol");
        }
      }
      

      let mkg = this.profile["BmkgSatu.metadata.mkg.IMKG"];
      this.selectedMKG.station_operating_hours = mkg.station_operating_hours
      this.selectedMKG.station_hour_start = mkg.station_hour_start
      this.selectedMKG.station_hour_end = mkg.station_hour_end
      this.selectedMKG.station_mid_hour_start = mkg.station_mid_hour_start
      this.selectedMKG.station_mid_hour_end = mkg.station_mid_hour_end
    }

    if (this.roles) {
      this.isRoleAdd = this.roles.isRoleAdd;
      this.isRoleEdit = this.roles.isRoleEdit;
      this.isRoleView = this.roles.isRoleView;
      this.isRoleDelete = this.roles.isRoleDelete;
    }

    this.settingForm();
  },

  methods: {

    goback() {
      this.$router.push({ name: "metadata" });
    },

    onCancel() {
      this.$emit("goBack", "back");
    },

    checkRequirement() {
      if (this.entry_first_enable ) {
        if (this.entry_first_date && this.entry_first_date != "") {
          return true
        }
        else {
          this.$swal({
            title: "Tanggal Belum diisi!",
            text: "Silahkan lengkapi data tanggal",
            icon: "danger",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          return false
        }
      }
      else {
        return true
      }
    },

    onUpdate() {

      if (!this.checkRequirement()) {
        console.log('Update data', 'data belum lengkap')
      }
      else {
        this.showLoading = true;

        let isme48 = false,
          ismetarspeci = false,
          ispibal = false,
          isfklim = false,
          isiklimmikro = false,
          isagm1a = false,
          isagm1b = false,
          issuhutanah = false,
          ispsychrometerassman = false,
          isoppenguapan = false,
          ispichepenguapan = false,
          islysimeter = false,
          islysimetergundul = false,
          islysimeterkomoditi = false,
          islysimeterberumput = false,
          isgunbellani = false,
          islamapenyinaran = false,
          isaktinograph = false,
          isthermohigograph = false,
          isbarograph = false,
          isperawanan = false,
          isformhujan = false,
          ishujanhelman = false,
          iskelembapantanah = false,
          isfenologi = false,
          ismetarhourly = false,
          ismetarhalfhourly = false,
          ispibal00 = false,
          ispibal06 = false,
          ispibal12 = false,
          ispibal18 = false,
          israson00 = false,
          israson06 = false,
          israson12 = false,
          israson18 = false,
          iskah = false,
          isspm = false,
          isso2no2 = false, 
          isaerosol = false

        // for (var idm = 0; idm < this.selectedMeteo.length; idm++) {
        //   if (this.selectedMeteo[idm] == "me48") isme48 = true;
        //   else if (this.selectedMeteo[idm] == "metar") ismetarspeci = true;
        //   else if (this.selectedMeteo[idm] == "pilot_balon") ispibal = true;
        // }
        
        isme48 = this.currentSelectedMeteo.sinoptik;
        ismetarspeci = this.currentSelectedMeteo.metar;
        ispibal = this.currentSelectedMeteo.pibal;

        ismetarhourly = this.radioMetarSelected == "is_metar_hourly" ? true : false
        ismetarhalfhourly = this.radioMetarSelected == "is_metar_half_hourly" ? true : false
        ispibal00 = this.selectedPibal.is_pibal_00
        ispibal06 = this.selectedPibal.is_pibal_06
        ispibal12 = this.selectedPibal.is_pibal_12
        ispibal18 = this.selectedPibal.is_pibal_18
        israson00 = this.selectedRason.is_rason_00
        israson06 = this.selectedRason.is_rason_06
        israson12 = this.selectedRason.is_rason_12
        israson18 = this.selectedRason.is_rason_18

        for (var idf = 0; idf < this.selectedFdih.length; idf++) {
          if (this.selectedFdih[idf] == "fklim") isfklim = true;
          else if (this.selectedFdih[idf] == "iklim_mikro") isiklimmikro = true;
          else if (this.selectedFdih[idf] == "agm1a") isagm1a = true;
          else if (this.selectedFdih[idf] == "agm1b") isagm1b = true;
          else if (this.selectedFdih[idf] == "suhu_tanah") issuhutanah = true;
          else if (this.selectedFdih[idf] == "psychrometer_assman") ispsychrometerassman = true;
          else if (this.selectedFdih[idf] == "op_penguapan") isoppenguapan = true;
          else if (this.selectedFdih[idf] == "piche_penguapan") ispichepenguapan = true;
          // else if (this.selectedFdih[idf] == "lysimeter") islysimeter = true;
          else if (this.selectedFdih[idf] == "lysimeter_gundul") {
            islysimeter = true;
            islysimetergundul = true;
          } else if (this.selectedFdih[idf] == "lysimeter_komoditi") {
            islysimeter = true;
            islysimeterkomoditi = true;
          } else if (this.selectedFdih[idf] == "lysimeter_berumput") {
            islysimeter = true;
            islysimeterberumput = true;
          } else if (this.selectedFdih[idf] == "gunbellani") isgunbellani = true;
        }

        for (var idp = 0; idp < this.selectedPias.length; idp++) {
          if (this.selectedPias[idp] == "lama_penyinaran") islamapenyinaran = true;
          else if (this.selectedPias[idp] == "aktinograph") isaktinograph = true;
          else if (this.selectedPias[idp] == "thermohigograph") isthermohigograph = true;
          else if (this.selectedPias[idp] == "barograph") isbarograph = true;
          else if (this.selectedPias[idp] == "perawanan") isperawanan = true;
          else if (this.selectedPias[idp] == "form_hujan") isformhujan = true;
          else if (this.selectedPias[idp] == "hujan_helman") ishujanhelman = true;
          else if (this.selectedPias[idp] == "kelembapan_tanah") iskelembapantanah = true;
          else if (this.selectedPias[idp] == "fenologi") isfenologi = true;
        }

        for (var idk = 0; idk < this.selectedKU.length; idk++) {
          if (this.selectedKU[idk] == "kah") iskah = true;
          else if (this.selectedKU[idk] == "spm") isspm = true;
          else if (this.selectedKU[idk] == "so2no2") isso2no2 = true;
          else if (this.selectedKU[idk] == "aerosol") isaerosol = true;
        }

        const update_data = {
          is_me48: isme48,
          is_metar_speci: ismetarspeci,
          is_pibal: ispibal,
          is_fklim: isfklim,
          is_iklim_mikro: isiklimmikro,
          is_agm1a: isagm1a,
          is_agm1b: isagm1b,
          is_suhu_tanah: issuhutanah,
          is_psychrometer_assman: ispsychrometerassman,
          is_op_penguapan: isoppenguapan,
          is_piche_penguapan: ispichepenguapan,
          is_lysimeter: islysimeter,
          is_lysimeter_gundul: islysimetergundul,
          is_lysimeter_komoditi: islysimeterkomoditi,
          is_lysimeter_berumput: islysimeterberumput,
          is_gunbellani: isgunbellani,
          is_lama_penyinaran: islamapenyinaran,
          is_aktinograph: isaktinograph,
          is_thermohigograph: isthermohigograph,
          is_barograph: isbarograph,
          is_perawanan: isperawanan,
          is_form_hujan: isformhujan,
          is_hujan_helman: ishujanhelman,
          is_kelembapan_tanah: iskelembapantanah,
          is_fenologi: isfenologi,
          is_metar_hourly: ismetarhourly,
          is_metar_half_hourly: ismetarhalfhourly,
          is_pibal_00: ispibal00,
          is_pibal_06: ispibal06,
          is_pibal_12: ispibal12,
          is_pibal_18: ispibal18,
          is_rason_00: israson00,
          is_rason_06: israson06,
          is_rason_12: israson12,
          is_rason_18: israson18,
          is_kimia_air_hujan: iskah,
          is_spm: isspm,
          is_so2_no2: isso2no2,
          is_aerosol: isaerosol
        };

        const mkg_data = {
          station_operating_hours: this.selectedMKG.station_operating_hours,
          station_hour_start: this.selectedMKG.station_hour_start,
          station_hour_end: this.selectedMKG.station_hour_end,
          station_mid_hour_start: this.selectedMKG.station_mid_hour_start,
          station_mid_hour_end:this.selectedMKG.station_mid_hour_end
        }

        let post_data = {
          "@type": "BmkgStation",
          "BmkgSatu.metadata.bmkgentry.IBMKGEntry": update_data,
          "BmkgSatu.metadata.mkg.IMKG": mkg_data,
          "entry_first_enable": this.entry_first_enable,
          "entry_first_date": this.entry_first_date,
          "sync_metastation": 1
        };

        const update_url = helper.getBaseUrl() + this.path;

        axios
          .patch(update_url, post_data, helper.getConfig())
          .then((response) => {
            if (response.status == 201 || response.status == 200 || response.status == 204) {
              this.$swal({
                title: "Update Data berhasil!",
                text: "Update Data BMKGEntry",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.showLoading = false;
              this.profile["BmkgSatu.metadata.bmkgentry.IBMKGEntry"] = update_data;
              this.settingForm();
            }
          })
          .catch((error) => {
            this.showLoading = false;
            error_handler(this, error, "Metadata Stasiun", "Update Data Error");
          });
      }
    },
    settingForm() {
      const array = [...this.selectedMeteo, ...this.selectedFdih, ...this.selectedPias];
      this.$emit("settingForm", array);
    },

  },
};
</script>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
.mt {
  margin-top: -9px;
}
</style>
