<template>
	<div>
		<div class="row">
			<b-col sm="6">
				<b-card no-body class="border mt-1">
					<b-row class="pl-1 pr-1 mt-1">
						<b-col>
							<b-form-group
								label-cols="4"
								label-size="sm"
								label="Valid From :"
								label-for="start-date"
								size="sm"
							>
								<b-form-datepicker
									id="start-date"
									v-model="gempa_start_date"
									size="sm"
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-size="sm"
								label="Valid To :"
								label-for="end-date"
								size="sm"
							>
								<b-form-datepicker
									id="end-date"
									v-model="gempa_end_date"
									size="sm"
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-size="sm"
								label="Kode :"
								label-for="gempa-code"
								size="sm"
							>
								<b-form-input
									id="gempa-code"
									size="sm"
									v-model="gempa_kode"
									disabled
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-size="sm"
								label="Wigos ID :"
								label-for="wigos-id"
								size="sm"
							>
								<b-form-input
									id="wigos-id"
									size="sm"
									v-model="wigos_id"
									disabled
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-size="sm"
								label="Station Name :"
								label-for="station-name"
								size="sm"
							>
								<b-form-input
									id="station-name"
									size="sm"
									v-model="gempa_station_name"
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="4"
								label-size="sm"
								label="Latitude :"
								label-for="gempa-latitude"
							>
								<b-form-input
								id="gempa-latitude"
								size="sm"
								v-model="gempa_latitude"
								type="number"
								/>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="4"
								label-size="sm"
								label="Longitude :"
								label-for="gempa-longitude"
							>
								<b-form-input
								id="gempa-longitude"
								size="sm"
								v-model="gempa_longitude"
								type="number"
								/>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Elevation (m) :"
							label-for="gempa-elevation"
						>
							<b-form-input
							id="gempa-elevation"
							size="sm"
							v-model="gempa_elevation"
							type="number"
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-size="sm"
								label="Status :"
								label-for="gempa-status"
								size="sm"
							>
								<b-form-input
									id="gempa-status"
									size="sm"
									v-model="gempa_status"
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="4"
								label-size="sm"
								label="Owner :"
								label-for="gempa-owner"
							>
								<b-form-input
									id="gempa-owner"
									size="sm"
									v-model="gempa_owner"
								/>
							</b-form-group>
						</b-col>
					</b-row>

				</b-card>

				<div>
					<b-button
						variant="primary"
						class="mb-1 mb-sm-0 mr-0 mr-sm-1"
						size="sm"
						v-on:click="goback"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					>
						Back
					</b-button>

					<b-button
						variant="success"
						class="mb-1 mb-sm-0 mr-0 mr-sm-1"
						size="sm"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
						@click="updateStation()"
					>
						Update
					</b-button>

					<!-- <b-button
						variant="danger"
						class="mb-1 mb-sm-0 ml-0 ml-sm-1 float-right"
						type="reset"
						size="sm"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					>
						Delete
					</b-button> -->
				</div>
			</b-col>
		</div>

		<b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
	</div>
</template>

<script>
import {
	BCard,
	BCardHeader,
	BContainer,
	BRow,
	BCol,
	BFormSelect,
	BForm,
	BButton,
	BFormGroup,
	BCollapse,
	BFormDatepicker,
	BFormInput,
	BOverlay,
	BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";

import axios from "axios";
import helper from "@/api/helper";
import metadata from "@/api/metadata";
import error_handler from "@/api/error_handler";

export default {
	components: {
		BCard,
		BCardHeader,
		BContainer,
		BRow,
		BCol,
		BFormSelect,
		BForm,
		BButton,
		BFormGroup,
		BCollapse,
		BFormDatepicker,
		BFormInput,
		BOverlay,
		vSelect,
		BFormCheckbox,
	},
	props: ["profile"],
	data() {
		return {
			isEditing: false,
			showLoading: false,
			path: "",
			wigos_id: "",
			gempa_start_date: "",
			gempa_end_date: "",
			gempa_kode: "",
			gempa_station_name:"",
			gempa_latitude	: "",
			gempa_longitude: "",
			gempa_elevation: "",
			gempa_status: "",
			gempa_owner: "",
		};
	},
	mounted() {
		this.path = this.$route.query.path;
		this.wigos_id = this.profile.wigos_id
		let gempa = this.profile["BmkgSatu.metadata.gempa.IGempa"]
		this.gempa_start_date = gempa.gempa_start_date,
		this.gempa_end_date = gempa.gempa_end_date,
		this.gempa_kode = gempa.gempa_kode,
		this.gempa_station_name = gempa.gempa_station_name,
		this.gempa_latitude = gempa.gempa_latitude,
		this.gempa_longitude = gempa.gempa_longitude,
		this.gempa_elevation = gempa.gempa_elevation,
		this.gempa_status = gempa.gempa_status,
		this.gempa_owner = gempa.gempa_owner

	},
	methods: {
		goback() {
			this.$router.push({ name: "metadata" });
		},
		updateStation() {
			this.showLoading = true;

			const update_data = {
				gempa_start_date: this.gempa_start_date,
				gempa_end_date: this.gempa_end_date,
				gempa_kode: this.gempa_kode,
				gempa_station_name: this.gempa_station_name,
				gempa_latitude: this.gempa_latitude == '' ? 0 : this.gempa_latitude,
				gempa_longitude: this.gempa_longitude,
				gempa_elevation: this.gempa_elevation,
				gempa_status: this.gempa_status,
				gempa_owner: this.gempa_owner
			};

			let post_data = {
				"@type": "BmkgStation",
				"BmkgSatu.metadata.gempa.IGempa": update_data,
			};

			const update_url = helper.getBaseUrl() + this.path;

			axios
				.patch(update_url, post_data, helper.getConfig())
				.then((response) => {
					if (
						response.status == 201 ||
						response.status == 200 ||
						response.status == 204
					) {
						this.$swal({
						title: "Update Data berhasil!",
						text: "Update Data Gempa",
						icon: "success",
						customClass: {
							confirmButton: "btn btn-primary",
						},
						buttonsStyling: false,
						});
						this.showLoading = false;
					}
				})
				.catch((error) => {
					this.showLoading = false;
					error_handler(this, error, "Metadata Stasiun", "Update Data Error");
				});
				this.isEditing = false;
		},
	},
};
</script>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
.mt {
  margin-top: -9px;
}
</style>
