<template>
	<div>
		<b-row>
			<b-col sm="8">
				<b-card class="border" style="min-height: 50vh;">
					<!-- <b-row>
						<b-col sm="6">
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Geography:"
							label-for="input-geography"
						>
							<b-form-select
							v-model="geography"
							id="input-geography"
							size="sm"
							>
							<option
								v-for="opt in options.geography"
								:key="opt.id"
								:value="opt.id"
							>
								{{ opt.label }}
							</option>
							</b-form-select>
						</b-form-group>
						</b-col>
					</b-row> -->
					<b-row class="h-100">
						<!-- Photos Grid -->
						<b-col sm="4" class="mb-1" v-for="photo in sortedPhotos" :key="photo.id" @click="selectData(photo)">
						<div :class="{ 'photo-item': true, 'selected': photo.id == selected.id }">
							<p class="text-center font-weight-bolder m-0">
							{{ photo.photo_date }}
							</p>
							<b-overlay :show="!photo.photo_data">
							<b-img
								:src="photo.photo_data"
								fluid-grow
							/>
							</b-overlay>
						</div>
						</b-col>
					</b-row>
				</b-card>
			</b-col>

			<b-col sm="4">
				<!-- Photos Input Form -->
				<b-card class="border" style="min-height: 50vh;">
					<b-row>
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="4"
								label-size="sm"
								label="Photo Date:"
								label-for="datepicker-photo"
							>
								<b-form-datepicker
									id="datepicker-photo"
									placeholder="Date"
									:date-format-options="{
										year: 'numeric',
										month: 'numeric',
										day: 'numeric',
									}"
									locale="en"
									size="sm"
									v-model="photo_date"
								/>
							</b-form-group>
						</b-col>
					</b-row>
						<b-row>
							<b-col>
								<b-form-group
									label="Photo File:"
									label-for="input-file"
									label-cols-sm="4"
									label-size="sm"
								>
									<b-form-file
									id="input-file"
									size="sm"
									@change="getPhotoData"
									v-model="photo_data"
									/>
								</b-form-group>
							</b-col>
						</b-row>
					<b-row>
						<!-- PREVIEW PHOTO -->
						<b-col cols="4"></b-col>
						<b-col>
							<b-img
								v-if="previewFile"
								:src="previewFile"
								fluid-grow
							/>
							<b-img
								v-else-if="selected"
								:src="selected.photo_data"
								fluid-grow
							/>
						</b-col>
					</b-row>
				</b-card>
				
				
				<b-button
					variant="gradient-secondary"
					class="mb-1 mb-sm-0 mr-0 mr-sm-1"
					size="sm"
					:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					@click="resetForm()"
					v-if="isEditing"
				>
					Cancel
				</b-button>
				<b-button
					variant="gradient-warning"
					class="mb-1 mb-sm-0 mr-0 mr-sm-1"
					type="reset"
					size="sm"
					:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					@click="editData()"
					v-if="isEditing"
				>
					Edit
				</b-button>
				<b-button
					variant="gradient-danger"
					class="mb-1 mb-sm-0 mr-0 float-right"
					size="sm"
					:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					@click="deleteConfirm()"
					v-if="isEditing"
					:disabled="!isRoleEdit"
				>
					Delete
				</b-button>
				<b-button
					variant="gradient-success"
					class="mb-1 mb-sm-0 mr-0 mr-sm-1"
					type="reset"
					size="sm"
					:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					@click="addData()"
					v-if="!isEditing"
					:disabled="!isRoleEdit"
				>
					Add Photo
				</b-button>

			</b-col>
		</b-row>
		<b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
	</div>
</template>

<script>
import axios from "axios";
import helper from "@/api/helper";

import {
	BCard,
	BCardHeader,
	BContainer,
	BRow,
	BCol,
	BFormSelect,
	BForm,
	BButton,
	BFormGroup,
	BCollapse,
	BFormDatepicker,
	BFormInput,
	BTable,
	BFormFile,
	BImg,
	BOverlay,
} from "bootstrap-vue";

export default {
	components: {
		BCard,
		BCardHeader,
		BContainer,
		BRow,
		BCol,
		BFormSelect,
		BForm,
		BButton,
		BFormGroup,
		BCollapse,
		BFormDatepicker,
		BFormInput,
		BFormFile,
		BTable,
		BImg,
		BOverlay,
	},
	props: ["profile", "detail", "roles"],
	data: function () {
		return {
			showLoading: false,
			isEditing: false,
			station_path: "",
			selected: {},
			photos: [],
			geography: "",
			photo_date: "",
			photo_data: null,
			dataFile: null,
			pathUsage: "",
			options: {
				geography: [],
			},
			isRoleAdd: false,
			isRoleEdit: false,
			isRoleView: false,
			isRoleDelete: false
		};
	},

	watch: {
		roles(val) {
			// console.log('s.roles', val)
			// console.log('this.roles', this.roles)
			if (val) {
				this.isRoleAdd = val.isRoleAdd
				this.isRoleEdit = val.isRoleEdit
				this.isRoleView = val.isRoleView
				this.isRoleDelete = val.isRoleDelete
			}
		}
	},

	computed: {
		sortedPhotos() {
			return this.photos.sort((a, b) => -(a.id.localeCompare(b.id)));
		},
		previewFile() {
			if (this.dataFile) {
				return URL.createObjectURL(this.dataFile);
			}else {
				return null;
			}
		},
	},
	mounted() {
		this.station_path = this.$route.query.path;
		this.pathUsage = this.detail.path
		// this.getAllGeography();
		if (this.roles) {
			// console.log('mounted - this.roles', this.roles)
			this.isRoleAdd = this.roles.isRoleAdd
			this.isRoleEdit = this.roles.isRoleEdit
			this.isRoleView = this.roles.isRoleView
			this.isRoleDelete = this.roles.isRoleDelete
		}
		this.getPhotos();
	},
	methods: {
		getAllGeography() {
			this.showLoading = true;

			const GET_URL =
				helper.getBaseUrl() + this.station_path + "/metageography/@items";
			const GET_CONFIG = {
				mode: "cors",
				withCredentials: false,
				params: {
				include:
					"id,geography_latitude,geography_longitude,geography_elevation,geography_valid_from,geography_valid_to",
				},
				headers: helper.getHeader(),
			};

			axios
				.get(GET_URL, GET_CONFIG)
				.then((response) => {
					const { data } = response;
					this.options.geography = data.items
						.map((item) => ({
							id: item["@name"],
							geography_latitude: item.geography_latitude,
							geography_longitude: item.geography_longitude,
							geography_elevation: item.geography_elevation,
							geography_valid_from: item.geography_valid_from.split("T")[0],
							geography_valid_to: item.geography_valid_to?.split("T")[0] || null,
						}))
						.map((item) => ({
						...item,
						label: `${item.geography_valid_from} / ${item.geography_latitude} / ${item.geography_longitude}`,
						}))
						.sort(
						(a, b) =>
							-a.geography_valid_from.localeCompare(b.geography_valid_from)
						);
					if (this.options.geography.length > 0) {
						this.geography = this.options.geography[0].id;
					}
					this.showLoading = false;
				})
				.catch((error) => {
					console.log(error);
					this.showLoading = false;
				});
		},
		// getPhotosByGeography(ID_GROGRAPHY) {
		// 	this.showLoading = true;

		// 	// const GET_URL = helper.getBaseUrl() + this.station_path + "/metaphoto/@items";
		// 	const GET_URL = this.pathUsage + "/metaphoto/@items";
		// 	axios
		// 		.get(GET_URL, helper.getConfig())
		// 		.then((response) => {
		// 		const { data } = response;
		// 		this.photos = data.items
		// 			.filter((item) => item.id_geography == ID_GROGRAPHY)
		// 			.map((item) => {
		// 			const photo = {
		// 				id: item["@name"],
		// 				id_geography: item.id_geography,
		// 				photo_data: null,
		// 				photo_date: item.photo_date.split("T")[0],
		// 			};
		// 			this.getPhotoAPI(photo, item["@id"] + "/@images/image/thumb");
		// 			return photo;
		// 			});
		// 		this.showLoading = false;
		// 		})
		// 		.catch((error) => {
		// 		console.log(error);
		// 		this.showLoading = false;
		// 		});
		// },
		getPhotos() {
			this.showLoading = true;

			// const GET_URL = helper.getBaseUrl() + this.station_path + "/metaphoto/@items";
			const GET_URL = this.pathUsage + "/photo/@items";
			// console.log('getPhotos', GET_URL)
			axios
				.get(GET_URL, helper.getConfig())
				.then((response) => {
					console.log('getPhotos - response', response)
					const { data } = response;
					this.photos = data.items
						.map((item) => {
							const photo = {
								id: item["@name"],
								id_geography: item.id_geography,
								photo_data: null,
								photo_date: item.usage_photo_date.split("T")[0],
							};
							this.getPhotoAPI(photo, item["@id"] + "/@images/image/thumb");
							return photo;
						});
					this.showLoading = false;
				})
				.catch((error) => {
					console.log(error);
					this.showLoading = false;
				});
		},
		async getPhotoAPI(photo, URL) {
			// setting image
			await axios.patch(URL, null, helper.getConfig());

			const PHOTO_CONFIG = {
				method: 'get',
				url: URL,
				headers: {
				'Authorization': helper.getHeader().Authorization,
				},
				responseType: 'arraybuffer',
			};
			console.log('PHOTO_CONFIG', PHOTO_CONFIG)

			const request_photo = await axios(PHOTO_CONFIG);
			const img_data_base64 = Buffer.from(request_photo.data, 'base64');
			photo.photo_data = 'data:image/jpeg;base64,' + btoa(new Uint8Array(img_data_base64).reduce((data, byte) => data + String.fromCharCode(byte), ''));
		},
		getPhotoData(e) {
			this.dataFile = null;
			this.dataFile = e.target.files[0];
		},
		selectData(photo) {
			this.resetForm();
			this.isEditing = true;
			this.selected = photo;
			this.photo_date = photo.photo_date;
		},
		goback() {
			this.$router.push({ name: "metadata" });
		},
		resetForm() {
			this.isEditing = false;
			this.selected = "";
			this.photo_date = "";
			this.photo_data = null;
			this.dataFile = null;
		},
		editData() {
			if (this.photo_date === "" || this.selected === null) {
				return false;
			}
			this.showLoading = true;

			const PATCH_DATA = {
				"@type": "StationUsagePhoto",
				usage_photo_date: this.photo_date,
			}

			// const PATCH_URL = helper.getBaseUrl() + this.station_path + "/metaphoto/" + this.selected.id;
			const PATCH_URL = this.pathUsage + "/photo/" + this.selected.id;
			console.log('editData - PATCH_URL', PATCH_URL)

			axios.patch(PATCH_URL, PATCH_DATA, helper.getConfig())
				.then((response) => {
					const found = this.photos.find(item => item.id == this.selected.id);
					found.photo_date = PATCH_DATA.photo_date;
					this.showLoading = false;
					// const selectedGeography = this.options.geography.find(item => item.id === this.geography);
					// this.createHistoricalNote(`perubahan data photo pada geography ${selectedGeography.label}`);
					if (this.dataFile !== null) {
						this.addFileTo(this.selected.id);
					}else {
						this.resetForm();
					}
				})
				.catch((error) => {
					console.log(error);
					this.showLoading = false;
				});
		},
		deleteConfirm() {
			this.$swal({
				title: "Apakah Anda Yakin?",
				text: "Apakah Anda Yakin untuk melakukan penghapusan data?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Delete",
				allowOutsideClick: false,
				allowEscapeKey: false,
				customClass: {
					confirmButton: "btn btn-danger",
					cancelButton: "btn btn-outline-dark ml-1",
				},
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					this.deleteData();
				}
			});
		},
		deleteData() {
			if (this.selected === null) {
				return false;
			}
			this.showLoading = true;

			const DELETE_URL = helper.getBaseUrl() + this.station_path + "/metaphoto/" + this.selected.id;

			axios.delete(DELETE_URL, helper.getConfig())
				.then((response) => {
					this.photos = this.photos.filter(item => item.id != this.selected.id);
					this.showLoading = false;
					const selectedGeography = this.options.geography.find(item => item.id === this.geography);
					this.createHistoricalNote(`penghapusan data photo pada geography ${selectedGeography.label}`);
					this.resetForm();
				})
				.catch((error) => {
					console.log(error);
					this.showLoading = false;
				});
		},
		addData() {
			if (this.photo_date === "" || this.dataFile === null) {
				return false;
			}
			this.showLoading = true;

			const POST_DATE = new Date().toISOString().substr(0, 19).split(":").join("-");

			const POST_ID = this.profile.station_id
							+ "_photo_"
							+ this.photo_date + "_"
							+ POST_DATE;

			const POST_DATA = {
				"@type": "StationUsagePhoto",
				id: POST_ID,
				usage_photo_date: this.photo_date,
			}

			// const POST_URL = helper.getBaseUrl() + this.station_path + "/metaphoto";
			const POST_URL = this.pathUsage + "/photo";
			console.log('addData - POST_URL', POST_URL, 'POST_DATA', POST_DATA)

			axios.post(POST_URL, POST_DATA, helper.getConfig())
				.then((response) => {
					const { data } = response;
					console.log('addData - response', data)
					this.showLoading = false;
					// const selectedGeography = this.options.geography.find(item => item.id === this.geography);
					this.createHistoricalNote(`penambahan data photo `);
					this.addFileTo(data["@name"]);
				})
				.catch((error) => {
					console.log(error);
					this.showLoading = false;
				});
		},
		addFileTo(photo_id) {
			this.showLoading = true;
			// const IMG_URL = helper.getBaseUrl() + this.station_path + "/metaphoto/" + photo_id + "/@upload/image";
			const IMG_URL = this.pathUsage + "/photo/" + photo_id + "/@upload/image";
			console.log('IMG_URL', IMG_URL)
			const IMG_CONFIG = {
				headers: { 
					Authorization: helper.getHeader().Authorization,
					"Content-Type": this.dataFile.type,
				},
			};
			console.log('addFileTo', IMG_CONFIG)
			console.log('dataFile', this.dataFile)

			axios.patch(IMG_URL, this.dataFile, IMG_CONFIG)
				.then((response) => {
					console.log('addFileTo', response)
					this.showLoading = false;
					this.resetForm();
					this.getPhotos()
					// this.getPhotosByGeography(this.geography);
				})
				.catch((error) => {
					console.log(error);
					this.showLoading = false;
				});
		},
		createHistoricalNote(description) {
			// get user login
			let user = "";
			if (localStorage.userLogin) {
				const userLogin = JSON.parse(localStorage.userLogin);
				user = userLogin.fullName;
			}

			if (user === "" || description === "") {
				return false;
			}
			this.showLoading = true;

			const NOW = new Date().toISOString();
			const date = NOW.split("T")[0];
			const POST_DATE = NOW.substr(0, 19).split(":").join("-");

			const POST_ID = this.profile.station_id
							+ "_metahistoricalnote_"
							+ date + "_"
							+ POST_DATE;

			const POST_DATA = {
				"@type": "StationUsageHistoricalNote",
				id: POST_ID,
				usage_historical_date: date,
				usage_historical_user: user,
				usage_historical_description: description,
			}

			// const POST_URL = helper.getBaseUrl() + this.station_path + "/metahistoricalnote";
			const POST_URL = this.pathUsage + "/historicalnote";
			axios.post(POST_URL, POST_DATA, helper.getConfig())
				.then((response) => {
					const { data } = response;
					this.showLoading = false;
				})
				.catch((error) => {
					console.log(error);
					this.showLoading = false;
				});
		},
	},
	watch: {
		// geography(val) {
		// 	if (val) {
		// 		this.resetForm();
		// 		this.getPhotosByGeography(val);
		// 	}
		// },
	},
};
</script>

<style scoped>
  .photo-item {
    cursor: pointer;
    border-radius: 0.5em;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border: solid 1px #b8c2cc;
  }
  .photo-item.selected {
    border: solid 1px #007bff;
  }
</style>
