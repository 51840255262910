<template>
	<div>
		<b-row>
			<b-col sm="12">
				<b-tabs pills v-model="tabStep">
					<b-tab title="General" active>
						<general :profile="profile" @station="shareStation($event)" @usageUpdate="usageUpdate($event)"
					/></b-tab>
					<b-tab :profile="profile" title="Observer" lazy> <contact /> </b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import {
	BTab,
	BTabs,
	BRow,
	BCol,
	BCard,
	BCardTitle,
	BCardBody,
} from "bootstrap-vue";
import General from "./componentgempa/GeneralGempa.vue";
import Contact from "./componentgempa/ContactGempa.vue";

export default {
	components: {
		BTab,
		BRow,
		BCol,
		BCard,
		BCardTitle,
		BCardBody,
		BTabs,
		General,
		Contact
	},
	props: ["profile"],
	data() {
		return {
		};
	},
	methods: {
	},
	mounted() {
		
	}
};
</script>
<style>
.nav-pills .nav-link {
  padding: 0.786rem 1rem;
}
</style>
