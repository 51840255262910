<template>
  <div>
    <b-row>
        <b-col>
            <b-card class="border">
                <b-row>
                    <b-col cols="12">
                        <h4 class="mb-1 badge badge-light-dark">SINOPTIK</h4>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group
                            label-cols="4"
                            label="Send To BMKGSwitching"
                            label-for="sinop_stbs"
                        >
                            <b-form-select
                                v-model="sinopBmkgSwitching"
                                id="sinopBmkgSwitching"
                                :options="options.sinopBmkgSwitchingOptions"
                            ></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                    <b-form-group
                        label-cols="4"
                        label="GTS Main Header"
                        label-for="sinop_gts_main_header"
                    >
                        <b-form-input class="textBold"  id="sinop_gts_main_header"  placeholder="Main Header"  v-model="sinop_gts_main_header" />
                    </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                    <b-form-group
                        label-cols="4"
                        label="GTS Intermediate Header"
                        label-for="sinop_gts_intermediate_header"
                    >
                        <b-form-input class="textBold" id="sinop_gts_intermediate_header" placeholder="Intermediate Header"  v-model="sinop_gts_intermediate_header" />
                    </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                    <b-form-group
                        label-cols="4"
                        label="GTS Non Standard Hour Header"
                        label-for="sinop_gts_non_standard_hour_header"
                    >
                        <b-form-input class="textBold" id="sinop_gts_non_standard_hour_header" placeholder="Non Stadard Hour Header"  v-model="sinop_gts_non_standard_hour_header" />
                    </b-form-group>
                    </b-col>
                </b-row>
            </b-card>

            <b-card class="border">
                <b-row>
                    <b-col cols="12">
                        <h4 class="mb-1 badge badge-light-dark">METAR / SPECI</h4>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                    <b-form-group
                        label-cols="4"
                        label="Send To BMKGSwitching"
                        label-for="metar_stbs"
                    >
                        <b-form-select
                            v-model="metarBmkgSwitching"
                            id="metar_stbs"
                            :options="options.metarBmkgSwitchingOptions"
                        ></b-form-select>
                    </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                    <b-form-group
                        label-cols="4"
                        label="GTS Metar Header"
                        label-for="metar_gts_metar_header"
                    >
                        <b-form-input class="textBold" id="metar_gts_metar_header"  placeholder="Metar Header"  v-model="metar_gts_metar_header" />
                    </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                    <b-form-group
                        label-cols="4"
                        label="GTS Speci Header"
                        label-for="metar_gts_speci_header"
                    >
                        <b-form-input class="textBold" id="metar_gts_speci_header" placeholder="Speci Header"  v-model="metar_gts_speci_header" />
                    </b-form-group>
                    </b-col>
                </b-row>
            </b-card>

            <b-card class="border">
                <b-row>
                    <b-col cols="12">
                        <h4 class="mb-1 badge badge-light-dark">WXREV</h4>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                    <b-form-group
                        label-cols="4"
                        label="Send To BMKGSwitching"
                        label-for="wxrev_stbs"
                    >
                        <b-form-select
                            v-model="wxrevBmkgSwitching"
                            id="wxrev_stbs"
                            :options="options.wxrevBmkgSwitchingOptions"
                        ></b-form-select>
                    </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                    <b-form-group
                        label-cols="4"
                        label="GTS Header"
                        label-for="wxrev_gts_header"
                    >
                        <b-form-input class="textBold" id="wxrev_gts_header"  placeholder="Wxrev Header"  v-model="wxrev_gts_header" />
                    </b-form-group>
                    </b-col>
                </b-row>
            </b-card>

            <b-card class="border">
                <b-row>
                    <b-col cols="12">
                        <h4 class="mb-1 badge badge-light-dark">KLIMAT</h4>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                    <b-form-group
                        label-cols="4"
                        label="Send To BMKGSwitching"
                        label-for="britaklimat_stbs"
                    >
                        <b-form-select
                            v-model="klimatBmkgSwitching"
                            id="britaklimat_stbs"
                            :options="options.klimatBmkgSwitchingOptions"
                        ></b-form-select>
                    </b-form-group>
                    </b-col> 
                </b-row>
                <b-row>
                    <b-col>
                    <b-form-group
                        label-cols="4"
                        label="GTS Berita Klimat Header"
                        label-for="britaklimat_gts_berita_klimat_header"
                    >
                        <b-form-input class="textBold" id="britaklimat_gts_berita_klimat_header"  placeholder="Berita Klimat Header"  v-model="britaklimat_gts_berita_klimat_header" />
                    </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                    <b-form-group
                        label-cols="4"
                        label="GTS Klimat Temp Header"
                        label-for="britaklimat_gts_klimat_temp_header"
                    >
                        <b-form-input class="textBold" id="britaklimat_gts_klimat_temp_header" placeholder="Klimat Temp Header"  v-model="britaklimat_gts_klimat_temp_header" />
                    </b-form-group>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
        <b-col> 

            <b-card class="border">
                <b-row>
                    <b-col cols="12">
                        <h4 class="mb-1 badge badge-light-dark">TEMP</h4>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="11">
                        <b-form-group
                            label-cols="5"
                            label="Send To BMKGSwitching"
                            label-for="temp_stbs"
                        >
                            <b-form-select
                                v-model="tempBmkgSwitching"
                                id="temp_stbs"
                                :options="options.tempBmkgSwitchingOptions"
                            ></b-form-select>
                        </b-form-group>
                    </b-col> 
                </b-row>
                <b-row>
                    <b-col cols="11">
                        <b-form-group
                            label-cols="5"
                            label="GTS TTAA Header"
                            label-for="temp_gts_ttaa_header"
                        >
                            <b-form-input class="textBold" id="temp_gts_ttaa_header"  placeholder="TTAA Header"  v-model="temp_gts_ttaa_header" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="11">
                    <b-form-group
                        label-cols="5"
                        label="GTS TTBB Header"
                        label-for="temp_gts_ttbb_header"
                    >
                        <b-form-input class="textBold" id="temp_gts_ttbb_header" placeholder="TTBB Header" v-model="temp_gts_ttbb_header" />
                    </b-form-group>
                    </b-col>
                </b-row>
                <b-row> 
                    <b-col cols="11">
                        <b-form-group
                            label-cols="5"
                            label="GTS TTCC Header"
                            label-for="temp_gts_ttcc_header"
                        >
                            <b-form-input class="textBold" id="temp_gts_ttcc_header"  placeholder="TTCC Header"  v-model="temp_gts_ttcc_header" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="11">
                        <b-form-group
                            label-cols="5"
                            label="GTS TTDD Header"
                            label-for="temp_gts_ttdd_header"
                        >
                            <b-form-input class="textBold" id="temp_gts_ttdd_header" placeholder="TTDD Header"  v-model="temp_gts_ttdd_header" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card>

            <b-card class="border">
                <b-row>
                    <b-col cols="12">
                        <h4 class="mb-1 badge badge-light-dark">PILOT</h4>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="11">
                        <b-form-group
                            label-cols="5"
                            label="Send To BMKGSwitching"
                            label-for="pilot_stbs"
                        >
                            <b-form-select
                                v-model="pilotBmkgSwitching"
                                id="pilot_stbs"
                                :options="options.pilotBmkgSwitchingOptions"
                            ></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="11">
                    <b-form-group
                        label-cols="5"
                        label="GTS PPAA Header"
                        label-for="pilot_gts_ppaa_header"
                    >
                        <b-form-input class="textBold" id="pilot_gts_ppaa_header"  placeholder="PPAA Header"  v-model="pilot_gts_ppaa_header" />
                    </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="11">
                        <b-form-group
                            label-cols="5"
                            label="GTS PPBB Header"
                            label-for="pilot_gts_ppbb_header"
                        >
                            <b-form-input class="textBold" id="pilot_gts_ppbb_header" placeholder="PPBB Header"  v-model="pilot_gts_ppbb_header" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="11">
                        <b-form-group
                            label-cols="5"
                            label="GTS PPAA PPBB National & Bilateral Header"
                            label-for="pilot_gts_ppaa_ppbb_header"
                        >
                            <b-form-input class="textBold" id="pilot_gts_ppaa_ppbb_header"  placeholder="PPAA PPBB Header"  v-model="pilot_gts_ppaa_ppbb_header" />
                            
                        </b-form-group>
                    </b-col>
                    <b-col cols="1">
                        <b-form-checkbox v-model="is_pilot_gts_ppaa_ppbb_header" style="margin-top: 8px;"/>
                    </b-col>
                </b-row>
                <b-row> 
                    <b-col cols="11">
                        <b-form-group
                            label-cols="5"
                            label="GTS PPCC Header"
                            label-for="pilot_gts_ppcc_header"
                        >
                            <b-form-input class="textBold" id="pilot_gts_ppcc_header" placeholder="PPCC Header"  v-model="pilot_gts_ppcc_header" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="11">
                    <b-form-group
                        label-cols="5"
                        label="GTS PPDD Header"
                        label-for="pilot_gts_ppdd_header"
                    >
                        <b-form-input class="textBold" id="pilot_gts_ppdd_header" placeholder="PPDD Header"  v-model="pilot_gts_ppdd_header" />
                    </b-form-group>
                    </b-col>
                </b-row>
            </b-card>

            <!-- <b-card class="border">
                <b-row>
                    <b-col cols="12">
                        <h4 class="mb-1 badge badge-light-dark">FTP BMKG SWITCHING</h4>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="11">
                        <b-form-group
                            label-cols="5"
                            label="Host"
                            label-for="ftp_host"
                        >
                            <b-form-input class="textBold" id="ftp_host"  placeholder="Host"  v-model="ftp_host" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="11">
                        <b-form-group
                            label-cols="5"
                            label="User ID"
                            label-for="ftp_user_id"
                        >
                            <b-form-input class="textBold" id="ftp_user_id"  placeholder="user id"  v-model="ftp_user_id" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="11">
                        <b-form-group
                            label-cols="5"
                            label="Password"
                            label-for="ftp_password"
                        >
                            <b-form-input class="textBold" id="ftp_password"  placeholder="password" v-model="ftp_password" />
                        </b-form-group>
                    </b-col>
                </b-row> 
                <b-row>
                    <b-col cols="11">
                        <b-form-group
                            label-cols="5"
                            label="Directory"
                            label-for="ftp_directory"
                        >
                            <b-form-input class="textBold" id="ftp_directory"  placeholder="/" v-model="ftp_directory" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card> -->

            

        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <div class="mr-1 float-right">
                <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    size="sm"
                    v-on:click="goback"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                >
                    Back
                </b-button>

                <b-button
                    variant="success"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    size="sm"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="updateStation()"
                >
                    Update
                </b-button>
            </div>
        </b-col>
    </b-row>
    <b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BOverlay,
  BButton,
  BFormCheckbox
} from "bootstrap-vue";
import helper from "@/api/helper";
import axios from "axios";
import error_handler from "@/api/error_handler";
export default {
  components: {
    BTab,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BTabs,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BOverlay,
    BButton,
    BFormCheckbox
    // General,
    // Observer,
  },
  props: ["profile", "roles"],
  data() {
    return {
        showLoading: false,
        sinopBmkgSwitching: 0,
        metarBmkgSwitching: 0,
        pilotBmkgSwitching: 0,
        wxrevBmkgSwitching: 0,
        klimatBmkgSwitching: 0,
        tempBmkgSwitching: 0,
        metar_gts_metar_header: "", 
        metar_gts_speci_header: "", 
        sinop_gts_main_header: "", 
        sinop_gts_intermediate_header: "",
        sinop_gts_non_standard_hour_header: "",
        wxrev_gts_header: "",
        britaklimat_gts_berita_klimat_header: "",
        britaklimat_gts_klimat_temp_header: "",
        temp_gts_ttaa_header: "",
        temp_gts_ttbb_header: "",
        temp_gts_ttcc_header: "", 
        temp_gts_ttdd_header: "",
        pilot_gts_ppaa_header: "", 
        pilot_gts_ppbb_header: "", 
        pilot_gts_ppaa_ppbb_header: "",
        pilot_gts_ppcc_header: "",
        pilot_gts_ppdd_header: "",
        is_pilot_gts_ppaa_ppbb_header: false,
        ftp_host: "",
        ftp_user_id: "",
        ftp_password: "",
        ftp_directory: "",
        options: {
            sinopBmkgSwitchingOptions: [
				{ value: 1, text: "Yes" },
                { value: 0, text: "No" }
            ],
            metarBmkgSwitchingOptions: [
				{ value: 1, text: "Yes" },
                { value: 0, text: "No" }
            ],
            pilotBmkgSwitchingOptions: [
				{ value: 1, text: "Yes" },
                { value: 0, text: "No" }
            ],
            wxrevBmkgSwitchingOptions: [
				{ value: 1, text: "Yes" },
                { value: 0, text: "No" }
            ],
            klimatBmkgSwitchingOptions: [
				{ value: 1, text: "Yes" },
                { value: 0, text: "No" }
            ],
            tempBmkgSwitchingOptions: [
				{ value: 1, text: "Yes" },
                { value: 0, text: "No" }
            ],
        }
    };
  },
  methods: {
    goback() {
        this.$router.push({ name: "metadata" });
    },
    updateStation() {
        let post_data = {
            "BmkgSatu.metadata.gts.IGTS": {
                "sinop_send_to_bmkgswitching": this.sinopBmkgSwitching,
                "sinop_gts_main_header": this.sinop_gts_main_header,
                "sinop_gts_intermediate_header": this.sinop_gts_intermediate_header,
                "sinop_gts_non_standard_hour_header": this.sinop_gts_non_standard_hour_header,
                "metarspeci_send_to_bmkgswitching": this.metarBmkgSwitching,
                "metarspeci_gts_metar_header": this.metar_gts_metar_header,
                "metarspeci_gts_speci_header": this.metar_gts_speci_header,
                "wxrev_send_to_bmkgswitching": this.wxrevBmkgSwitching,
                "wxrev_gts_header": this.wxrev_gts_header,
                "temp_send_to_bmkgswitching": this.tempBmkgSwitching,
                "temp_gts_ttaa_header": this.temp_gts_ttaa_header,
                "temp_gts_ttbb_header": this.temp_gts_ttbb_header,
                "temp_gts_ttcc_header": this.temp_gts_ttcc_header,
                "temp_gts_ttdd_header": this.temp_gts_ttdd_header,
                "pilot_send_to_bmkgswitching": this.pilotBmkgSwitching,
                "pilot_gts_ppaa_header": this.pilot_gts_ppaa_header,
                "pilot_gts_ppbb_header": this.pilot_gts_ppbb_header,
                "pilot_gts_ppaa_ppbb_national_bilateral_header": this.pilot_gts_ppaa_ppbb_header,
                "pilot_gts_ppcc_header": this.pilot_gts_ppcc_header,
                "pilot_gts_ppdd_header": this.pilot_gts_ppdd_header,
                "klimat_send_to_bmkgswitching": this.klimatBmkgSwitching,
                "klimat_gts_berita_klimat_header": this.britaklimat_gts_berita_klimat_header,
                "klimat_gts_klimat_temp_header": this.britaklimat_gts_klimat_temp_header,
                "ftp_bmkgswitching_host": this.ftp_host,
                "ftp_bmkgswitching_user_id": this.ftp_user_id,
                "ftp_bmkgswitching_password": this.ftp_password,
                "ftp_bmkgswitching_directory": this.ftp_directory
            }
        };
        const update_url = helper.getBaseUrl() + this.path;
        this.showLoading = true;
        axios
            .patch(update_url, post_data, helper.getConfig())
            .then((response) => {
                if (
                    response.status == 201 ||
                    response.status == 200 ||
                    response.status == 204
                ) {
                    // if (this.profile) {
                    //     this.createHistoricalNote(`perubahan data usage MKG
                    //         Date Established: ${this.profile.station_valid_from} => ${this.station_valid_from}
                    //         Date Closed: ${this.profile.station_valid_to} => ${this.station_valid_to}
                    //         WIGOS ID: ${this.profile.wigos_id} => ${this.wigos_id}
                    //         ICAO : ${this.profile.station_icao} => ${this.station_icao}
                    //         Station Name: ${this.profile.station_name} => ${this.mkg_name}
                    //         Type: ${this.profile.station_type_mkg} => ${this.station_type_mkg}
                    //         Operating Hours : ${this.profile.station_operating_hours} => ${this.station_operating_hours}
                    //         Start Hour: ${this.profile.station_hour_start} => ${this.station_hour_start}
                    //         End Hour: ${this.profile.station_valid_to} => ${this.station_hour_end}
                    //     `)
                    // }
                    this.$swal({
                    title: "Update Data berhasil!",
                    text: "Update Data MKG",
                    icon: "success",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                    });
                    this.showLoading = false;
                }
            })
            .catch((error) => {
                this.showLoading = false;
                error_handler(this, error, "Metadata Stasiun", "Update Data Error");
            });
            
            this.isEditing = false;
    }
  },
  mounted() {
    this.path = this.$route.query.path;
    console.log('mounted - gtsswitching-profile:', this.profile)
    let mkg = this.profile["BmkgSatu.metadata.gts.IGTS"]
    if (this.profile) {
        this.sinopBmkgSwitching = mkg.sinop_send_to_bmkgswitching,
        this.metarBmkgSwitching = mkg.metarspeci_send_to_bmkgswitching,
        this.pilotBmkgSwitching = mkg.pilot_send_to_bmkgswitching,
        this.wxrevBmkgSwitching = mkg.wxrev_send_to_bmkgswitching,
        this.klimatBmkgSwitching = mkg.klimat_send_to_bmkgswitching,
        this.tempBmkgSwitching = mkg.temp_send_to_bmkgswitching,
        this.metar_gts_metar_header = mkg.metarspeci_gts_metar_header, 
        this.metar_gts_speci_header = mkg.metarspeci_gts_speci_header, 
        this.sinop_gts_main_header = mkg.sinop_gts_main_header, 
        this.sinop_gts_intermediate_header = mkg.sinop_gts_intermediate_header,
        this.sinop_gts_non_standard_hour_header = mkg.sinop_gts_non_standard_hour_header,
        this.wxrev_gts_header = mkg.wxrev_gts_header,
        this.britaklimat_gts_berita_klimat_header = mkg.klimat_gts_berita_klimat_header,
        this.britaklimat_gts_klimat_temp_header = mkg.klimat_gts_klimat_temp_header,
        this.temp_gts_ttaa_header = mkg.temp_gts_ttaa_header,
        this.temp_gts_ttbb_header = mkg.temp_gts_ttbb_header,
        this.temp_gts_ttcc_header = mkg.temp_gts_ttcc_header, 
        this.temp_gts_ttdd_header = mkg.temp_gts_ttdd_header,
        this.pilot_gts_ppaa_header = mkg.pilot_gts_ppaa_header, 
        this.pilot_gts_ppbb_header = mkg.pilot_gts_ppbb_header, 
        this.pilot_gts_ppaa_ppbb_header = mkg.pilot_gts_ppaa_ppbb_national_bilateral_header,
        this.pilot_gts_ppcc_header = mkg.pilot_gts_ppcc_header,
        this.pilot_gts_ppdd_header = mkg.pilot_gts_ppdd_header,
        this.ftp_host = mkg.ftp_bmkgswitching_host,
        this.ftp_user_id = mkg.ftp_bmkgswitching_user_id,
        this.ftp_password = mkg.ftp_bmkgswitching_password,
        this.ftp_directory = mkg.ftp_bmkgswitching_directory
    }
  },
  watch: {
    profile(val) {
        console.log('gtsswitching-profile:', val)
        let mkg = this.profile["BmkgSatu.metadata.gts.IGTS"]
    
        this.sinopBmkgSwitching = mkg.sinop_send_to_bmkgswitching,
        this.metarBmkgSwitching = mkg.metarspeci_send_to_bmkgswitching,
        this.pilotBmkgSwitching = mkg.pilot_send_to_bmkgswitching,
        this.wxrevBmkgSwitching = mkg.wxrev_send_to_bmkgswitching,
        this.klimatBmkgSwitching = mkg.klimat_send_to_bmkgswitching,
        this.tempBmkgSwitching = mkg.temp_send_to_bmkgswitching,
        this.metar_gts_metar_header = mkg.metarspeci_gts_metar_header, 
        this.metar_gts_speci_header = mkg.metarspeci_gts_speci_header, 
        this.sinop_gts_main_header = mkg.sinop_gts_main_header, 
        this.sinop_gts_intermediate_header = mkg.sinop_gts_intermediate_header,
        this.sinop_gts_non_standard_hour_header = mkg.sinop_gts_non_standard_hour_header,
        this.wxrev_gts_header = mkg.wxrev_gts_header,
        this.britaklimat_gts_berita_klimat_header = mkg.klimat_gts_berita_klimat_header,
        this.britaklimat_gts_klimat_temp_header = mkg.klimat_gts_klimat_temp_header,
        this.temp_gts_ttaa_header = mkg.temp_gts_ttaa_header,
        this.temp_gts_ttbb_header = mkg.temp_gts_ttbb_header,
        this.temp_gts_ttcc_header = mkg.temp_gts_ttcc_header, 
        this.temp_gts_ttdd_header = mkg.temp_gts_ttdd_header,
        this.pilot_gts_ppaa_header = mkg.pilot_gts_ppaa_header, 
        this.pilot_gts_ppbb_header = mkg.pilot_gts_ppbb_header, 
        this.pilot_gts_ppaa_ppbb_header = mkg.pilot_gts_ppaa_ppbb_national_bilateral_header,
        this.pilot_gts_ppcc_header = mkg.pilot_gts_ppcc_header,
        this.pilot_gts_ppdd_header = mkg.pilot_gts_ppdd_header,
        this.ftp_host = mkg.ftp_bmkgswitching_host,
        this.ftp_user_id = mkg.ftp_bmkgswitching_user_id,
        this.ftp_password = mkg.ftp_bmkgswitching_password,
        this.ftp_directory = mkg.ftp_bmkgswitching_directory
    },

    roles(val) {
        if (val) {
            this.isRoleAdd = val.isRoleAdd
            this.isRoleEdit = val.isRoleEdit
            this.isRoleView = val.isRoleView
            this.isRoleDelete = val.isRoleDelete
        }
    },
  }
};
</script>
<style>
.nav-pills .nav-link {
  padding: 0.786rem 1rem;
}

.textBold {
    color: black;
}
</style>
