<template>
    <div>
        <div class="row">
            <b-col sm="6">
                <b-card no-body class="border mt-1 pr-1">
                    <b-row class="pl-1 pr-1 mt-1">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="3" label-size="md" label="Date Established :" label-for="valid-from" size="md">
                                <b-form-datepicker id="valid-from" v-model="usage_start_date" size="md" />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="3" label-size="md" label="Date Closed :" label-for="valid-to" size="md">
                                <b-form-datepicker id="valid-to" v-model="usage_end_date" size="md" />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="3" label-size="md" label="Station ID :" label-for="wmo-id" size="md">
                                <b-form-input id="wmo-id" size="md" v-model="usage_station_id" />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="3" label-size="md" label="Wigos ID :" label-for="wigos-id" size="md">
                                <b-form-input id="wigos-id" size="md" v-model="usage_wigos_id" readonly />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="3" label-size="md" label="Station Name :" label-for="station-name" size="md">
                                <b-form-input id="station-name" size="md" v-model="usage_station_name" />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="3" label-size="md" label="Koordinator Station :" label-for="select-koordinator">
                                <!-- <b-form-select
									v-model="usage_koordinator_station"
									id="select-type"
									size="sm"
									:options="options.stations"
								></b-form-select> -->
                                <!-- <b-spinner v-if  variant="primary" label="Spinning"></b-spinner> -->
                                <v-select
                                    id="select-koordinator"
                                    v-model="koordinatorSelected"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="koordinatorOptions"
                                    class="select-size-sm"
                                    style="margin-top: -2px; background: white;"
                                    :loading="loading"
                                >
                                </v-select>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row v-if="isPosHujan" class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="3" label-size="md" label="Ownership :" label-for="ownership">
                                <b-form-input id="ownership" size="md" v-model="usage_ownership" />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="3" label-size="md" label="Contact Name :" label-for="contact-name">
                                <b-form-input id="contact-name" size="md" v-model="usage_contact_name" />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="3" label-size="md" label="Contact Telephone :" label-for="contact-telephone">
                                <b-form-input id="contact-telephone" size="md" v-model="usage_contact_telephone" />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row v-if="isPosHujan" class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="3" label-size="md" label="Instrument Status :" label-for="instrument_status">
                                <b-form-select v-model="usage_instrument_status" class="mb-1" size="md">
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card>

                <div>
                    <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" v-on:click="goback" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                        Back
                    </b-button>

                    <b-button variant="success" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="updateStation()" :disabled="!isRoleEdit">
                        Update
                    </b-button>

                    <b-button variant="danger" class="mb-1 mb-sm-0 ml-0 ml-sm-1 float-right" type="reset" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="removeUsage()" :disabled="!isRoleEdit">
                        Remove Usage
                    </b-button>
                </div>
            </b-col>

            <b-col v-if="!isPosHujan" sm="6">
                <b-card no-body class="border mt-1 pr-1">
                    <b-row class="pl-1 pr-1 mt-1">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="3" label-size="md" label="Latitude :" label-for="usage-latitude" size="md">
                                <b-form-input id="usage-latitude" size="md" v-model="usage_latitude" />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="3" label-size="md" label="Longitude :" label-for="usage-longitude" size="md">
                                <b-form-input id="usage-longitude" size="md" v-model="usage_longitude" />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="pl-1 pr-1 mt">
                        <b-col>
                            <b-form-group label-cols="4" label-cols-lg="3" label-size="md" label="Elevation :" label-for="usage-elevation" size="md">
                                <b-form-input id="usage-elevation" size="md" v-model="usage_elevation" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </div>

        <b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
    </div>
</template>

<script>
import { BCard, BCardHeader, BContainer, BRow, BCol, BFormSelect, BForm, BButton, BFormGroup, BCollapse, BFormDatepicker, BFormInput, BOverlay, BFormCheckbox, BTable, BSpinner } from "bootstrap-vue";
import vSelect from "vue-select";

import axios from "axios";
import helper from "@/api/helper";
import metadata from "@/api/metadata";
import error_handler from "@/api/error_handler";

export default {
    components: {
        BCard,
        BCardHeader,
        BContainer,
        BRow,
        BCol,
        BFormSelect,
        BForm,
        BButton,
        BFormGroup,
        BCollapse,
        BFormDatepicker,
        BFormInput,
        BOverlay,
        vSelect,
        BFormCheckbox,
        BTable,
        BSpinner,
    },
    props: ["profile", "detail", "roles", "usage"],
    data() {
        return {
            koordinatorSelected: null,
            koordinatorOptions: [],
            isEditing: false,
            showLoading: false,
            loading: false,
            usage_start_date: "",
            usage_end_date: "",
            usage_station_id: "",
            usage_wigos_id: "",
            usage_station_name: "",
            usage_koordinator_station: "",
            usage_contact_name: "",
            usage_contact_telephone: "",
            usage_latitude: "",
            usage_longitude: "",
            usage_elevation: "",
            usage_ownership: "",
            usage_instrument_status: "",
            pathStation: "",
            pathUsage: "",
            usage_name: "",
            t_usage: "lain",
            options: {
                stations: [],
            },
            pengamatanTable: {
                fields: [
                    { key: "checked", label: "" },
                    { key: "pengamatan", label: "Pengamatan", tdClass: "text-left" },
                    { key: "start_date", label: "Start Date" },
                    { key: "end_date", label: "End Date" },
                ],
                items: [
                    {
                        id: 1,
                        pengamatan: "PILOT",
                        checked: false,
                        start_date: null,
                        end_date: null,
                    },
                    {
                        id: 2,
                        pengamatan: "RASON",
                        checked: false,
                        start_date: null,
                        end_date: null,
                    },
                    {
                        id: 3,
                        pengamatan: "PETIR",
                        checked: false,
                        start_date: null,
                        end_date: null,
                    },
                    {
                        id: 4,
                        pengamatan: "MAGNET",
                        checked: false,
                        start_date: null,
                        end_date: null,
                    },
                ],
            },
            isRoleAdd: false,
            isRoleEdit: false,
            isRoleView: false,
            isRoleDelete: false,
            isPosHujan: false,
        };
    },

    watch: {
        roles(val) {
            // console.log("s.roles", val);
            // console.log('this.roles', this.roles)
            if (val) {
                this.isRoleAdd = val.isRoleAdd;
                this.isRoleEdit = val.isRoleEdit;
                this.isRoleView = val.isRoleView;
                this.isRoleDelete = val.isRoleDelete;
            }
        },
    },

    mounted() {
        this.pathStation = this.$route.query.path;
        this.usage_wigos_id = this.profile.wigos_id;

        // console.log('detail', this.detail)
        if (this.detail && this.detail.label == "Pos Hujan") {
            this.isPosHujan = true;
        }

        if (this.usage) {
            // console.log("this.usage", this.usage);
            this.t_usage = this.usage;
            if (this.usage == "poshujan") {
                this.isPosHujan = true;
            }
        }

        if (this.roles) {
            // console.log('mounted - this.roles', this.roles)
            this.isRoleAdd = this.roles.isRoleAdd;
            this.isRoleEdit = this.roles.isRoleEdit;
            this.isRoleView = this.roles.isRoleView;
            this.isRoleDelete = this.roles.isRoleDelete;
        }

        this.getInfoUsage();
        this.getStationsWmo();
    },
    methods: {
        goback() {
            this.$router.push({ name: "metadata" });
        },

        removeUsage() {
            this.$swal({
                title: "Remove Usage?",
                text: "Anda ingin menghapus usage " + this.usage_name + " pada stasiun ini?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Ya",
                allowOutsideClick: false,
                allowEscapeKey: false,
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                this.processRemoveUsage(this.usage_name);
            });
        },

        processRemoveUsage(usageName) {
            this.showLoading = true;
            let post_data = {
                "BmkgSatu.metadata.station.behavusage.IBehavStationUsage": {
                    usage_status: false,
                },
            };

            let usageUrl = this.pathUsage;

            let currentUrl = window.location.protocol;
            // currentUrl = currentUrl.replace('http:','https:');
            // console.log('currentUrl', currentUrl)
            if (currentUrl.indexOf("https:") == 0 && this.pathUsage.indexOf("http:") == 0) {
                let url = this.pathUsage.replace("http:", "https:");
                usageUrl = url;
            }

            // console.log("remove - post_data: ", post_data, "usageUrl: ", usageUrl);

            axios
                .patch(usageUrl, post_data, helper.getConfig())
                .then((response) => {
                    if (response.status == 201 || response.status == 200 || response.status == 204) {
                        let detail = this.detail.detail;
                        detail.usage_status = false;

                        this.$swal({
                            title: "Remove usage berhasil!",
                            text: "Usage '" + usageName + "' telah di remove.",
                            icon: "success",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                        this.showLoading = false;
                    }
                })
                .catch((error) => {
                    this.showLoading = false;
                    error_handler(this, error, "Metadata Stasiun", "Update Data Error");
                });
        },

        updateStation() {
            this.showLoading = true;
            let st_usage_id = "-1";
            if (this.usage_station_id && this.usage_station_id != "" && this.usage_station_id != "-" && this.usage_station_id.length > 1) {
                st_usage_id = this.usage_station_id;
            }
            let post_data = {};
            if (this.isPosHujan) {
                if (this.koordinatorSelected) {
                    this.usage_koordinator_station = this.koordinatorSelected.value;
                } else {
                    this.usage_koordinator_station = null;
                }
                post_data = {
                    "BmkgSatu.metadata.station.behavusage.IBehavStationUsage": {
                        usage_start_date: this.usage_start_date,
                        usage_end_date: this.usage_end_date,
                        usage_station_id: st_usage_id,
                        usage_station_name: this.usage_station_name,
                        usage_koordinator_station: this.usage_koordinator_station,
                        usage_contact_name: this.usage_contact_name,
                        usage_contact_telephone: this.usage_contact_telephone,
                        usage_latitude: this.usage_latitude,
                        usage_longitude: this.usage_longitude,
                        usage_elevation: this.usage_elevation,
                        usage_status: true,
                    },
                    "BmkgSatu.metadata.station.behavusage.IBehavPosHujanUsage": {
                        usage_ownership: this.usage_ownership,
                        usage_instrument_status: this.usage_instrument_status,
                    },
                };
            } else {
                if (this.koordinatorSelected) {
                    this.usage_koordinator_station = this.koordinatorSelected.value;
                } else {
                    this.usage_koordinator_station = null;
                }
                post_data = {
                    "BmkgSatu.metadata.station.behavusage.IBehavStationUsage": {
                        usage_start_date: this.usage_start_date,
                        usage_end_date: this.usage_end_date,
                        usage_station_id: st_usage_id,
                        usage_station_name: this.usage_station_name,
                        usage_koordinator_station: this.usage_koordinator_station,
                        usage_contact_name: this.usage_contact_name,
                        usage_contact_telephone: this.usage_contact_telephone,
                        usage_latitude: this.usage_latitude,
                        usage_longitude: this.usage_longitude,
                        usage_elevation: this.usage_elevation,
                        usage_status: true,
                    },
                };
            }

            const usageUrl = this.pathUsage;

            // console.log("this.pathStation: ", this.pathStation, "usageUrl: ", usageUrl);

            axios
                .patch(usageUrl, post_data, helper.getConfig())
                .then((response) => {
                    if (response.status == 201 || response.status == 200 || response.status == 204) {
                        this.checkUsageInfo();
                        let detail = this.detail.detail;
                        detail.usage_start_date = this.usage_start_date;
                        detail.usage_end_date = this.usage_end_date;
                        detail.usage_station_id = this.usage_station_id;
                        detail.usage_station_name = this.usage_station_name;
                        detail.usage_koordinator_station = this.usage_koordinator_station;
                        detail.usage_contact_name = this.usage_contact_name;
                        detail.usage_contact_telephone = this.usage_contact_telephone;
                        detail.usage_latitude = this.usage_latitude;
                        detail.usage_longitude = this.usage_longitude;
                        detail.usage_elevation = this.usage_elevation;
                        detail.usage_status = true;

                        this.$swal({
                            title: "Update data berhasil!",
                            text: "Update data usage " + this.usage_name + " telah disimpan",
                            icon: "success",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                        this.showLoading = false;
                    }
                })
                .catch((error) => {
                    this.showLoading = false;
                    error_handler(this, error, "Metadata Stasiun", "Update Data Error");
                });
        },

        async checkUsageInfo() {
            let url = helper.getBaseUrl() + this.pathStation + "/usage";

            const { data } = await axios.get(url, helper.getConfig());
            // console.log("checkUsageInfo", data);
            if (data && data.hasOwnProperty("items")) {
                let usages = data.items;
                let usage_contain_mkg = false
                
                if (usages.find((e) => e["@name"] == "mkg")) {
                    usage_contain_mkg = true
                }

                if (usages.find((e) => e["@name"] == "poshujan")) {    
                    await this.updatePosHujanInBMKGStation(usage_contain_mkg)
                }

                // AN not fully correct
                // if (usages.find((e) => e["@name"] == "mkg")) {
                //     console.log(
                //         "isFind",
                //         usages.find((e) => e["name"] == "mkg")
                //     );
                // } else {
                //     console.log("notfind");
                    // let baseurl = helper.getBaseUrl() + this.pathStation;

                    // let post_data = {
                    //     alias_station_id: this.usage_station_id,
                    // };
                    // if (usages.find((e) => e["@name"] == "poshujan")) {
                    //     post_data["is_poshujan"] = 1;
                    //     post_data["BmkgSatu.metadata.poshujan.IPosHujan"] = {
                    //         pos_hujan_id: this.usage_station_id,
                    //         poshujan_valid_from: this.usage_start_date,
                    //         poshujan_valid_to: this.usage_end_date,
                    //         poshujan_is_active: this.usage_instrument_status == "active"? 1 : 0,
                    //         poshujan_entry_station_id: this.usage_koordinator_station,
                    //     };
                    // }
                    // await axios.patch(baseurl, post_data, helper.getConfig());
                    
                // }
            }
        },

        async updatePosHujanInBMKGStation(usage_contain_mkg) {
            let baseurl = helper.getBaseUrl() + this.pathStation;

            let post_data = {
                alias_station_id: this.usage_station_id,
            };
            
            // AN legacy code, mungkin jika MKG jangan di set is_poshujan true?
            if (!usage_contain_mkg) {
                post_data["is_poshujan"] = 1;
            }
            post_data["BmkgSatu.metadata.poshujan.IPosHujan"] = {
                pos_hujan_id: this.usage_station_id,
                poshujan_valid_from: this.usage_start_date,
                poshujan_valid_to: this.usage_end_date,
                poshujan_is_active: this.usage_instrument_status == "active"? 1 : 0,
                poshujan_entry_station_id: this.usage_koordinator_station,
            };

            await axios.patch(baseurl, post_data, helper.getConfig());
        },

        getInfoUsage() {
            // console.log(this.detail)
            if (this.detail) {
                // AN bugs, karena path https://backend/db/bmkgsatu/...
                // this.pathUsage = this.detail.path;
                const url = this.detail.path
                this.pathUsage = helper.getBaseUrl() + url
                    .substring(url.indexOf("/db/bmkgsatu/"))
                    .replace('/db/bmkgsatu/', '');

                let detail = this.detail.detail;
                this.usage_name = detail.usage_name;
                this.usage_start_date = detail.usage_start_date;
                this.usage_end_date = detail.usage_end_date;
                this.usage_station_id = detail.usage_station_id;
                this.usage_station_name = detail.usage_station_name;
                this.usage_koordinator_station = detail.usage_koordinator_station;
                this.usage_contact_name = detail.usage_contact_name;
                this.usage_contact_telephone = detail.usage_contact_telephone;
                this.usage_latitude = detail.usage_latitude;
                this.usage_longitude = detail.usage_longitude;
                this.usage_elevation = detail.usage_elevation;
                this.usage_status = detail.usage_status;

                // Only poshujan otherwise null
                if(this.isPosHujan) {
                    this.usage_instrument_status = 
                        detail?.usage_instrument_status || "inactive";
                    this.usage_ownership = 
                        detail?.usage_ownership;
                }   
            }
        },

        async getStationsWmo() {
            try {
                this.loading = true;
                const { data } = await metadata.getAllStasiunWithWMO();
                for (var i = 0; i < data.length; i++) {
                    const data_station = {
                        value: data[i].station_id,
                        label: data[i].station_wmo_id + " - " + data[i].station_name,
                        path: data[i].path,
                    };

                    this.koordinatorOptions.push(data_station);
                }

                if (this.koordinatorOptions.length > 0) {
                    for (var station of this.koordinatorOptions) {
                        if (station.value == this.usage_koordinator_station) {
                            this.koordinatorSelected = station;
                            break;
                        }
                    }
                }
                this.loading = false;
            } catch (e) {
                console.error(e);
            }
        },
    },
};
</script>

<style scoped>
.pad2 {
    padding-left: 2px;
    padding-right: 2px;
}
.mt {
    margin-top: -9px;
}
</style>
