<template>
	<div>
		<b-row> 
			<b-col cols="6" class="ml-2">
			
				<label class="font-weight-bolder"> Pos Hujan Station</label>

				<b-card no-body class="border mt-1">

				<vue-good-table
					:columns="selectedFields"
					:rows="selectedItems"
					:rtl="direction"
					:isLoading="showLoading"
					:search-options="{
					enabled: true,
					externalQuery: searchTerm,
					}"
					:pagination-options="{
					enabled: true,
					perPage:pageLength,
					setCurrentPage: 1,
					mode: 'pages'
					}"
					@on-row-click="onRowClick"
					theme="polar-bear"
				> </vue-good-table>

				</b-card>
			</b-col>
		</b-row>
		<b-row class="row ml-2">
			<b-button
					variant="primary"
					class="mb-1 mb-sm-0 mr-0 mr-sm-1"
					size="sm"
					:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					v-on:click="goback"
				>
				Back
				</b-button>
		</b-row>
    <b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
	</div>
</template>

<script>

import {
  BCard,
  BCardHeader,
  BContainer,
  BRow,
  BCol,
  BForm,
  BButton,
  BFormGroup,
  BTable,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import Service from "@/api/metadata";
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
export default {
	components: {
		BCard,
		BCardHeader,
		BContainer,
		BRow,
		BCol,
		BForm,
		BButton,
		BFormGroup,
		BTable,
    VueGoodTable
	},
	directives: {
		Ripple,
	},
	mixins: [togglePasswordVisibility],
	computed: {
		passwordToggleIcon() {
		return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
		},
	},
  props: ["profile"],
	data: function () {
		return {
      searchTerm: '',
      paginationCurrentPage: 10,
			pageLength: 10,
      showLoading: false,
      isLoading: false,
			// selectedFields: [
			// 	{ key: 'pos_hujan_id', label: 'Pos Hujan ID', sortable: true, class: 'text-center' },
			// 	{ key: 'station_name', label: 'Name', sortable: true, class: 'text-center' }
      // ],
      selectedFields: [
				{
					label: 'POS HUJAN ID',
					field: 'pos_hujan_id',
					tdClass: 'text-center',
					thClass: 'text-center theadc',
				},
				{
					label: 'Station Name',
					field: 'station_name',
					tdClass: 'text-left',
					thClass: 'text-center theadc',
				}
			],
			selectedItems: []
		};
	},

	methods: {
    onRowClick(params) {
			
		},
		add(item) {
			item.add_status = 1
			this.selectedItems.push(item)
		},
		remove(item) {
			console.log('item', item)
			item.add_status = 0
			this.selectedItems.forEach((sitem, index) => {
				if(sitem.userid === item.userid) {
					this.selectedItems.splice(index,1);
					return false;
				}
			});
		},
		goback() {
			console.log('goback')
			this.$router.push({ name: "metadata" });
		},
		onCancel() {
			console.log('onCancel')
			this.$emit("goBack", "back");
		},
		onUpdate() {
			console.log('onUpdate')
		},
		getStationPosHujan() {
			this.showLoading = true;
			let station_id = null;
			if (this.profile) {
				station_id = this.profile.station_id
			}
			try {
				Service.getStationPosHujan(station_id)
				.then((response) => {
					this.showLoading = false;
					const data = response.data
					// console.log("getStationPosHujan ", station_id, data);
					if (data && data.length > 0) {
						this.totalRows = data.length;
						this.selectedItems = data
					}
				})
				.catch((error) => {
					this.showLoading = false;
					console.log("Eee : ", error.response.status);
				});
			} catch (error) {
				console.log("error: ", error);
				this.showLoading = false;
			}
		}
	},

	mounted() {
		this.getStationPosHujan()
	},

	watch: {
		
	}
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style>
	.vgt-table thead th.theadc {
		background-color: #f7fafc !important;
	}

</style>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
.mt {
  margin-top: -9px;
}
</style>
















<!-- <template>
  <div>
    <b-card>
      <b-row>
        <b-col sm="12">
          <b-tabs vertical nav-wrapper-class="nav-vertical">
            <b-tab title="Range Check" active> <quality-control :profile="profile"/></b-tab>
            <b-tab title="Stations"> <station :profile="profile"/></b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BTab, BTabs, BRow, BCol, BCard } from "bootstrap-vue";
import QualityControl from "./componentposhujan/qccomponent.vue";
import Station from "./componentposhujan/liststationcomponent.vue";

export default {
  components: {
    BTab,
    BRow,
    BCol,
    BCard,
    BTabs,
    QualityControl,
    Station,
  },
  props: ["profile"],
  mounted() {
    // console.log("me48 : ", this.profile);
  },
};
</script>

<style>
.nav-pills .nav-link {
  padding: 0.786rem 1rem;
}
</style> -->
