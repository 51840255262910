<template>
	<div>
		<b-row> 
			<b-col cols="6" class="ml-2">
				<label class="font-weight-bolder"> Selected User</label>
				<b-card no-body class="border mt-1">
					<b-table
						:items="selectedItems"
						:fields="selectedFields"
						thead-class="text-center"
						
					>
						<template #cell(action)="row">
							<b-button
								v-ripple.400="'rgba(40, 199, 111, 0.15)'"
								variant="flat-danger"
								class="btn-icon rounded-circle"
								@click="remove(row.item)"
								size="sm"
							>
							
								<feather-icon icon="TrashIcon" />
							</b-button>
						</template>
					</b-table>
				</b-card>
			</b-col>
			<b-col cols="5">
				<label class="font-weight-bolder"> List User</label>
				<b-card no-body class="border mt-1">
					<b-table
						:items="optionItems"
						:fields="optionFields"
						thead-class="text-center"
						
					>
						<template #cell(action)="row">
							<b-button
								v-ripple.400="'rgba(40, 199, 111, 0.15)'"
								variant="flat-primary"
								class="btn-icon rounded-circle bold"
								@click="add(row.item)"
								size="sm"
								:disabled="row.item.add_status == 1"
							>
							
								<feather-icon icon="PlusIcon" />
							</b-button>
						</template>
					</b-table>
				</b-card>
			</b-col>
		</b-row>
		<b-row class="row ml-2">
			<b-button
					variant="primary"
					class="mb-1 mb-sm-0 mr-0 mr-sm-1"
					size="sm"
					:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					v-on:click="goback"
				>
				Back
				</b-button>
				<b-button
					variant="danger"
					class="mb-1 mb-sm-0 mr-0 mr-sm-1"
					size="sm"
					:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					v-on:click="onCancel"
				>
				Cancel
				</b-button>
				<b-button
					variant="gradient-success"
					class="mb-1 mb-sm-0 mr-0 mr-sm-1"
					type="reset"
					size="sm"
					:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					v-on:click="onUpdate"
				>
				Update
			</b-button>	
		</b-row>
	</div>
</template>

<script>

import {
  BCard,
  BCardHeader,
  BContainer,
  BRow,
  BCol,
  BForm,
  BButton,
  BFormGroup,
  BTable,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import Service from "@/api/userservice";
import Ripple from 'vue-ripple-directive'
export default {
	components: {
		BCard,
		BCardHeader,
		BContainer,
		BRow,
		BCol,
		BForm,
		BButton,
		BFormGroup,
		BTable,
	},
	directives: {
		Ripple,
	},
	mixins: [togglePasswordVisibility],
	computed: {
		passwordToggleIcon() {
		return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
		},
	},
	data: function () {
		return {
			selectedFields: [
				{ key: 'userid', label: 'User ID', sortable: true, class: 'text-center' },
				{ key: 'username', label: 'Username', sortable: true, class: 'text-center' },
				{ key: 'department', label: 'Department', sortable: true, class: 'text-center' },
				{ key: 'assigned_at', label: 'Assigned At', sortable: true, class: 'text-center' },
				{ key: 'action', label: 'Action', class: 'text-center'}],
			selectedItems: [
				// { userid: "df", username: "dfd", department: "", assigned_at: "", }
			],
			optionFields: [
				{ key: 'userid', label: 'User ID', sortable: true, class: 'text-center' },
				{ key: 'username', label: 'Username', sortable: true, class: 'text-center' },
				{ key: 'department', label: 'Department', sortable: true, class: 'text-center' },
				{ key: 'action', label: 'Action', class: 'text-center'}
			],
			optionItems: [
				// { userid: "df", username: "dfd", department: "", assigned_at: "",}
			]
		};
	},

	methods: {
		add(item) {
			item.add_status = 1
			this.selectedItems.push(item)
		},
		remove(item) {
			console.log('item', item)
			item.add_status = 0
			this.selectedItems.forEach((sitem, index) => {
				if(sitem.userid === item.userid) {
					this.selectedItems.splice(index,1);
					return false;
				}
			});
		},
		goback() {
			console.log('goback')
			this.$router.push({ name: "metadata" });
		},
		onCancel() {
			console.log('onCancel')
			this.$emit("goBack", "back");
		},
		onUpdate() {
			console.log('onUpdate')
		},
		getAllUsers() {
			this.showLoading = true;
			try {
				Service.getListUser()
				.then((response) => {
					this.showLoading = false;
					const data = response.data
					// console.log("list-users: ", response.data);
					for (var i = 0 ; i < data.length; i++) {
						let user = { userid: data[i].id, username: data[i]['@name'], department: "", assigned_at: "", add_status:0}
						this.optionItems.push(user)
					}
					// this.items = response.data;
					// this.totalRows = this.items.length;
				})
				.catch((error) => {
					this.showLoading = false;
					console.log("Eee : ", error.response.status);
				});
			} catch (error) {
				console.log("error: ", error);
				this.showLoading = false;
			}
		}
	},
	mounted() {
		this.getAllUsers()
	},
	watch: {
		
	}
};
</script>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
.mt {
  margin-top: -9px;
}
</style>
