<template>
	<div>
		<div class="row">
			<b-col sm="6">
				<b-card no-body class="border mt-1">
					<b-row class="pl-1 pr-1 mt-1">
						<b-col>
						<b-form-group
							label-cols="4"
							label-size="sm"
							label="Valid From :"
							label-for="valid-from"
							size="sm"
						>
							<b-form-datepicker
								id="valid-from"
								v-model="radar_valid_from"
								size="sm"
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-size="sm"
							label="Valid To :"
							label-for="valid-from"
							size="sm"
						>
							<b-form-datepicker
								id="valid-to"
								v-model="radar_valid_to"
								size="sm"
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-size="sm"
							label="Radar ID :"
							label-for="radar-id"
							size="sm"
						>
							<b-form-input
							id="radar-id"
							size="sm"
							v-model="radar_id"
							disabled
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-size="sm"
							label="Wigos ID :"
							label-for="wigos-id"
							size="sm"
						>
							<b-form-input
							id="wigos-id"
							size="sm"
							v-model="wigos_id"
							/>
						</b-form-group>
						</b-col>
					</b-row>
					
					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="4"
								label-size="sm"
								label="Is Active :"
								label-for="is-active"
							>
								<b-form-checkbox
									id="is-active"
									v-model="radar_active"
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Radar :"
							label-for="radar-code"
						>
							<b-form-input
								id="radar-code"
								size="sm"
								v-model="radar_code"
							/>
						</b-form-group>
						</b-col>
					</b-row>
					
					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="4"
								label-size="sm"
								label=" Radar Name :"
								label-for="radar-name"
							>
								<b-form-input
									id="radar-name"
									size="sm"
									v-model="radar_name"
								/>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="4"
								label-size="sm"
								label="Latitude :"
								label-for="radar-latitude"
							>
								<b-form-input
									id="radar-latitude"
									size="sm"
									v-model="radar_latitude"
								/>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="4"
								label-size="sm"
								label="Longitude :"
								label-for="radar-longitude"
							>
								<b-form-input
								id="radar-longitude"
								size="sm"
								v-model="radar_longitude"
								
							/>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Brand:"
							label-for="brand"
						>
							<b-form-select
								v-model="radar_brand_id"
								id="brand"
								size="sm"
								:options="options.brand"
							></b-form-select>
						</b-form-group>
						</b-col>
					</b-row>

					<!-- <b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Band :"
							label-for="band"
						>
							<b-form-select
								v-model="radar_band_id"
								id="band_id"
								size="sm"
								:options="options.band"
							></b-form-select>
						</b-form-group>
						</b-col>
					</b-row> -->

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="TX Type :"
							label-for="tx-type"
						>
							<b-form-select
								v-model="radar_tx_type_id"
								id="tx-type"
								size="sm"
								:options="options.txtype"
							></b-form-select>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Polariza :"
							label-for="polariza"
						>
							<b-form-select
								v-model="radar_polarization_id"
								id="polariza"
								size="sm"
								:options="options.polariza"
							></b-form-select>
						</b-form-group>
						</b-col>
					</b-row>
				</b-card>

				<div>
					<b-button
						variant="primary"
						class="mb-1 mb-sm-0 mr-0 mr-sm-1"
						size="sm"
						v-on:click="goback"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					>
						Back
					</b-button>

					<b-button
						variant="success"
						class="mb-1 mb-sm-0 mr-0 mr-sm-1"
						size="sm"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
						@click="updateStation()"
					>
						Update
					</b-button>

					<!-- <b-button
						variant="danger"
						class="mb-1 mb-sm-0 ml-0 ml-sm-1 float-right"
						type="reset"
						size="sm"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					>
						Delete
					</b-button> -->
				</div>
			</b-col>
		</div>

		<b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
	</div>
</template>

<script>
import {
	BCard,
	BCardHeader,
	BContainer,
	BRow,
	BCol,
	BFormSelect,
	BForm,
	BButton,
	BFormGroup,
	BCollapse,
	BFormDatepicker,
	BFormInput,
	BOverlay,
	BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";

import axios from "axios";
import helper from "@/api/helper";
import metadata from "@/api/metadata";
import error_handler from "@/api/error_handler";

export default {
	components: {
		BCard,
		BCardHeader,
		BContainer,
		BRow,
		BCol,
		BFormSelect,
		BForm,
		BButton,
		BFormGroup,
		BCollapse,
		BFormDatepicker,
		BFormInput,
		BOverlay,
		vSelect,
		BFormCheckbox,
	},
	props: ["profile"],
	data() {
		return {
			isEditing: false,
			showLoading: false,
			path: "",
			radar_valid_from:"",
			radar_valid_to: "",
			radar_id: "",
			wigos_id: "",
			radar_active: "",
			radar_code:"",
			radar_name: "",
			radar_longitude: "",
			radar_latitude: "",
			radar_brand_id: "",
			radar_band_id: "",
			radar_tx_type_id: "",
			radar_polarization_id: "",
			options: {
				brand: [
					{ value: 2, text: "EEC" }, { value: 3, text: "Gemotronik" }, { value: 4, text: "Baron" }, { value: 5, text: "Vaisala" }
				],
				band: [
					{ value: 2, text: "C-Band" }, { value: 3, text: "X-Band" }
				],
				txtype: [
					{ value: 2, text: "Magnetron" }, { value: 3, text: "Solid-State" }
				],
				polariza: [
					{ value: 2, text: "Single" }, { value: 3, text: "Dual" }
				]
			}
		};
	},
	mounted() {
		this.path = this.$route.query.path;
		this.wigos_id = this.profile.wigos_id
		let radar = this.profile["BmkgSatu.metadata.radar.IRadar"]
		this.radar_valid_from = radar.radar_valid_from,
		this.radar_valid_to = radar.radar_valid_to,
		this.radar_id = radar.radar_id,
		this.radar_active = radar.radar_active ? radar.radar_active : false,
		this.radar_code = radar.radar_code,
		this.radar_name = radar.radar_name,
		this.radar_latitude = radar.radar_latitude,
		this.radar_longitude = radar.radar_longitude,
		this.radar_brand_id = radar.radar_brand_id,
		this.radar_band_id = radar.radar_band_id
		this.radar_tx_type_id = radar.radar_tx_type_id,
		this.radar_polarization_id = radar.radar_polarization_id
	},
	methods: {
		goback() {
			this.$router.push({ name: "metadata" });
		},
		updateStation() {
			this.showLoading = true;

			const update_data = {
				"radar_valid_from": this.radar_valid_from,
				"radar_valid_to": this.radar_valid_to,
				"radar_id": this.radar_id,
				"radar_active": this.radar_active,
				"radar_code": this.radar_code,
				"radar_name": this.radar_name,
				"radar_longitude": this.radar_longitude,
				"radar_latitude": this.radar_latitude,
				"radar_brand_id": this.radar_brand_id,
				"radar_band_id": this.radar_band_id,
				"radar_tx_type_id": this.radar_tx_type_id,
				"radar_polarization_id": this.radar_polarization_id
			};

			let post_data = {
				"@type": "BmkgStation",
				"BmkgSatu.metadata.radar.IRadar": update_data,
			};

			const update_url = helper.getBaseUrl() + this.path;

			axios
				.patch(update_url, post_data, helper.getConfig())
				.then((response) => {
					if (
						response.status == 201 ||
						response.status == 200 ||
						response.status == 204
					) {
						this.$swal({
						title: "Update Data berhasil!",
						text: "Update Data Pos Hujan",
						icon: "success",
						customClass: {
							confirmButton: "btn btn-primary",
						},
						buttonsStyling: false,
						});
						this.showLoading = false;
					}
				})
				.catch((error) => {
					this.showLoading = false;
					error_handler(this, error, "Metadata Stasiun", "Update Data Error");
				});
				this.isEditing = false;
		},
	},
};
</script>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
.mt {
  margin-top: -9px;
}
</style>
