<template>
	<div>
		<div class="row">
			<b-col sm="6">
				<b-card no-body class="border mt-1">

					<b-row class="pl-1 pr-1 mt-1">
						<b-col>
						<b-form-group
							label-cols="4"
							label-size="sm"
							label="Valid From :"
							label-for="valid-from"
							size="sm"
						>
							<b-form-datepicker
								id="valid-from"
								v-model="acc_valid_from"
								size="sm"
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-size="sm"
							label="Valid To :"
							label-for="valid-from"
							size="sm"
						>
							<b-form-datepicker
								id="valid-to"
								v-model="acc_valid_to"
								size="sm"
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-size="sm"
								label="Station ID :"
								label-for="station-id"
								size="sm"
							>
								<b-form-input
									id="station-id"
									size="sm"
									v-model="station_id"
									readonly
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-size="sm"
								label="Wigos ID :"
								label-for="wigos-id"
								size="sm"
							>
								<b-form-input
									id="wigos-id"
									size="sm"
									v-model="wigos_id"
									readonly
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<!-- <b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="4"
								label-size="sm"
								label="ID :"
								label-for="acc-id"
							>
								<b-form-input
									id="acc-id"
									size="sm"
									v-model="acc_id"
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-size="sm"
								label="Tanggal Pemasangan :"
								label-for="install-date"
								size="sm"
							>
								<b-form-datepicker
									id="install-date"
									v-model="acc_install_date"
									size="sm"
								/>
							</b-form-group>
						</b-col>
					</b-row> -->

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="4"
								label-size="sm"
								label="Samp Rate :"
								label-for="samp-rate"
							>
								<b-form-input
									id="samp-rate"
									size="sm"
									v-model="acc_samp_rate"
								/>
							</b-form-group>
						</b-col>
					</b-row>
					<!-- <b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="4"
								label-size="sm"
								label="Accelero :"
								label-for="accelero"
							>
								<b-form-input
									id="accelero"
									size="sm"
									v-model="acc_accelero"
								/>
							</b-form-group>
						</b-col>
					</b-row> -->

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Accelero Serial :"
							label-for="accelero-serial"
						>
							<b-form-input
								id="accelero-serial"
								size="sm"
								v-model="acc_accelero_serial"
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Digitiser:"
							label-for="digitiser"
						>
							<b-form-input
								id="digitiser"
								size="sm"
								v-model="acc_digitiser"
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Digitiser Serial :"
							label-for="digitiser-serial"
						>
							<b-form-input
								id="digitiser-serial"
								size="sm"
								v-model="acc_digitiser_serial"
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Profile :"
							label-for="acc-profile"
						>
							<b-form-input
								id="acc-profile"
								size="sm"
								v-model="acc_profile"
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Type :"
							label-for="acc-type"
						>
							<b-form-input
								id="acc-type"
								size="sm"
								v-model="acc_type"
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Comments :"
							label-for="acc-comments"
						>
							<b-form-input
								id="acc-comments"
								size="sm"
								v-model="acc_comments"
							/>
						</b-form-group>
						</b-col>
					</b-row>
				</b-card>

				<div>
					<b-button
						variant="primary"
						class="mb-1 mb-sm-0 mr-0 mr-sm-1"
						size="sm"
						v-on:click="goback"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					>
						Back
					</b-button>

					<b-button
						variant="success"
						class="mb-1 mb-sm-0 mr-0 mr-sm-1"
						size="sm"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
						@click="updateStation()"
					>
						Update
					</b-button>

					<!-- <b-button
						variant="danger"
						class="mb-1 mb-sm-0 ml-0 ml-sm-1 float-right"
						type="reset"
						size="sm"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					>
						Delete
					</b-button> -->
				</div>
			</b-col>
		</div>

		<b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
	</div>
</template>

<script>
import {
	BCard,
	BCardHeader,
	BContainer,
	BRow,
	BCol,
	BFormSelect,
	BForm,
	BButton,
	BFormGroup,
	BCollapse,
	BFormDatepicker,
	BFormInput,
	BOverlay,
	BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";

import axios from "axios";
import helper from "@/api/helper";
import metadata from "@/api/metadata";
import error_handler from "@/api/error_handler";

export default {
	components: {
		BCard,
		BCardHeader,
		BContainer,
		BRow,
		BCol,
		BFormSelect,
		BForm,
		BButton,
		BFormGroup,
		BCollapse,
		BFormDatepicker,
		BFormInput,
		BOverlay,
		vSelect,
		BFormCheckbox,
	},
	props: ["profile"],
	data() {
		return {
			isEditing: false,
			showLoading: false,
			path: "",
			station_id:"",
			wmo_id: "",
			wigos_id: "",
			acc_valid_from: "",
			acc_valid_to: "",
			acc_id: "",
			acc_install_date: "",
			acc_samp_rate:false,
			acc_accelero: "",
			acc_accelero_serial: "",
			acc_digitiser: "",
			acc_digitiser_serial: "",
			acc_profile: "",
			acc_type: "",
			acc_comments: ""
		};
	},
	mounted() {
		this.path = this.$route.query.path;
		this.wigos_id = this.profile.wigos_id
		let accelerograph = this.profile["BmkgSatu.metadata.percepatantanah.IPercepatanTanah"]
		this.station_id = this.profile.station_wmo_id,
		this.wmo_id = this.profile.station_wmo_id,
		this.acc_valid_from = accelerograph.acc_valid_from
		this.acc_valid_to = accelerograph.acc_valid_to
		this.acc_id = accelerograph.acc_id,
		this.acc_install_date = accelerograph.acc_install_date,
		this.acc_samp_rate = accelerograph.acc_samp_rate,
		this.acc_accelero = accelerograph.acc_accelero,
		this.acc_accelero_serial = accelerograph.acc_accelero_serial,
		this.acc_digitiser = accelerograph.acc_digitiser,
		this.acc_digitiser_serial = accelerograph.acc_digitiser_serial,
		this.acc_profile = accelerograph.acc_profile
		this.acc_type = accelerograph.acc_type
		this.acc_comments = accelerograph.acc_comments

	},
	methods: {
		goback() {
			this.$router.push({ name: "metadata" });
		},
		updateStation() {
			this.showLoading = true;

			const update_data = {
				acc_id: this.acc_id,
				acc_install_date: this.acc_install_date,
				acc_samp_rate: this.acc_samp_rate,
				acc_accelero: this.acc_accelero,
				acc_accelero_serial: this.acc_accelero_serial,
				acc_digitiser: this.acc_digitiser,
				acc_digitiser_serial: this.acc_digitiser_serial,
				acc_profile: this.acc_profile,
				acc_type: this.acc_type,
				acc_comments: this.acc_comments,
			};

			let post_data = {
				"@type": "BmkgStation",
				"BmkgSatu.metadata.percepatantanah.IPercepatanTanah": update_data,
			};

			const update_url = helper.getBaseUrl() + this.path;

			axios
				.patch(update_url, post_data, helper.getConfig())
				.then((response) => {
					if (
						response.status == 201 ||
						response.status == 200 ||
						response.status == 204
					) {
						this.$swal({
						title: "Update Data berhasil!",
						text: "Update Data Percepatan Tanah",
						icon: "success",
						customClass: {
							confirmButton: "btn btn-primary",
						},
						buttonsStyling: false,
						});
						this.showLoading = false;
					}
				})
				.catch((error) => {
					this.showLoading = false;
					error_handler(this, error, "Metadata Stasiun", "Update Data Error");
				});
				this.isEditing = false;
		},
	},
};
</script>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
.mt {
  margin-top: -9px;
}
</style>
