var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"sm":"8"}},[_c('b-card',{staticClass:"border",attrs:{"no-body":""}},[_c('b-table',{ref:"table",attrs:{"fields":_vm.fields,"items":_vm.sortedItems,"responsive":"","hover":"","selectable":"","select-mode":"single"},on:{"row-selected":_vm.selectedRow}})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-card',{staticClass:"border"},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-cols":"4","label-size":"sm","label":"Latitude","label-for":"latitude"}},[_c('b-form-input',{attrs:{"id":"latitude","size":"sm","placeholder":"Latitude","type":"number"},model:{value:(_vm.latitude),callback:function ($$v) {_vm.latitude=_vm._n($$v)},expression:"latitude"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-cols":"4","label-size":"sm","label":"Longitude","label-for":"longitude"}},[_c('b-form-input',{attrs:{"id":"longitude","size":"sm","placeholder":"Longitude","type":"number"},model:{value:(_vm.longitude),callback:function ($$v) {_vm.longitude=_vm._n($$v)},expression:"longitude"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-cols":"4","label-size":"sm","label":"Elevation (m)","label-for":"elevation"}},[_c('b-form-input',{attrs:{"id":"elevation","size":"sm","placeholder":"Elevation","type":"number"},model:{value:(_vm.elevation),callback:function ($$v) {_vm.elevation=_vm._n($$v)},expression:"elevation"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-cols":"4","label-size":"sm","label":"Valid From","label-for":"datepicker-valid-from"}},[_c('b-form-datepicker',{attrs:{"id":"datepicker-valid-from","placeholder":"From","date-format-options":{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  },"locale":"en","size":"sm"},model:{value:(_vm.valid_from),callback:function ($$v) {_vm.valid_from=$$v},expression:"valid_from"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-cols":"4","label-size":"sm","label":"Valid To","label-for":"datepicker-valid-to"}},[_c('b-form-datepicker',{attrs:{"id":"datepicker-valid-to","placeholder":"To","date-format-options":{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  },"locale":"en","size":"sm","reset-button":""},model:{value:(_vm.valid_to),callback:function ($$v) {_vm.valid_to=$$v},expression:"valid_to"}})],1)],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","size":"sm","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":_vm.goback}},[_vm._v(" Back ")]),(_vm.isEditing)?_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"gradient-secondary","size":"sm","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v(" Cancel ")]):_vm._e(),(_vm.isEditing)?_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"gradient-warning","type":"reset","size":"sm","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"disabled":!_vm.isRoleEdit},on:{"click":function($event){return _vm.editData()}}},[_vm._v(" Edit ")]):_vm._e(),(_vm.isEditing)?_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 float-right",attrs:{"variant":"gradient-danger","size":"sm","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"disabled":!_vm.isRoleEdit},on:{"click":function($event){return _vm.deleteConfirm()}}},[_vm._v(" Delete ")]):_vm._e(),(!_vm.isEditing)?_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"gradient-success","type":"reset","size":"sm","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"disabled":!_vm.isRoleEdit},on:{"click":function($event){return _vm.addGeography()}}},[_vm._v(" Add Geography ")]):_vm._e()],1)],1),_c('b-overlay',{attrs:{"show":_vm.showLoading,"rounded":"sm","no-wrap":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }