<template>
	<div>
		<div class="row">
			<b-col sm="5">
				<b-card no-body class="border mt-1">

					<b-row class="pl-1 pr-1 mt-1">
						<b-col>
							<b-form-group
								label-cols="4"
								label-size="sm"
								label="Valid From :"
								label-for="valid-from"
								size="sm"
							>
								<b-form-datepicker
									id="valid-from"
									v-model="awscenter_valid_from"
									size="sm"
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-size="sm"
								label="Valid To :"
								label-for="valid-to"
								size="sm"
							>
								<b-form-datepicker
									id="valid-to"
									v-model="awscenter_valid_to"
									size="sm"
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-size="sm"
								label="AWS Center ID :"
								label-for="aws-id"
								size="sm"
							>
								<b-form-input
									id="aws-id"
									size="sm"
									v-model="awscenter_id"
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-size="sm"
								label="Wigos ID :"
								label-for="wigos-id"
								size="sm"
							>
								<b-form-input
									id="wigos-id"
									size="sm"
									v-model="wigos_id"
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-size="sm"
								label="Tipe Alat :"
								label-for="aws-tipe-alat"
								size="sm"
							>
								<b-form-select
									v-model="awscenter_tipe_alat"
									id="aws-tipe-alat"
									size="sm"
									:options="options.aws_type_alat"
								></b-form-select>
							</b-form-group>
						</b-col>
					</b-row>

					<!-- <b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-size="sm"
								label="Tanggal Dipasang :"
								label-for="install-date"
								size="sm"
							>
								<b-form-datepicker
									id="install-date"
									v-model="awscenter_install_date"
									size="sm"
								/>
							</b-form-group>
						</b-col>
					</b-row> -->

					<!-- <b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="4"
								label-size="sm"
								label="Status Operasional :"
								label-for="aws-status"
							>
								<b-form-input
									id="aws-status"
									size="sm"
									v-model="awscenter_status_operational"
								/>
							</b-form-group>
						</b-col>
					</b-row> -->

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="4"
								label-size="sm"
								label="Is Active :"
								label-for="is-active"
							>
								<b-form-checkbox
									id="is-active"
									v-model="awscenter_is_active"
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="4"
								label-size="sm"
								label="Koordinator ID :"
								label-for="aws-koordinator-id"
							>
								<b-form-input
									id="aws-koordinator-id"
									size="sm"
									v-model="awscenter_koordinator_id"
								/>
							</b-form-group>
						</b-col>
					</b-row>

					
					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="4"
								label-size="sm"
								label="Merk Instrument :"
								label-for="aws-merk"
							>
								<b-form-input
									id="aws-merk"
									size="sm"
									v-model="awscenter_merk_instrument"
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Nama Petugas :"
							label-for="aws-nama-petugas"
						>
							<b-form-input
								id="aws-nama-petugas"
								size="sm"
								v-model="awscenter_nama_petugas"
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="HP Petugas:"
							label-for="aws-hp-petugas"
						>
							<b-form-input
								id="aws-hp-petugas"
								size="sm"
								v-model="awscenter_hp_petugas"
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Instansi :"
							label-for="aws-instansi"
						>
							<b-form-input
								id="aws-instansi"
								size="sm"
								v-model="awscenter_instansi"
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Kecamatan :"
							label-for="aws-kecamatan"
						>
							<b-form-input
								id="aws-kecamatan"
								size="sm"
								v-model="kecamatan_name"
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Alamat :"
							label-for="aws-alamat"
						>
							<b-form-input
								id="aws-alamat"
								size="sm"
								v-model="awscenter_alamat"
							/>
						</b-form-group>
						</b-col>
					</b-row>

				</b-card>

				<div>
					<b-button
						variant="primary"
						class="mb-1 mb-sm-0 mr-0 mr-sm-1"
						size="sm"
						v-on:click="goback"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					>
						Back
					</b-button>

					<b-button
						variant="success"
						class="mb-1 mb-sm-0 mr-0 mr-sm-1"
						size="sm"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
						@click="updateStation()"
					>
						Update
					</b-button>

					<!-- <b-button
						variant="danger"
						class="mb-1 mb-sm-0 ml-0 ml-sm-1 float-right"
						type="reset"
						size="sm"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					>
						Delete
					</b-button> -->
				</div>
			</b-col>

			<b-col sm="5">
				<b-card no-body class="border mt-1">
					
					<b-row class="pl-1 pr-1 mt-1">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Latitude :"
							label-for="aws-latitude"
						>
							<b-form-input
								id="aws-latitude"
								size="sm"
								v-model="current_latitude"
								readonly
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Longitude :"
							label-for="aws-longitude"
						>
							<b-form-input
								id="aws-longitude"
								size="sm"
								v-model="current_longitude"
								readonly
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Elevasi :"
							label-for="aws-elevation"
						>
							<b-form-input
								id="aws-elevation"
								size="sm"
								v-model="station_elevation"
								readonly
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Propinsi :"
							label-for="aws-propinsi"
						>
							<b-form-input
								id="aws-propinsi"
								size="sm"
								v-model="propinsi_name"
								readonly
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Kota/kabupaten :"
							label-for="aws-kabupaten"
						>
							<b-form-input
								id="aws-kabupaten"
								size="sm"
								v-model="kabupaten_name"
								readonly
							/>
						</b-form-group>
						</b-col>
					</b-row>
				</b-card>				
			</b-col>

		</div>

		<b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
	</div>
</template>

<script>
import {
	BCard,
	BCardHeader,
	BContainer,
	BRow,
	BCol,
	BFormSelect,
	BForm,
	BButton,
	BFormGroup,
	BCollapse,
	BFormDatepicker,
	BFormInput,
	BOverlay,
	BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";

import axios from "axios";
import helper from "@/api/helper";
import metadata from "@/api/metadata";
import error_handler from "@/api/error_handler";

export default {
	components: {
		BCard,
		BCardHeader,
		BContainer,
		BRow,
		BCol,
		BFormSelect,
		BForm,
		BButton,
		BFormGroup,
		BCollapse,
		BFormDatepicker,
		BFormInput,
		BOverlay,
		vSelect,
		BFormCheckbox,
	},
	props: ["profile"],
	data() {
		return {
			isEditing: false,
			showLoading: false,
			path: "",
			wigos_id: "",
			awscenter_id: "", 
			awscenter_tipe_alat: "", 
			awscenter_install_date: "",
			awscenter_valid_from: "",
			awscenter_valid_to: "", 
			awscenter_status_operational: "",
			awscenter_is_active: "",
			awscenter_koordinator_id: "",
			awscenter_alamat: "",
			awscenter_merk_instrument: "",
			awscenter_nama_petugas: "",
			awscenter_hp_petugas: "",
			awscenter_instansi: "",
			current_latitude: "",
			current_longitude: "",
			station_elevation: "",
			propinsi_name: "",
			kabupaten_name: "", 
			kecamatan_name: "",
			awscenter_alamat: "",
			options: {
				aws_type_alat: [
					{value: "HL-10", text: "HL-10"},
					{value: "DT-12", text: "DT-12"},
					{value: "Vaisala", text: "Vaisala"},
					{value: "Casella", text: "Casella"},
					{value: "RMYoung", text: "RMYoung"},
					{value: "CampbellScience", text: "CampbellScience"},
					{value: "CIMEL", text: "CIMEL"},
					{value: "TS-Maritime", text: "TS-Maritime"},
					{value: "ARG", text: "ARG"},
					{value: "CDM", text: "CDM"},
					{value: "Jinyang", text: "Jinyang"},
					{value: "TS-KLIMAT", text: "TS-KLIMAT"},
					{value: "AAWS", text: "AAWS"}
				]
			}
		};
	},
	mounted() {
		this.path = this.$route.query.path;
		this.wigos_id = this.profile.wigos_id
		let aws = this.profile["BmkgSatu.metadata.awscenter.IAwsCenter"]
		this.awscenter_id = aws.awscenter_id
		this.awscenter_tipe_alat =  aws.awscenter_tipe_alat
		// this.awscenter_install_date =  aws.awscenter_install_date
		this.awscenter_valid_from =  aws.awscenter_valid_from
		this.awscenter_valid_to = aws.awscenter_valid_to
		// this.awscenter_status_operational = aws.awscenter_status_operational
		this.awscenter_koordinator_id = aws.awscenter_koordinator_id
		this.awscenter_is_active = aws.awscenter_is_active
		this.awscenter_alamat = aws.awscenter_alamat
		this.awscenter_merk_instrument = aws.awscenter_merk_instrument
		this.awscenter_nama_petugas = aws.awscenter_nama_petugas
		this.awscenter_hp_petugas = aws.awscenter_hp_petugas
		this.awscenter_instansi = aws.awscenter_instansi
		this.current_latitude = this.profile.current_latitude
		this.current_longitude = this.profile.current_longitude
		this.station_elevation = this.profile.station_elevation
		this.propinsi_name = this.profile.propinsi_name
		this.kabupaten_name = this.profile.kabupaten_name
		this.kecamatan_name = this.profile.kecamatan_name

	},
	methods: {
		goback() {
			this.$router.push({ name: "metadata" });
		},
		updateStation() {
			this.showLoading = true;

			const update_data = {
				awscenter_id: this.awscenter_id,
				awscenter_tipe_alat: this.awscenter_tipe_alat,
				awscenter_is_active: this.awscenter_is_active,
				awscenter_valid_from: this.awscenter_valid_from,
				awscenter_valid_to: this.awscenter_valid_to,
				awscenter_koordinator_id: this.awscenter_koordinator_id,
				awscenter_alamat: this.awscenter_alamat,
				awscenter_merk_instrument: this.awscenter_merk_instrument,
				awscenter_nama_petugas: this.awscenter_nama_petugas,
				awscenter_hp_petugas: this.awscenter_hp_petugas,
				awscenter_instansi: this.awscenter_instansi,
			};

			let post_data = {
				"@type": "BmkgStation",
				"kecamatan_name": this.kecamatan_name,
				"BmkgSatu.metadata.awscenter.IAwsCenter": update_data,
			};

			const update_url = helper.getBaseUrl() + this.path;

			axios
				.patch(update_url, post_data, helper.getConfig())
				.then((response) => {
					if (
						response.status == 201 ||
						response.status == 200 ||
						response.status == 204
					) {
						this.$swal({
						title: "Update Data berhasil!",
						text: "Update Data AWS",
						icon: "success",
						customClass: {
							confirmButton: "btn btn-primary",
						},
						buttonsStyling: false,
						});
						this.showLoading = false;
					}
				})
				.catch((error) => {
					this.showLoading = false;
					error_handler(this, error, "Metadata Stasiun", "Update Data Error");
				});
				this.isEditing = false;
		},
	},
};
</script>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
.mt {
  margin-top: -9px;
}
</style>
