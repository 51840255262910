<template>
  <div>
    <b-row>
      <b-col sm="12">
        <b-card class="border">
          <b-row style="z-index: 1; position: absolute;">
            <!-- Select Geography -->
            <b-col>
              <b-form-group
                label-cols="4"
                label-size="sm"
                label="Geography:"
                label-for="input-geography"
              >
                <b-form-select
                  v-model="geography"
                  id="input-geography"
                  size="sm"
                >
                  <option
                    v-for="opt in options.geography"
                    :key="opt.id"
                    :value="opt.id"
                    :disabled="opt.geography_status"
                  >
                    {{ opt.label }}
                  </option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="h-100">
            <b-col>
              <DinamicMaps :ZOOM="zoom" ref="dinamicMapComponent"></DinamicMaps>
            </b-col>
          </b-row>
        </b-card>

      </b-col>

      <!-- <b-col sm="5">
        <b-card no-body class="border mt-1">
          <b-row class="pl-1 pr-1 ">
            <b-col sm="12">
              <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="Description :" label-for="input-sm">
                <b-form-input id="input-sm" size="sm" placeholder="Auto" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="pl-1 pr-1">
            <b-col sm="12">
              <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="Polygon Type :" label-for="input-sm">
                <b-form-select v-model="selected" :options="options" size="sm" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="pl-1 pr-1">
            <b-col sm="12">
              <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="Coordinates :" label-for="input-sm">
                <b-form-textarea id="textarea-rows" placeholder="Textarea" rows="10" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="pl-1 pr-1 ">
            <b-col sm="12">
              <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="Fill :" label-for="input-sm">
                <b-form-input id="input-sm" size="sm" placeholder="Auto" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
          Back
        </b-button>
        <b-button variant="danger" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
          Cancel
        </b-button>
        <b-button variant="gradient-success" class="mb-1 mb-sm-0 mr-0 mr-sm-1" type="reset" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
          Add
        </b-button>
      </b-col> -->
    </b-row>

    <b-row>
      <b-col>
        <b-button
						variant="primary"
						class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
						size="sm"
						v-on:click="goback"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					>
						Back
        </b-button>
      </b-col>
    </b-row>
    <b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
  </div>
</template>

<script>
import axios from "axios";
import helper from "@/api/helper";

import {
  BCard,
  BCardHeader,
  BContainer,
  BRow,
  BCol,
  BFormSelect,
  BForm,
  BButton,
  BFormGroup,
  BCollapse,
  BFormDatepicker,
  BFormInput,
  BFormTextarea,
  BTable,
  BOverlay,
} from "bootstrap-vue";
import "leaflet/dist/leaflet.css";

import DinamicMaps from '../../../outputdata/visualisasi/DinamicMaps.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BFormGroup,
    BCollapse,
    BFormDatepicker,
    BFormInput,
    BFormTextarea,
    BTable,
    BOverlay,
    DinamicMaps,
  },
  props: ["profile"],
  data: function () {
    return {
      showLoading: false,
      station_path: "",
      geography: "",
      options: {
        geography: [],
      },
      // maps
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 16,
      center: null,
      markerLatLng: null,
    };
  },
  mounted() {
    this.station_path = this.$route.query.path;
    this.getAllGeography();
  },
  watch: {
    geography(val) {
      if (val) {
        const selected = this.options.geography.find(item => item.id === this.geography);
        const latlng = [selected.geography_latitude, selected.geography_longitude];
        this.markerLatLng = latlng;
        this.center = latlng;
        this.$refs.dinamicMapComponent.generateMarker([{
          position           : this.markerLatLng,
          //JIKA INGIN MENGCUSTOME ICON
          iconSize           : [15,15],
        }]);
      }else {
        this.markerLatLng = null;
        this.center = null;
      }
    },
  },
  methods: {
    getAllGeography() {
      this.showLoading = true;

      const GET_URL =
        helper.getBaseUrl() + this.station_path + "/metageography/@items_list";
      const GET_CONFIG = {
        mode: "cors",
        withCredentials: false,
        params: {
          include:
            "id,geography_latitude,geography_longitude,geography_elevation,geography_valid_from,geography_valid_to",
        },
        headers: helper.getHeader(),
      };

      axios
        .get(GET_URL, GET_CONFIG)
        .then((response) => {
          const { data } = response;
          this.options.geography = data.items
            .map((item) => ({
              id: item["@name"],
              geography_latitude: item.geography_latitude,
              geography_longitude: item.geography_longitude,
              geography_elevation: item.geography_elevation,
              geography_valid_from: item.geography_valid_from.split("T")[0],
              geography_valid_to: item.geography_valid_to?.split("T")[0] || null,
              geography_status:true
            }))
            .map((item) => ({
              ...item,
              label: `${item.geography_valid_from} / ${item.geography_latitude} / ${item.geography_longitude}`,
            }))
            .sort(
              (a, b) =>
                -a.geography_valid_from.localeCompare(b.geography_valid_from)
            );
          if (this.options.geography.length > 0) {
            this.geography = this.options.geography[0].id;
          }
          this.showLoading = false;

          this.checkValidGeography()

        })
        .catch((error) => {
          console.log(error);
          this.showLoading = false;
        });
    },

    checkValidGeography() {
      if (this.options.geography) {
        let validGeo = null
        for (let geo of this.options.geography) {
          if (!geo.geography_valid_to) {
						validGeo = geo
						break
					}
					else {
						if (validGeo && new Date(geo.geography_valid_to) > new Date(validGeo.geography_valid_to) ) {
							validGeo = geo;
						}
					}
        }
        validGeo.geography_status = false
        console.log('validGeo', validGeo)
      }
    },

    goback() {
			this.$router.push({ name: "metadata" });
		},
  },
};
</script>

<style scoped>
</style>

<style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>
