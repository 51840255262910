<template>
	<div>
		<div class="row">
			<b-col sm="6">
				<!-- Parameter -->
				<b-card no-body class="border mt-1">
					<b-row class="pl-1 pr-1 mt-1">
						<b-col>
							<b-form-group
								label-cols="4"
								label-size="sm"
								label="Mulai Pengamatan :"
								label-for="start-date"
								size="sm"
							>
								<b-form-datepicker
									id="start-date"
									v-model="ku_start_date"
									size="sm"
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-size="sm"
								label="Akhir Pengamatan :"
								label-for="end-date"
								size="sm"
							>
								<b-form-datepicker
									id="end-date"
									v-model="ku_end_date"
									size="sm"
								/>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="KU Station Name :"
							label-for="station-name"
						>
							<b-form-input
							id="station-name"
							size="sm"
							v-model="ku_station_name"
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Wigos ID :"
							label-for="wigos-id"
						>
							<b-form-input
							id="wigos-id"
							size="sm"
							v-model="wigos_id"
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="4"
								label-size="sm"
								label="Fields :"
								label-for="fields"
							>
								<b-form-checkbox
									id="kah"
									v-model="ku_kah"
								>
								KAH
								</b-form-checkbox>

								<b-form-checkbox
									id="spm"
									v-model="ku_spm"
								>
								SPM
								</b-form-checkbox>

								<b-form-checkbox
									id="so2"
									v-model="ku_so2"
								>
								SO2
								</b-form-checkbox>

								<b-form-checkbox
									id="no2"
									v-model="ku_no2"
								>
								NO2
								</b-form-checkbox>
							</b-form-group>
							
						</b-col>
					</b-row>

					
				</b-card>

				<div>
					<b-button
						variant="primary"
						class="mb-1 mb-sm-0 mr-0 mr-sm-1"
						size="sm"
						v-on:click="goback"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					>
						Back
					</b-button>

					<b-button
						variant="success"
						class="mb-1 mb-sm-0 mr-0 mr-sm-1"
						size="sm"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
						@click="updateStation()"
					>
						Update
					</b-button>
					
					<!-- <b-button
						variant="danger"
						class="mb-1 mb-sm-0 ml-0 ml-sm-1 float-right"
						type="reset"
						size="sm"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					>
						Delete
					</b-button> -->
				</div>
			</b-col>
		</div>

		<b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
	</div>
</template>

<script>
//import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
	BCard,
	BCardHeader,
	BContainer,
	BRow,
	BCol,
	BFormSelect,
	BForm,
	BButton,
	BFormGroup,
	BCollapse,
	BFormDatepicker,
	BFormInput,
	BOverlay,
	BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";

import axios from "axios";
import helper from "@/api/helper";
import metadata from "@/api/metadata";
import error_handler from "@/api/error_handler";

//import aaws from "./monitoringcomponent/aaws.vue";
// import Aktinograph from "./piascomponent/aktinograph.vue";

export default {
	components: {
		BCard,
		BCardHeader,
		BContainer,
		BRow,
		BCol,
		BFormSelect,
		BForm,
		BButton,
		BFormGroup,
		BCollapse,
		BFormDatepicker,
		BFormInput,
		BOverlay,
		vSelect,
		BFormCheckbox,
		// Aktinograph,
	},
	props: ["profile"],
	data() {
		return {
			isEditing: false,
			showLoading: false,
			path: "",
			ku_start_date: "",
			ku_end_date: "",
			ku_station_name: "",
			ku_kah: false,
			ku_no2: false,
			ku_so2: false,
			ku_spm: false,
			wigos_id: ''
		};
	},
	mounted() {
		this.path = this.$route.query.path;
		this.wigos_id = this.profile.wigos_id
		let ku = this.profile["BmkgSatu.metadata.kualitasudara.IKualitasUdara"]
		console.log('ku:', ku)
		this.ku_start_date = ku.ku_start_date
		this.ku_end_date = ku.ku_end_date
		this.ku_station_name = ku.ku_station_name
		this.ku_kah = ku.ku_kah == 1 ? true : false
		this.ku_no2 = ku.ku_no2 == 1 ? true : false
		this.ku_so2 = ku.ku_so2 == 1 ? true : false
		this.ku_spm = ku.ku_spm	== 1 ? true : false

		console.log('spm', this.ku_spm + ' - ' + ku.ku_spm)
	},
	methods: {
		goback() {
			this.$router.push({ name: "metadata" });
		},
		updateStation() {
			this.showLoading = true;

			const update_data = {
				"ku_start_date": this.ku_start_date,
				"ku_end_date": this.ku_end_date,
				"ku_station_name": this.ku_station_name,
				"ku_kah": this.ku_kah ? 1 : 0,
				"ku_no2": this.ku_no2 ? 1 : 0,
				"ku_so2": this.ku_so2 ? 1 : 0,
				"ku_spm": this.ku_spm ? 1 : 0
			};

			let post_data = {
				"@type": "BmkgStation",
				"BmkgSatu.metadata.kualitasudara.IKualitasUdara": update_data,
			};

			const update_url = helper.getBaseUrl() + this.path;

			axios
				.patch(update_url, post_data, helper.getConfig())
				.then((response) => {
					if (
						response.status == 201 ||
						response.status == 200 ||
						response.status == 204
					) {
						this.$swal({
						title: "Update Data berhasil!",
						text: "Update Data Kualitas Udara",
						icon: "success",
						customClass: {
							confirmButton: "btn btn-primary",
						},
						buttonsStyling: false,
						});
						this.showLoading = false;
					}
				})
				.catch((error) => {
					this.showLoading = false;
					error_handler(this, error, "Metadata Stasiun", "Update Data Error");
				});
				this.isEditing = false;
		},
	},
};
</script>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
.mt {
  margin-top: -9px;
}
</style>
