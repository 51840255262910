var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"ml-2",attrs:{"cols":"6"}},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(" Pos Hujan Station")]),_c('b-card',{staticClass:"border mt-1",attrs:{"no-body":""}},[_c('vue-good-table',{attrs:{"columns":_vm.selectedFields,"rows":_vm.selectedItems,"rtl":_vm.direction,"isLoading":_vm.showLoading,"search-options":{
					enabled: true,
					externalQuery: _vm.searchTerm,
					},"pagination-options":{
					enabled: true,
					perPage:_vm.pageLength,
					setCurrentPage: 1,
					mode: 'pages'
					},"theme":"polar-bear"},on:{"on-row-click":_vm.onRowClick}})],1)],1)],1),_c('b-row',{staticClass:"row ml-2"},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","size":"sm","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":_vm.goback}},[_vm._v(" Back ")])],1),_c('b-overlay',{attrs:{"show":_vm.showLoading,"rounded":"sm","no-wrap":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }