<template>
	<div style="margin:35px;color: #000">
		<section class="pdf-item">

			<div>
				<b-row>
					<b-col cols="12" class="font-weight-bold mb-1"> 
						<h1>SUMMARY OF  QUALITY ASSESMENT </h1>  
					</b-col>
				</b-row>
				<b-row class=" mb-1">
					<b-col cols="4" > 
						Station ID:  
					</b-col>
					<b-col cols="8" class="font-italic"> 
						{{summary.station_id}}
					</b-col>
				</b-row>
				<b-row class=" mb-1">
					<b-col cols="4"> 
						Wigos ID:  
					</b-col>
					<b-col cols="8" class="font-italic"> 
						{{summary.wigos_id}}  
					</b-col>
				</b-row>
				<b-row class=" mb-1">
					<b-col cols="4"> 
						Station Name:  
					</b-col>
					<b-col cols="8" class="font-italic"> 
						{{summary.station_name}}  
					</b-col>
				</b-row>
				<b-row class=" mb-1">
					<b-col cols="4"> 
						Element:  
					</b-col>
					<b-col cols="8" class="font-italic"> 
						{{summary.element_name}}  
					</b-col>
				</b-row >
				<b-row class="mb-2">
					<b-col cols="4"> 
						Last Update:  
					</b-col>
					<b-col cols="8" class="font-italic"> 
						{{summary.last_update}}  
					</b-col>
				</b-row>
				<b-row class=" mb-1">
					<b-col cols="5" > 
						Observing Method:  
					</b-col>
					<b-col cols="7" class="font-weight-bold"> 
						{{summary.observing_method}}  
					</b-col>
				</b-row>
				<b-row class=" mb-1">
					<b-col cols="5"> 
						Exposure of Instrument:  
					</b-col>
					<b-col cols="7" class="font-weight-bold"> 
						{{summary.exposure_instrument}}  
					</b-col>
				</b-row>
				<b-row class=" mb-1">
					<b-col cols="5"> 
						Relative Uncertainity:  
					</b-col>
					<b-col cols="7" class="font-weight-bold"> 
						{{summary.relative_uncertainity}}  
					</b-col>
				</b-row>
				<b-row class=" mb-1">
					<b-col cols="5"> 
						Absolute Uncertainity:  
					</b-col>
					<b-col cols="7" class="font-weight-bold"> 
						{{summary.absolute_uncertainity}}  
					</b-col>
				</b-row>
				<b-row class=" mb-1">
					<b-col cols="5"> 
						Measurement Height:  
					</b-col>
					<b-col cols="7" class="font-weight-bold"> 
						{{summary.measurement_height}}  
					</b-col>
				</b-row>
				<b-row class=" mb-1">
					<b-col cols="5"> 
						Maintenance Schedule:  
					</b-col>
					<b-col cols="7" class="font-weight-bold"> 
						{{summary.maintenance_schedule}}  
					</b-col>
				</b-row>
				<b-row class=" mb-1">
					<b-col cols="5"> 
						Timelines:  
					</b-col>
					<b-col cols="7" class="font-weight-bold"> 
						{{summary.timelines}}  
					</b-col>
				</b-row>
				<b-row class=" mb-1">
					<b-col cols="5"> 
						Data Availability:  
					</b-col>
					<b-col cols="7" class="font-weight-bold"> 
						{{summary.data_availability}}  
					</b-col>
				</b-row>
				<b-row class=" mb-1">
					<b-col cols="5"> 
						Instrument Photo:  
					</b-col>
					<b-col cols="7"> 
						<div>
							<b-img
								ref="instrument_photo"
								rounded
								:src="require('@/assets/images/elements/homepod.png')"
								height="100"
							/>
						</div>
						<div class="font-weight-bold">
							{{summary.instrument_photo_name}}
						</div> 
						<div class="font-weight-bold">
							{{summary.instrument_photo_date}}
						</div>    
					</b-col>
				</b-row>
			</div>
			
		</section>
	</div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BButton, BTable, BTr, BTh, BTd
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import metadata from "@/api/metadata";
import useJwt from '@/auth/jwt/useJwt'
import VueHtml2pdf from "vue-html2pdf";

export default {
	components: {
		BCardCode,
		VueGoodTable,
		BAvatar,
		BPagination,
		BFormGroup,
		BFormInput,
		BFormSelect,
		BRow,
		BCol,
		BCard,
		BButton,
		BTable,
		// eslint-disable-next-line vue/no-unused-components
		ToastificationContent,
		BTd,
		BTr,
		BTh,
	},
	props: ["dataPDF"],
	data() {
		return {
			summary: {
                station_id:'-',
                wigos_id:'-',
                station_name:'-',
                element_name:'-',
                last_update:'-',
                observing_method:'-',
                exposure_instrument:'-',
                relative_uncertainity:'-',
                absolute_uncertainity:'-',
                measurement_height:'-',
                maintenance_schedule:'-',
                timelines:'-',
				data_availability:'-',
				instrument_photo:'-',
				instrument_photo_name:'-',
				instrument_photo_date:'-',
            },

		}
	},
	
	methods: {
		
	},

	computed: {
		sortedPhotos() {
			// return this.photos.sort((a, b) => -(a.id.localeCompare(b.id)));
		},
	},

	mounted() {
		this.summary = this.dataPDF
		
	},
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style>
	.vgt-table thead th.theadc {
		background-color: #f7fafc !important;
	}


	.table-report-metadata  tbody > tr > td:last-child {
		border-right: solid 1px rgb(90, 90, 90);
	}


	.table-report-metadata  tbody > tr > td {
		padding: 5px !important;
		margin: 0px;
		text-align: center;
		color:#000;
		border-left:solid 1px rgb(90, 90, 90);
		border-bottom:solid 1px rgb(90, 90, 90);
	}

	.table-report-metadata > thead > tr > td:last-child{
		border-right: solid 1px rgb(90, 90, 90) !important;
	}

	.table-report-metadata > thead > tr > td {
		background: #ffffff;
		color: #000000;
		border-left: solid 1px rgb(90, 90, 90) !important;
		border-top: solid 1px rgb(90, 90, 90) !important;
		border-bottom: solid 1px rgb(90, 90, 90) !important;
	}

	.table-report-metadata > thead > tr > td {
		text-align: center;
		font-weight: bold;
		padding: 5px !important;
	}

</style>
