<template>
	<a-spin :spinning="spinningLoadingPage">
		<div>
			<div class="row">
				<b-col sm="7">

					<b-card no-body class="border mt-1">
						<b-card-header class="p-1">
							<b-card-title class="font-medium-1">
							<feather-icon icon="UsersIcon" size="14" />
							<span class="align-middle ml-50">Selected Elements</span>
							</b-card-title>
						</b-card-header>
						<b-row class="pl-1 pr-1 mt-1 mb-1">
							<b-col sm="12">
								<div class="custom-search d-flex justify-content-end">
									<b-form-group>
										<div class="d-flex align-items-center">
										<label class="mr-1">Search</label>
										<b-form-input
											v-model="searchTermSelected"
											placeholder="Search"
											type="text"
											class="d-inline-block"
										/>
										</div>
									</b-form-group>
								</div>
								<vue-good-table
									:columns="columnsSelected"
									:rows="rowsSelected"
									:rtl="direction"
									:search-options="{
										enabled: true,
										externalQuery: searchTermSelected,
									}"
									:pagination-options="{
										enabled: true,
										perPage: pageLength,
										setCurrentPage: 1,
										mode: 'pages'
									}"
									@on-cell-click="onCellClick"
								>
									<template slot="table-row" slot-scope="props">

										<span
											v-if="props.column.field === 'qa'"
											class="text-nowrap"
										>
											<a href="#" class="font-weight-bold">Summary</a>
										</span>

										<span v-else-if="props.column.field === 'action'">
											<span>
											<b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
												<template v-slot:button-content>
												<feather-icon icon="Trash2Icon" size="16" class=" align-middle text-danger" variant="danger"/>
												</template>
												<b-dropdown-item @click="removeElement(props.row)" :disabled="!isRoleEdit" variant="danger" >
													<feather-icon icon="TrashIcon" class="mr-50 text-danger" />
													<span>Delete</span>
												</b-dropdown-item>
											</b-dropdown>
											</span>
										</span>

										<span v-else>
											{{ props.formattedRow[props.column.field] }}
										</span>
									</template>

								</vue-good-table>
							</b-col>
						</b-row>

					</b-card>
				</b-col>

				<b-col sm="5">
					<b-card no-body class="border mt-1">
						<b-card-header class="p-1">
							<b-card-title class="font-medium-1">
							<feather-icon icon="UsersIcon" size="14" />
							<span class="align-middle ml-50">Available Elements</span>
							</b-card-title>
						</b-card-header>

						<b-row class="pl-1 pr-1 mt-1 mb-1">
							<b-col sm="12">
								<div class="custom-search d-flex justify-content-end">
									<b-form-group>
										<div class="d-flex align-items-center">
										<label class="mr-1">Search</label>
										<b-form-input
											v-model="searchTerm"
											placeholder="Search"
											type="text"
											class="d-inline-block"
										/>
										</div>
									</b-form-group>
								</div>

								<vue-good-table
									:columns="columnsAvaliable"
									:rows="rowsAvailable"
									:rtl="direction"
									:search-options="{
										enabled: true,
										externalQuery: searchTerm,
									}"
									:pagination-options="{
										enabled: true,
										perPage: pageLength,
										setCurrentPage: 1,
										mode: 'pages'
									}"
								>
									<template slot="table-row" slot-scope="props">
										<span v-if="props.column.field === 'ae_label'" class="text-nowrap">
											<span class="text-nowrap">{{ props.row.ae_label }}</span>
										</span>

										<span v-else-if="props.column.field === 'action'">
											<span v-if="!props.row.ae_status">
												<b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
													<template v-slot:button-content disabled>
														<feather-icon icon="PlusCircleIcon" size="16" class="text-success align-middle" />
													</template>
													<b-dropdown-item @click="addElement(props.row)" :disabled="!isRoleEdit" variant="success">
														<feather-icon icon="PlusCircleIcon" class="mr-50" />
														<span>Add</span>
													</b-dropdown-item>
												</b-dropdown>
											</span>
										</span>

										<span v-else>
											{{ props.formattedRow[props.column.field] }}
										</span>
									</template>
								</vue-good-table>
							</b-col>
						</b-row>
					</b-card>
				</b-col>
			</div>
		</div>

		<client-only>
			<vue-html2pdf
			:show-layout="false"
			:float-layout="true"
			:enable-download="false"
			:preview-modal="true"
			:paginate-elements-by-height="9500"
			:filename="filePdfName"
			:pdf-quality="3"
			:manual-pagination="false"
			pdf-format="a4"
			v-bind:pdf-orientation="REPORT_ORIENTATION"
			@beforeDownload="beforeDownload($event)"
			@progress="onProgress($event)"
			@hasStartedGeneration="hasStartedGeneration()"
			@hasGenerated="hasGenerated($event)"
			ref="html2Pdf"
			pdf-content-width="680px"
			:html-to-pdf-options="htmlToPdfOptions"
			>
				<section slot="pdf-content">
					<summary-qa v-if="STORE_REPORT != null " :dataPDF="STORE_REPORT"> </summary-qa>
				</section>

			</vue-html2pdf>
		</client-only>

		<div>
			<b-modal id="modal-summary" ref="modal-summary" title="SUMMARY OF QUALITY ASSESMENT" centered size="md" button-size="sm" ok-only>
				
				<div>
					<b-row>
						<b-col cols="4"> 
							Station ID:  
						</b-col>
						<b-col cols="8" class="font-italic"> 
							{{summary.station_id}}
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="4"> 
							Wigos ID:  
						</b-col>
						<b-col cols="8" class="font-italic"> 
							{{summary.wigos_id}}  
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="4"> 
							Station Name:  
						</b-col>
						<b-col cols="8" class="font-italic"> 
							{{summary.station_name}}  
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="4"> 
							Element:  
						</b-col>
						<b-col cols="8" class="font-italic"> 
							{{summary.element_name}}  
						</b-col>
					</b-row>
					<b-row class="mb-1">
						<b-col cols="4"> 
							Last Update:  
						</b-col>
						<b-col cols="8" class="font-italic"> 
							{{summary.last_update}}  
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="5" > 
							Observing Method:  
						</b-col>
						<b-col cols="7" class="font-weight-bold"> 
							{{summary.observing_method}}  
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="5"> 
							Exposure of Instrument:  
						</b-col>
						<b-col cols="7" class="font-weight-bold"> 
							{{summary.exposure_instrument}}  
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="5"> 
							Relative Uncertainity:  
						</b-col>
						<b-col cols="7" class="font-weight-bold"> 
							{{summary.relative_uncertainity}}  
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="5"> 
							Absolute Uncertainity:  
						</b-col>
						<b-col cols="7" class="font-weight-bold"> 
							{{summary.absolute_uncertainity}}  
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="5"> 
							Measurement Height:  
						</b-col>
						<b-col cols="7" class="font-weight-bold"> 
							{{summary.measurement_height}}  
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="5"> 
							Maintenance Schedule:  
						</b-col>
						<b-col cols="7" class="font-weight-bold"> 
							{{summary.maintenance_schedule}}  
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="5"> 
							Timelines:  
						</b-col>
						<b-col cols="7" class="font-weight-bold"> 
							{{summary.timelines}}  
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="5"> 
							Data Availability:  
						</b-col>
						<b-col cols="7" class="font-weight-bold"> 
							{{summary.data_availability}}  
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="5"> 
							Instrument Photo:  
						</b-col>
						<b-col cols="7"> 
							<div>
								<b-img
									ref="instrument_photo"
									rounded
									:src="require('@/assets/images/elements/homepod.png')"
									height="100"
								/>
							</div>
							<div class="font-weight-bold">
								{{summary.instrument_photo_name}}
							</div> 
							<div class="font-weight-bold">
								{{summary.instrument_photo_date}}
							</div>    
						</b-col>
					</b-row>
				</div>

				

				<template #modal-footer>
					<b-button
						size="sm"
						variant="gradient-primary"
						@click="downloadSummary();"
					>
					Download
					</b-button>
				</template>
				<!-- <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay> -->
			</b-modal>
		</div>
	</a-spin>
</template>


<script>
import { BCard, BCardTitle, BCardHeader, BContainer, BRow, BCol, BFormSelect, BForm, BButton, BFormGroup, BDropdown, BDropdownItem, 
			BFormDatepicker, BFormInput, BFormTextarea, BTable, BAvatar, BBadge, BPagination, BModal, BOverlay, BImg } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import metadata from "@/api/metadata";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import SummaryQa from "./SummaryQA.vue";
import VueHtml2pdf from "vue-html2pdf";

const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
export default {
	components: {
		BCard,
		BCardTitle,
		BCardHeader,
		BContainer,
		BRow,
		BCol,
		BFormSelect,
		BForm,
		BButton,
		BFormGroup,
		BFormDatepicker,
		BFormInput,
		BFormTextarea,
		BTable,
		BAvatar,
		BBadge,
		BPagination,
		VueGoodTable,
		BDropdown,
		BDropdownItem,
		BModal, 
		BOverlay,
		BImg,
		SummaryQa,
		VueHtml2pdf

	},
	props: ["profile", "roles"],
	data: function() {
		return {
			pageLength: 10,
			dir: false,
			columnsAvaliable: [
				{
					label: "Element Description",
					field: "ae_label",
				},
				{
					label: "Action",
					field: "action",
					thClass: "text-center",
					tdClass: "text-center"
				},
			],
			columnsSelected: [
				{
					label: "Element Description",
					field: "ae_label",
				},
				{
					label: "Quality Assesment",
					field: "qa",
					thClass: "text-center",
					tdClass: "text-center"
				},
				{
					label: "Action",
					field: "action",
					thClass: "text-center",
					tdClass: "text-center"
				},
			],
			rowsAvailable: [],
			rowsSelected: [],
			searchTerm: "",
			searchTermSelected: "",
			status: [],
			postDataExisting: {},
			isRoleAdd: false,
			isRoleEdit: false,
			isRoleView: false,
			isRoleDelete: false,
			summary: {
				title: 'SUMMARY OF QUALITY ASSSESMENT',
                station_id:'-',
                wigos_id:'-',
                station_name:'-',
                element_name:'-',
                last_update:'-',
                observing_method:'-',
                exposure_instrument:'-',
                relative_uncertainity:'-',
                absolute_uncertainity:'-',
                measurement_height:'-',
                maintenance_schedule:'-',
                timelines:'-',
				data_availability:'-',
				instrument_photo:'-',
				instrument_photo_name:'-',
				instrument_photo_date:'-',
            },
			filePdfName: 'SUMMARY OF QUALITY ASSESMENT',
			REPORT_ORIENTATION: 'portrait',
			STORE_REPORT: null,
			spinningLoadingPage: false,
			htmlToPdfOptions: {
				margin: 17
			},
		};
	},
	computed: {
		direction() {
			if (store.state.appConfig.isRTL) {
				this.dir = true;
				return this.dir;
			}
			this.dir = false;
			return this.dir;
		},

	},
	created() {},

	async mounted() {
		if (this.roles) {
			// console.log('mounted - this.roles', this.roles)
			this.isRoleAdd = this.roles.isRoleAdd
			this.isRoleEdit = this.roles.isRoleEdit
			this.isRoleView = this.roles.isRoleView
			this.isRoleDelete = this.roles.isRoleDelete
		}
		this.getListElement();
	},

	watch: {
		roles(val) {
			if (val) {
				this.isRoleAdd = val.isRoleAdd
				this.isRoleEdit = val.isRoleEdit
				this.isRoleView = val.isRoleView
				this.isRoleDelete = val.isRoleDelete
			}
		}
	},

	methods: {
		onFetch() {
			this.choosenForm = this.selectedForm;
		},

		async getListElement() {      
			// const { data } = await metadata.getStationElement();
			
			// this.rowsAvailable = data.sort(function(a, b){
			// 		return parseInt(a.ae_id) - parseInt(b.ae_id)
			// 	})

			if (this.profile) {
     		 	let elementExisting = this.profile["BmkgSatu.metadata.aelement.IAelement"].aelement_data;
				let aedata = []
				if (elementExisting) {
					for (var key in elementExisting) {
						aedata.push(elementExisting[key])

						// this.postDataExisting[String(elementExisting[key].ae_id)] = elementExisting[key]

						// var item = this.rowsAvailable.find(item => item.ae_id == key);
						// item['ae_status'] = true
						// console.log('item', item)
					}
				}

				this.rowsSelected = aedata				
				
			}
			
		},

		removeElement(event) {
			// delete this.postDataExisting[String(event.ae_id)];

			// console.log('this.postDataExisting', this.postDataExisting)

			// this.moveElement(this.postDataExisting)
		},

		addElement(event) {
			
			this.postDataExisting[String(event.ae_id)] = {
				"ae_id": event['ae_id'],
				"ae_attribut": event['ae_attribut'],
				"ae_form_type": event['ae_form_type'],
				"ae_label": event['ae_label']
			}

			this.moveElement(this.postDataExisting)
		},

		async moveElement(data) {   
			let path = this.$route.query;
			let post_data = {
				"BmkgSatu.metadata.aelement.IAelement": {
					"aelement_data": data
				},
			};
			this.spinningLoadingPage = true
			metadata.updateStasiun(path.path, post_data)
				.then((response) => {
					if (response.status == 201 || response.status == 200 || response.status == 204) {

						this.updateTable(data)
						this.spinningLoadingPage = false

						this.$toast({
							component: ToastificationContent,
							props: {
								title: "Element berhasil diupdate",
								icon: "BellIcon",
								variant: "success",
							},
						});
					}
				})
				.catch((error) => {
					this.spinningLoadingPage = false
					if (error.response.status == 401) {
						this.$swal({
							title: "Unauthorized!",
							text: "Sesi anda telah habis. Logout dan silahkan login kembali",
							customClass: {
								confirmButton: "btn btn-primary",
							},
							buttonsStyling: false,
						});
					} else {
						this.t_data_status = "new";
						this.$swal({
							title: "Something wrong!",
							text: "" + error.response.status,
							icon: "warning",
							customClass: {
								confirmButton: "btn btn-primary",
							},
							buttonsStyling: false,
						});
					}
					this.spinningLoadingPage = false;
				});		
		},

		updateTable(data) {
			let aedata = []
			this.rowsAvailable.forEach(element => {
				element['ae_status'] = false
			});

			for (var key in data) {
				aedata.push(data[key])

				var item = this.rowsAvailable.find(item => item.ae_id == key);
				item['ae_status'] = true
			}

			this.rowsSelected = aedata

		},

		onCellClick(params) {

			if(params.column.field == "qa") {
				if (this.profile) {
					this.summary.station_id = this.profile.station_wmo_id
					this.summary.wigos_id = this.profile.wigos_id
					this.summary.station_name = this.profile.station_name
					this.summary.element_name = params.row.ae_label
					let d = new Date()
					let day = weekday[d.getDay()];
					this.summary.last_update = day +', '+d.toLocaleString() 
				}
				
				this.$refs['modal-summary'].show()
			}
		},

		beforeDownload($event) {},
		onProgress($event) {},
		hasStartedGeneration() {},
		hasGenerated($event) {},

		downloadSummary() {
			this.$refs['modal-summary'].hide()
			this.spinningLoadingPage = true;
			new Promise(async (resolve) => {
				
				this.STORE_REPORT = this.summary;

				setTimeout(() => {
					this.$refs.html2Pdf.generatePdf();
					this.spinningLoadingPage = false;
				}, 100);
				resolve(this.summary);
			});
		}
	},
};
</script>

<style scoped>
.pad2 {
	padding-left: 2px;
	padding-right: 2px;
}
.mt {
  	margin-top: -9px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
