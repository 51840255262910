<template>
	<div>
		<b-row>
			<b-col sm="12">
				<b-tabs pills v-model="tabStep">
					<b-tab title="General" active>
						<general :profile="profile" @station="shareStation($event)" @usageUpdate="usageUpdate($event)"
					/></b-tab>
					<b-tab :profile="profile" title="Observer" lazy> <contact /> </b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import {
	BTab,
	BTabs,
	BRow,
	BCol,
	BCard,
	BCardTitle,
	BCardBody,
} from "bootstrap-vue";
import General from "./componentaccelerograph/GeneralAccelerograph.vue";
import Contact from "./componentaccelerograph/ContactAccelerograph.vue";

export default {
	components: {
		BTab,
		BRow,
		BCol,
		BCard,
		BCardTitle,
		BCardBody,
		BTabs,
		General,
		Contact
	},
	props: ["profile"],
	data() {
		return {
			profile: null,
			tabStep: 0,
			title: "",
			mkg_selected: 0,
			bmkgentry_selected: 0,
			pos_hujan_selected: 0,
			ku_selected: 0,
			aws_selected: 0,
			radar_selected: 0,
			accelerograph_selected: 0,
			gempa_selected: 0
		};
	},
	methods: {
		// usageUpdate(event) {
		// 	console.log('event: ', event)
		// 	if(event) {
		// 		this.mkg_selected = event.is_mkg,
		// 		this.bmkgentry_selected = event.is_bmkgentry,
		// 		this.pos_hujan_selected = event.is_poshujan,
		// 		this.ku_selected = event.is_kualitas_udara,
		// 		this.aws_selected = event.is_peralatan_otomatis,
		// 		this.radar_selected = event.is_radar,
		// 		this.accelerograph_selected = event.is_percepatan_tanah,
		// 		this.gempa_selected = event.is_gempa
		// 	}
		// },
		// shareStation(event) {
		// 	this.profile = event;
		// 	console.log("shareStation : ", event);
		// 	this.title = event.station_name + " (" + event.station_id + ")"

		// 	this.mkg_selected = event.is_mkg,
		// 	this.bmkgentry_selected = event.is_bmkgentry,
		// 	this.pos_hujan_selected = event.is_poshujan,
		// 	this.ku_selected = event.is_kualitas_udara,
		// 	this.aws_selected = event.is_peralatan_otomatis,
		// 	this.radar_selected = event.is_radar,
		// 	this.accelerograph_selected = event.is_percepatan_tanah,
		// 	this.gempa_selected = event.is_gempa
		// },
		// onBack(event) {
		// 	console.log("onBack");
		// 	this.tabStep = 0;
		// },
	},
	mounted() {
		// console.log('mounted -stationprofile')
		// let content = this.$route.query.content;
		// this.title = content.stasiun + " (" + content.id + ")"
	}
};
</script>
<style>
.nav-pills .nav-link {
  padding: 0.786rem 1rem;
}
</style>
