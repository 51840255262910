<template>
  <div>
    <b-row>
      <b-col xl="6" lg="12">
        <!-- Parameter TABLE -->
        <b-card-body class="border mt-1 mb-2 ml-2">
          <b-row class="m-1">
            <b-col xl="3">
              <b-form-checkbox
                v-model="entry_first_enable"
                value=true
              >
                First Input
              </b-form-checkbox>
            </b-col>
            
            <b-col xl="4">
              <b-form-datepicker
                id="datepicker-2"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="en"
                size="sm"
                placeholder="tanggal"
                v-model="entry_first_date"
                :disabled="!entry_first_enable"
              />
            </b-col>

            <b-col xl="2">
              <label> Jam 01:00</label>
            </b-col>
          </b-row>
          
        </b-card-body>
        <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'" v-on:click="goback">
          Back
        </b-button>
        <b-button variant="danger" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'" v-on:click="onCancel">
          Cancel
        </b-button>
        <b-button variant="gradient-success" class="mb-1 mb-sm-0 mr-0 mr-sm-1" type="reset" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'" v-on:click="onUpdate" :disabled="!isRoleEdit">
          Update
        </b-button>

        <!-- Action Buttons -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
//import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BCard,
  BCardBody,
  BCardHeader,
  BContainer,
  BRow,
  BCol,
  BFormSelect,
  BForm,
  BButton,
  BFormGroup,
  BCollapse,
  BFormDatepicker,
  BFormInput,
  BTable,
  BFormFile,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
} from "bootstrap-vue";
// import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import axios from "axios";
import helper from "@/api/helper";
import metadata from "@/api/metadata";
import error_handler from "@/api/error_handler";

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BFormGroup,
    BCollapse,
    BFormDatepicker,
    BFormInput,
    BFormFile,
    BTable,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    //aaws,
    // Aktinograph,
  },
  // mixins: [togglePasswordVisibility],
  // computed: {
  // 	passwordToggleIcon() {
  // 		return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
  // 	},
  // },
  props: ["profile", "roles"],
  data: function() {
    return {
      showLoading: false,
      entry_first_enable: false,
      entry_first_date: null,
      path: "",
      
      isRoleAdd: false,
      isRoleEdit: false,
      isRoleView: false,
      isRoleDelete: false,
    };
  },

  watch: {
    entry_first_enable: function(value) {
      // console.log('entry_first_enable',value)
      if (!value) {
        this.entry_first_date = null
      }
    },

    roles(val) {
      // console.log('s.roles', val)
      // console.log('this.roles', this.roles)
      if (val) {
        this.isRoleAdd = val.isRoleAdd;
        this.isRoleEdit = val.isRoleEdit;
        this.isRoleView = val.isRoleView;
        this.isRoleDelete = val.isRoleDelete;
      }
    },
  },

  mounted() {
    this.path = this.$route.query.path;
    if (this.profile) {
      this.entry_first_date = this.profile.entry_first_date
      this.entry_first_enable = this.profile.entry_first_enable
    }

    if (this.roles) {
      // console.log('mounted - this.roles', this.roles)
      this.isRoleAdd = this.roles.isRoleAdd;
      this.isRoleEdit = this.roles.isRoleEdit;
      this.isRoleView = this.roles.isRoleView;
      this.isRoleDelete = this.roles.isRoleDelete;
    }

  },

  methods: {
    goback() {
      this.$router.push({ name: "metadata" });
    },
    onCancel() {
      this.$emit("goBack", "back");
    },

    checkRequirement() {
      if (this.entry_first_enable ) {
        if (this.entry_first_date && this.entry_first_date != "") {
          return true
        }
        else {
          this.$swal({
            title: "Tanggal Belum diisi!",
            text: "Silahkan lengkapi data tanggal",
            icon: "danger",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          return false
        }
      }
      else {
        return true
      }
    },

    onUpdate() {

      if (!this.checkRequirement()) {
        console.log('Update data', 'data belum lengkap')
      }
      else {

        this.showLoading = true;
    
        let post_data = {
          "@type": "BmkgStation",
          "entry_first_enable": this.entry_first_enable,
          "entry_first_date": this.entry_first_date,
        };

        const update_url = helper.getBaseUrl() + this.path;

        axios
          .patch(update_url, post_data, helper.getConfig())
          .then((response) => {
            if (response.status == 201 || response.status == 200 || response.status == 204) {
              this.$swal({
                title: "Update Data berhasil!",
                text: "Update Data BMKGEntry",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.showLoading = false;
              this.profile["entry_first_enable"] = this.entry_first_enable;
              this.profile["entry_first_date"] = this.entry_first_date;

            }
          })
          .catch((error) => {
            this.showLoading = false;
            error_handler(this, error, "Metadata Stasiun", "Update Data Error");
          });
      }
    }
    
  },
};
</script>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
.mt {
  margin-top: -9px;
}
</style>
