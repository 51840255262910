<template>
	<div>
		<div class="row">
			<b-col sm="6">
				<b-card no-body class="border mt-1">
					<b-row class="pl-1 pr-1 mt-1">
						<b-col>
						<b-form-group
							label-cols="4"
							label-size="sm"
							label="Valid From :"
							label-for="valid-from"
							size="sm"
						>
							<b-form-datepicker
								id="valid-from"
								v-model="poshujan_valid_from"
								size="sm"
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-size="sm"
							label="Valid To :"
							label-for="valid-from"
							size="sm"
						>
							<b-form-datepicker
								id="valid-to"
								v-model="poshujan_valid_to"
								size="sm"
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-size="sm"
							label="Pos Hujan ID :"
							label-for="poshujan-id"
							size="sm"
						>
							<b-form-input
							id="poshujan-id"
							size="sm"
							v-model="pos_hujan_id"
							disabled
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-size="sm"
							label="Wigos ID :"
							label-for="poshujan-id"
							size="sm"
						>
							<b-form-input
							id="wigos-id"
							size="sm"
							v-model="wigos_id"
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="SMS Hujan ID :"
							label-for="sms-hujan-id"
						>
							<b-form-input
								id="sms-hujan-id"
								size="sm"
								v-model="poshujan_sms_id"
							/>
						</b-form-group>
						</b-col>
					</b-row>
					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="4"
								label-size="sm"
								label="Is Active :"
								label-for="is-active"
							>
								<b-form-checkbox
									id="is-active"
									v-model="poshujan_is_active"
								/>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="4"
								label-size="sm"
								label=" Entry Station ID :"
								label-for="entry-station-id"
							>
								<b-form-input
									id="entry-station-id"
									size="sm"
									v-model="poshujan_entry_station_id"
								/>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="4"
								label-size="sm"
								label="Contact Type :"
								label-for="contact-type"
							>
								<b-form-input
									id="contact-type"
									size="sm"
									v-model="poshujan_contact_type"
								/>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row class="pl-1 pr-1 mt">
						<b-col>
							<b-form-group
								label-cols="4"
								label-cols-lg="4"
								label-size="sm"
								label="Nama :"
								label-for="nama"
							>
								<b-form-input
								id="nama"
								size="sm"
								v-model="poshujan_name"
								
							/>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Telephone:"
							label-for="telephone"
						>
							<b-form-input
								id="telephone"
								size="sm"
								v-model="poshujan_telephone"
							/>
						</b-form-group>
						</b-col>
					</b-row>

					<b-row class="pl-1 pr-1 mt">
						<b-col>
						<b-form-group
							label-cols="4"
							label-cols-lg="4"
							label-size="sm"
							label="Alamat :"
							label-for="alamat"
						>
							<b-form-input
								id="alamat"
								size="sm"
								v-model="poshujan_alamat"
							/>
						</b-form-group>
						</b-col>
					</b-row>
				</b-card>

				<div>
					<b-button
						variant="primary"
						class="mb-1 mb-sm-0 mr-0 mr-sm-1"
						size="sm"
						v-on:click="goback"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					>
						Back
					</b-button>

					<b-button
						variant="success"
						class="mb-1 mb-sm-0 mr-0 mr-sm-1"
						size="sm"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
						@click="updateStation()"
					>
						Update
					</b-button>

					<!-- <b-button
						variant="danger"
						class="mb-1 mb-sm-0 ml-0 ml-sm-1 float-right"
						type="reset"
						size="sm"
						:block="$store.getters['app/currentBreakPoint'] === 'xs'"
					>
						Delete
					</b-button> -->
				</div>
			</b-col>
		</div>

		<b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
	</div>
</template>

<script>
import {
	BCard,
	BCardHeader,
	BContainer,
	BRow,
	BCol,
	BFormSelect,
	BForm,
	BButton,
	BFormGroup,
	BCollapse,
	BFormDatepicker,
	BFormInput,
	BOverlay,
	BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";

import axios from "axios";
import helper from "@/api/helper";
import metadata from "@/api/metadata";
import error_handler from "@/api/error_handler";

export default {
	components: {
		BCard,
		BCardHeader,
		BContainer,
		BRow,
		BCol,
		BFormSelect,
		BForm,
		BButton,
		BFormGroup,
		BCollapse,
		BFormDatepicker,
		BFormInput,
		BOverlay,
		vSelect,
		BFormCheckbox,
	},
	props: ["profile"],
	data() {
		return {
			isEditing: false,
			showLoading: false,
			path: "",
			wigos_id: "",
			poshujan_valid_from:"",
			poshujan_valid_to: "",
			pos_hujan_id: "",
			poshujan_sms_id: "",
			poshujan_is_active:false,
			poshujan_entry_station_id: "",
			poshujan_contact_type: "",
			poshujan_name: "",
			poshujan_telephone: "",
			poshujan_alamat: ""
		};
	},
	mounted() {
		this.path = this.$route.query.path;
		this.wigos_id = this.profile.wigos_id
		let poshujan = this.profile["BmkgSatu.metadata.poshujan.IPosHujan"]
		this.poshujan_valid_from = poshujan.poshujan_valid_from,
		this.poshujan_valid_to = poshujan.poshujan_valid_to,
		this.pos_hujan_id = poshujan.pos_hujan_id,
		this.poshujan_sms_id = poshujan.poshujan_sms_id,
		this.poshujan_is_active = poshujan.poshujan_is_active,
		this.poshujan_entry_station_id = poshujan.poshujan_entry_station_id,
		this.poshujan_contact_type = poshujan.poshujan_contact_type,
		this.poshujan_name = poshujan.poshujan_name,
		this.poshujan_telephone = poshujan.poshujan_telephone,
		this.poshujan_alamat = poshujan.poshujan_alamat

	},
	methods: {
		goback() {
			this.$router.push({ name: "metadata" });
		},
		updateStation() {
			this.showLoading = true;

			const update_data = {
				poshujan_valid_from: this.poshujan_valid_from,
				poshujan_valid_to: this.poshujan_valid_to,
				pos_hujan_id: this.pos_hujan_id,
				poshujan_sms_id: this.poshujan_sms_id ? this.poshujan_sms_id : null,
				poshujan_is_active: this.poshujan_is_active,
				poshujan_entry_station_id: this.poshujan_entry_station_id,
				poshujan_contact_type: this.poshujan_contact_type,
				poshujan_name: this.poshujan_name,
				poshujan_telephone: this.poshujan_telephone,
				poshujan_alamat: this.poshujan_alamat,
			};

			let post_data = {
				"@type": "BmkgStation",
				"BmkgSatu.metadata.poshujan.IPosHujan": update_data,
			};

			const update_url = helper.getBaseUrl() + this.path;

			axios
				.patch(update_url, post_data, helper.getConfig())
				.then((response) => {
					if (
						response.status == 201 ||
						response.status == 200 ||
						response.status == 204
					) {
						this.$swal({
						title: "Update Data berhasil!",
						text: "Update Data Pos Hujan",
						icon: "success",
						customClass: {
							confirmButton: "btn btn-primary",
						},
						buttonsStyling: false,
						});
						this.showLoading = false;
					}
				})
				.catch((error) => {
					this.showLoading = false;
					error_handler(this, error, "Metadata Stasiun", "Update Data Error");
				});
				this.isEditing = false;
		},
	},
};
</script>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
.mt {
  margin-top: -9px;
}
</style>
